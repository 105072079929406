import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CampaignShow } from "../components/Campaign";
import Page from "../components/layout/Page";
import { getCampaignAttributes, showCampaign } from "../redux/campaigns";

function Campaign(props, context) {
  const campaignId = useSelector((state) => state.campaigns.showId);
  const dispatch = useDispatch();
  const { id } = useParams();

  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    registerEffect(dispatch, showCampaign({ id }));
  }, []);

  const campaignData = useSelector((state) =>
    getCampaignAttributes(state, campaignId)
  );

  const campaignTitle = campaignData?.title || context.t("Campaign");

  return (
    <Page title={campaignTitle}>
      <CampaignShow campaignId={campaignId} />
    </Page>
  );
}

Campaign.contextTypes = {
  t: PropTypes.func,
};

export default Campaign;
