/**
 * The redux store
 */
// import { createBrowserHistory, createMemoryHistory } from "history";
import { configureStore as createStore } from "@reduxjs/toolkit";
// import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware, { END } from "redux-saga";

/** Logger with default options */
import logger from "redux-logger";
import { loadState, saveState } from "../utils/localStorage";
import createRootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
/** A nice helper to tell us if we're on the server */
export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

function updatePersistedState(state) {
  let currentState = state;
  if (!isServer) {
    if (window && window.REDUX_DATA) currentState = window.REDUX_DATA;
  }
  let newState = {};
  Object.keys(currentState).forEach((reducer) => {
    if (currentState[reducer].stored) {
      if (currentState[reducer].propertiesToStore) {
        let reducerStoredState = {
          propertiesToStore: currentState[reducer].propertiesToStore,
        };
        currentState[reducer].propertiesToStore.forEach((property) => {
          if (currentState[reducer][property])
            reducerStoredState[property] = currentState[reducer][property];
        });
        newState[reducer] = reducerStoredState;
      } else newState[reducer] = currentState[reducer];
    }
  });
  return newState;
}

/**
 * Configures the store globally
 * - applies middlewares
 * - applies routing history
 * - adds root sagas and reducers
 */
export default function configureStore(tokens = {}) {
  /** the persisted state */
  const persistedState = !isServer ? loadState() : {};
  persistedState.tokens = tokens;

  /**  create the saga middleware */
  const sagaMiddleware = createSagaMiddleware({
    context: {
      tokens,
    },
  });
  // set the server url to something without ? so we do not reuse the same goole gclid or something every time
  // const url_split = url.split("?");
  // const history = isServer
  //   ? createMemoryHistory({
  //       initialEntries: [url_split[0]],
  //     })
  //   : createBrowserHistory();

  // Dev tools are helpful
  // if (process.env.NODE_ENV === 'development' && !isServer) {
  //   const devToolsExtension = window.devToolsExtension;

  //   if (typeof devToolsExtension === 'function') {
  //     enhancers.push(devToolsExtension());
  //   }
  // }
  const middlewares = [sagaMiddleware];
  // middlewares.push(routerMiddleware(history));
  if (!isServer && process.env.NODE_ENV === "development")
    middlewares.push(logger);

  const store = createStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these field paths in all actions
          ignoredActionPaths: [
            "payload.callback", // File upload
            "payload.successCallbackFnc",
            "payload.errorCallbackFnc",
          ],
        },
      }).concat(middlewares),
    devTools: process.env.NODE_ENV !== "production", //&& !isServer,
    preloadedState: persistedState,
  });

  // add here the parts of the store to persist
  store.subscribe(() => {
    const currentState = store.getState();
    let stateToSave = updatePersistedState(currentState);
    saveState(stateToSave);
  });

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return { store, sagaMiddleware, rootSaga };
}
