import PropTypes from "prop-types";
import React from "react";
import {
  MdCancel,
  MdCheckCircle,
  MdOutlineSquare,
  MdPending,
  MdSwapHoriz,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCampaignAttributes } from "../../../redux/campaigns";
import {
  getInvestmentAttributes,
  getInvestmentRelationships,
} from "../../../redux/investments";

const InvestmentListItem = (props, context) => {
  const { investmentId } = props;
  const investmentData = useSelector((state) =>
    getInvestmentAttributes(state, investmentId)
  );
  const investmentRelationships = useSelector((state) =>
    getInvestmentRelationships(state, investmentId)
  );
  const investmentCampaignData = useSelector((state) =>
    getCampaignAttributes(state, investmentRelationships?.campaign?.data?.id)
  );

  const currentLang = useSelector((state) => state.i18nState.lang);

  const renderPaymentStatus = () => {
    switch (investmentData?.payment_status) {
      case "pending":
        return <MdPending title={context.t("pending")} />;
      case "accepted":
        return <MdCheckCircle title={context.t("accepted")} />;
      case "rejected":
        return <MdCancel title={context.t("rejected")} />;
      case "refunded":
        return <MdSwapHoriz title={context.t("refunded")} />;
      default:
        return <MdOutlineSquare title={investmentData?.payment_status} />;
    }
  };

  return (
    <div className="d-flex align-items-center rounded-3 border border-1 border-dark p-3 mb-3">
      <div className="fw-bold">
        <Link
          to={`/${currentLang}/campaigns/${investmentRelationships?.campaign?.data?.id}`}
          className="text-reset text-decoration-none fw-bold"
        >
          {investmentCampaignData?.title}
        </Link>
      </div>
      <div className="ms-auto">{investmentData?.price}€</div>
      <div className="ms-3 fs-5">{renderPaymentStatus()}</div>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
InvestmentListItem.contextTypes = {
  t: PropTypes.func,
};

InvestmentListItem.propTypes = {
  investmentId: PropTypes.string.isRequired,
};

export default InvestmentListItem;
