/**
 * The ShareButtons component
 */
import PropTypes from "prop-types";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FaClipboard,
  FaClipboardCheck,
  FaFacebook,
  FaShareNodes,
  FaSquareXTwitter,
} from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { isServer } from "../../redux/store";
import { getEnv } from "../../utils/env";

/**
 * Stateless react component to render the ShareButtons
 * @param {Object} props the props passed to the component.
 */

const ShareButtons = (props, context) => {
  const REACT_APP_WEB_SERVER = getEnv("REACT_APP_WEB_SERVER");
  const { className, label, target, shareTitle, tweet, fbPost } = props;
  const location = useLocation();

  const baseUrl =
    REACT_APP_WEB_SERVER && REACT_APP_WEB_SERVER.endsWith("/")
      ? REACT_APP_WEB_SERVER.slice(0, -1)
      : REACT_APP_WEB_SERVER;

  const socialTarget = target ? target : `${baseUrl}${location.pathname}`;

  const [isCopied, setIsCopied] = useState(false);

  const onShare = () => {
    const title = shareTitle ? shareTitle : document.title;
    const text = shareTitle ? shareTitle : `${document.title}`;
    const onSuccess = () => console.log("Successful share");
    const onError = (error) => console.log("Error sharing", error);
    if (window.navigator.share) {
      window.navigator
        .share({ title, text, socialTarget })
        .then(onSuccess)
        .catch(onError);
    } else {
      console.log("Sharing not available");
    }
  };

  const unstyledButtonStyle = {
    outline: "none",
    padding: 0,
    border: 0,
    boxSizing: "none",
    backgroundColor: "transparent",
  };

  if (isServer) return null;

  return (
    <div className={className}>
      {!window?.navigator?.share && fbPost && (
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            socialTarget
          )}`}
          target="_blank"
          rel="noreferrer"
          className="me-2 mb-2 text-dark"
        >
          <FaFacebook size={32} />
        </a>
      )}
      {!window?.navigator?.share && tweet && (
        <a
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
            tweet
          )}&url=${encodeURIComponent(socialTarget)}`}
          target="_blank"
          rel="noreferrer"
          className="me-2 mb-2 text-dark"
        >
          <FaSquareXTwitter size={32} />
        </a>
      )}
      {!window?.navigator?.share && (
        <CopyToClipboard text={socialTarget} onCopy={() => setIsCopied(true)}>
          <button
            className="me-2 mb-2 text-dark"
            id="copyAddressButton"
            style={unstyledButtonStyle}
          >
            {isCopied ? (
              <>
                <FaClipboardCheck
                  size={32}
                  className="text-success"
                  id="url-copied-tooltip"
                />
                <UncontrolledTooltip target="url-copied-tooltip">
                  {context.t("Copied to clipboard")}
                </UncontrolledTooltip>
              </>
            ) : (
              <FaClipboard size={32} />
            )}
          </button>
        </CopyToClipboard>
      )}

      {window?.navigator?.share && (
        <button
          style={unstyledButtonStyle}
          onClick={onShare}
          className="me-2 mb-2 text-dark"
        >
          <FaShareNodes size={32} className="me-2" />
          {label ? label : context.t("Share now")}
        </button>
      )}
    </div>
  );
};

/** define proptypes for the ShareButtons */
ShareButtons.propTypes = {
  className: PropTypes.string,
  target: PropTypes.string,
  shareTitle: PropTypes.string,
  location: PropTypes.object,
  tweet: PropTypes.string,
  fbPost: PropTypes.bool,
  label: PropTypes.string,
};

ShareButtons.defaultProps = {};

/**  define proptype for the 'translation' function  */
ShareButtons.contextTypes = {
  t: PropTypes.func,
};

export default ShareButtons;
