import PropTypes from "prop-types";
import React, { useState } from "react";
import { MdCheck, MdLock, MdLockOpen } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getPerkAttributes, unlockPerk } from "../../../redux/perks";

/**
 * Table container used as app default landing page
 */
const PerkCard = ({ perkId }, context) => {
  const perkData = useSelector((state) => getPerkAttributes(state, perkId));
  const perkUnlockErrors = useSelector((state) => state.perks.unlockErrors);
  const unlockError = perkUnlockErrors?.[perkId];
  const dispatch = useDispatch();
  const handleUnlockPerk = () => {
    dispatch(unlockPerk({ id: perkId }));
  };

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const claimedCount = perkData?.codes?.length || 0;
  const unclaimedCount = perkData?.unclaimed_count || 0;
  const isUnlocked = claimedCount > 0;
  const canUnlock = unclaimedCount > 0;
  const renderPerkStatus = () => {
    return (
      <span>
        <span className="bg-secondary p-2 rounded rounded-circle">
          {isUnlocked ? <MdCheck /> : canUnlock ? <MdLockOpen /> : <MdLock />}
        </span>
      </span>
    );
  };

  const renderClaimableMessage = () => {
    const perkLimit = perkData?.perk_unlocks_limit;
    switch (perkData?.perk_type) {
      case "first_shares":
        return context.t("Claimable by first {n} shares", {
          n: perkLimit,
        });
      case "first_investors":
        return context.t("Claimable by first {n} investors", {
          n: perkLimit,
        });
      case "random_shares":
        return context.t("Claimable by random {n} shares", {
          n: perkLimit,
        });
      case "random_investors":
        return context.t("Claimable by random {n} investors", {
          n: perkLimit,
        });
      case "all_shares":
        return context.t("Claimable by all shares");
      case "all_investors":
        return context.t("Claimable by all investors");
      default:
        return "";
    }
  };

  return (
    <>
      <Card
        className="p-0 h-100 shadow-sm"
        onClick={() => setShowModal(true)}
        role="button"
      >
        <div className="position-relative">
          <div className="ratio ratio-1x1 overflow-hidden">
            {perkData?.header_original?.url && (
              <img
                src={perkData.header_original.url}
                className="img-fluid card-img-top h-100 object-fit-cover"
                alt={perkData?.title}
              />
            )}
          </div>
          <div className="position-absolute top-0 start-0 mt-2 ms-2">
            {renderPerkStatus()}
          </div>
          {perkData?.perk_unlocks_limit && (
            <span className="position-absolute bottom-0 end-0 mb-2 me-2">
              <Badge pill color="secondary" className="text-dark">
                {context.t("Limited")}
              </Badge>
            </span>
          )}
        </div>
        <CardBody className="d-flex flex-column">
          <p className="h5">{perkData?.title}</p>
          <p className="small">
            {renderClaimableMessage()} {context.t("after the campaign ended")}.
          </p>
          <div className="d-flex align-items-center small">
            <div>
              <div>{context.t("total claimed")}</div>
              <div className="fw-bold">
                {perkData?.claimed_unlocks_count} /{" "}
                {perkData?.perk_unlocks_limit}
              </div>
            </div>
            {perkData?.perk_shares_required && (
              <div className="ms-auto text-end">
                <div>{context.t("Shares required")}</div>
                <div className="fw-bold">{perkData?.perk_shares_required}</div>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center small">
            {isUnlocked && (
              <div>
                <div>{context.t("claimed by you")}</div>
                <div className="fw-bold">{claimedCount}</div>
              </div>
            )}
            {canUnlock && (
              <div>
                <div>{context.t("claimable by you")}</div>
                <div className="fw-bold">{unclaimedCount}</div>
              </div>
            )}
          </div>
          <div className="d-flex mt-auto">
            <Button color="link" className="stretched-link p-0 ms-auto">
              {context.t("Show more")}
            </Button>
          </div>
        </CardBody>
      </Card>
      <Modal
        isOpen={showModal}
        toggle={toggleModal}
        centered
        scrollable
        fullscreen="sm"
      >
        <ModalHeader toggle={toggleModal}>{perkData?.title}</ModalHeader>
        <ModalBody>
          {perkData?.header_original?.url && (
            <div className="ratio ratio-1x1 overflow-hidden mb-3">
              <img
                src={perkData.header_original.url}
                className="img-fluid w-100 h-100 object-fit-cover"
                alt={perkData?.title}
              />
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: perkData?.description }} />
          {unlockError && (
            <Alert color="danger">
              <p>{unlockError?.full_error_message}</p>
            </Alert>
          )}
          {isUnlocked && (
            <Alert>
              <p>
                {context.t(
                  "You have successfully unlocked this perk. Your Unique Voucher Codes are"
                )}
                :{" "}
                {perkData?.codes.map((unlockCode) => (
                  <p key={unlockCode} className="fw-bold">
                    {unlockCode}
                  </p>
                ))}
              </p>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          {canUnlock ? (
            <Button
              onClick={() => handleUnlockPerk()}
              color="primary"
              className="w-100"
            >
              {context.t("Unlock")}
            </Button>
          ) : (
            <Button color="primary" onClick={toggleModal}>
              {context.t("Close")}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

/**  define proptype for the 'translation' function  */
PerkCard.contextTypes = {
  t: PropTypes.func,
};

PerkCard.propTypes = {
  perkId: PropTypes.string.isRequired,
};

/** export the component as redux connected component */
export default PerkCard;
