import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import { getFaqAttributes } from "../../../../redux/faqs";

const FaqEntry = ({ faqId }) => {
  const faqData = useSelector((state) => getFaqAttributes(state, faqId));

  return (
    <AccordionItem>
      <AccordionHeader targetId={faqId} tag="h3">
        <strong>{faqData.title}</strong>
      </AccordionHeader>
      <AccordionBody accordionId={faqId}>
        <div dangerouslySetInnerHTML={{ __html: faqData.description }} />
      </AccordionBody>
    </AccordionItem>
  );
};

FaqEntry.propTypes = {
  faqId: PropTypes.string.isRequired,
};

FaqEntry.contextTypes = {
  t: PropTypes.func,
};

export default FaqEntry;
