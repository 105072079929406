import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { ArtistEvents, ArtistSocials } from "..";
import {
  getArtistAttributes,
  getArtistRelationships,
} from "../../../redux/artists";
import { CampaignPreview } from "../../Campaign";
import { ReleaseCard } from "../../Release";
import ArtistShowNavigation from "./utils/ArtistShowNavigation";

const ArtistShow = ({ artistId }, context) => {
  const artistData = useSelector((state) =>
    getArtistAttributes(state, artistId)
  );

  const artistRelationships = useSelector((state) =>
    getArtistRelationships(state, artistId)
  );

  const hasCampaigns = artistRelationships?.campaigns?.data?.length > 0;
  const hasDiscography = artistRelationships?.releases?.data?.length > 0;
  const hasEvents = artistRelationships?.events?.data?.length > 0;

  return (
    <>
      <div className="position-relative artistshow__header">
        <div className="ratio ratio-21x9">
          {artistData?.header_original?.url && (
            <img
              src={artistData?.header_original?.url}
              className="img-fluid card-img-top h-100 object-fit-cover"
              alt={artistData?.name}
            />
          )}
        </div>
        <div className="position-absolute start-0 end-0 bottom-0 artistshow__header__gradient">
          <Container className="mt-5 pt-5 mb-4">
            <div className="d-flex align-items-center">
              {artistData?.logo_original?.url && (
                <img
                  src={artistData?.logo_original?.url}
                  alt="Artist logo"
                  className="img-fluid me-3 rounded "
                  style={{ width: "15%" }}
                />
              )}
              <div>
                <h1>{artistData?.name}</h1>
                <p className="mb-0">
                  {artistData?.genre}
                  {artistData?.location && ` | ${artistData.location}`}
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <ArtistShowNavigation
        hasCampaigns={hasCampaigns}
        hasDiscography={hasDiscography}
        hasEvents={hasEvents}
      />

      <section className="my-5" id="artist-about">
        <Container>
          <h2 className="mb-4">
            {context.t("About")} {artistData?.name}
          </h2>
          <Row>
            <Col xl={{ size: 4, order: 2 }}>
              <div className="d-flex gap-3 mb-4">
                <ArtistSocials artistId={artistId} />
              </div>
              <Row>
                {artistData?.genre && (
                  <Col xs="6" md="3" xl="12">
                    <p className="fw-bold mb-1">{context.t("Genre")}</p>
                    <p>{artistData.genre}</p>
                  </Col>
                )}
                {artistData?.location && (
                  <Col xs="6" md="3" xl="12">
                    <p className="fw-bold mb-1">{context.t("Location")}</p>
                    <p>{artistData?.location}</p>
                  </Col>
                )}
                {artistData?.founded_at && (
                  <Col xs="6" md="3" xl="12">
                    <p className="fw-bold mb-1">{context.t("Founded")}</p>
                    <p>{artistData?.founded_at}</p>
                  </Col>
                )}
                {artistData?.members_count && (
                  <Col xs="6" md="3" xl="12">
                    <p className="fw-bold mb-1">{context.t("Members")}</p>
                    <p>{artistData?.members_count}</p>
                  </Col>
                )}
              </Row>
            </Col>
            <Col xl={{ size: 8, order: 1 }}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {artistData?.description}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {hasCampaigns && (
        <section className="my-5" id="artist-campaigns">
          <Container>
            <h2 className="mb-4">{context.t("Campaigns")}</h2>
            {artistRelationships.campaigns.data.map((campaign) => (
              <CampaignPreview campaignId={campaign?.id} key={campaign?.id} />
            ))}
          </Container>
        </section>
      )}

      {hasDiscography && (
        <section className="my-5" id="artist-discography">
          <Container>
            <h2 className="mb-4">{context.t("Discography")}</h2>
            <Row>
              {artistRelationships.releases.data.map((release) => (
                <Col
                  key={release?.id}
                  xs="6"
                  md="4"
                  lg="3"
                  xxl="2"
                  style={{ marginBottom: "var(--bs-gutter-x)" }}
                >
                  <ReleaseCard releaseId={release?.id} />
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      )}

      {hasEvents && (
        <section className="my-5" id="artist-events">
          <Container>
            <ArtistEvents artistId={artistId} />
          </Container>
        </section>
      )}
    </>
  );
};

/**  define proptype for the 'translation' function  */
ArtistShow.contextTypes = {
  t: PropTypes.func,
};

ArtistShow.propTypes = {
  artistId: PropTypes.string.isRequired,
};

export default ArtistShow;
