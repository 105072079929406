/**
 * The TalenzzKpi component
 */
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "reactstrap";

/**
 * Stateless react component to render the TalenzzKpi
 * @param {Object} props the props passed to the component.
 */

const TalenzzKpi = (props, context) => {
  return (
    <Container className="py-5">
      <Row className="border-top border-bottom border-black border-2 py-4 text-center text-lg-start ">
        <Col lg={3}>
          <div className="d-flex flex-column justify-content-center h-100">
            <div className="fs-1 fw-bold">{context.t("talenzz Facts")}:</div>
          </div>
        </Col>
        <Col lg={3} className="mt-5 mt-lg-0">
          <div className="display-2 fw-bold">1%</div>
          <div className="fw-bold">
            {context.t("Chance of being signed by a conventional label")}
          </div>
        </Col>
        <Col lg={3} className="mt-5 mt-lg-0">
          <div className="display-2 fw-bold">10€</div>
          <div className="fw-bold">
            {context.t(
              "Per person and 1000 true fans and an album is financed"
            )}
          </div>
        </Col>
        <Col lg={3} className="mt-5 mt-lg-0">
          <div className="display-2 fw-bold">100%</div>
          <div className="fw-bold">{context.t("Creative Freedom")}</div>
        </Col>
      </Row>
    </Container>
  );
};

/** define proptypes for the TalenzzKpi */
TalenzzKpi.propTypes = {};

TalenzzKpi.defaultProps = {};

/**  define proptype for the 'translation' function  */
TalenzzKpi.contextTypes = {
  t: PropTypes.func,
};

export default TalenzzKpi;
