/**
 * The root reducer function. This function is used by the store to mutate its state. All reducers should subscirbe in this file in order to have effect
 */
import { combineReducers } from "redux";
// import { connectRouter } from "connected-react-router";
import { i18nState } from "redux-i18n";
// import servWork from "./servWork/reducers";
import accounts from "./accounts";
import artists from "./artists";
import campaigns from "./campaigns";
import contents from "./contents";
import events from "./events";
import faqs from "./faqs";
import investments from "./investments";
import payments from "./payments";
import perks from "./perks";
import releases from "./releases";
import tokens from "./tokens";
import staticPages from "./staticPages";
/** exports all reducers through the combineReducers function */
const rootReducer = () =>
  combineReducers({
    // router: connectRouter(history),
    i18nState,
    // servWork,
    accounts,
    artists,
    campaigns,
    contents,
    events,
    faqs,
    investments,
    payments,
    perks,
    releases,
    tokens,
    staticPages,
  });
export default rootReducer;
