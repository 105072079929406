// Basic styleguide react component
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import Page from "../components/layout/Page";

const Styleguide = (props, context) => {
  return (
    <Page id="dashboard" title="Styleguide" noCrawl>
      <header className="Styleguide-header">
        <Container>
          <h1 className="display-1">{context.t("Hello World")}</h1>
        </Container>
      </header>
      {/* <Container className="Styleguide">
        <h1 className="pt-3 display-3">Date, Time & Number Formatting</h1>
        Date: {moment().format(context.t("YYYY-MM-DD"))}
        <br />
        Moment: {moment().format("LLL")}
        <br />
        FromNow:{" "}
        <Moment
          locale={language}
          date={moment().subtract(12, "minutes")}
          fromNow
        />
        <br />
        FormatedNumber:{" "}
        <FormattedNumber
          value={Math.round(123213123 * 10) / 100}
          language={language}
          unit="cm"
          decimals={2}
        />
      </Container> */}
      <Container className="py-4 mt-2">
        <Row className="py-4">
          <Col>
            <h1 className="pt-3 display-3">Bootstrap Elements</h1>
            <p className="lead">
              Kickstart your project with a variety of reusable, stylish,
              modular widgets. These widgets utilize the core{" "}
              <span className="font-weight-bold">Bootstrap</span> components, so
              they <em>don&apos;t</em> require extra libraries or scripts to
              function.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="py-4 mt-2">
        <h1>Typography</h1>
        <h2>Headings</h2>
        <h1>h1. Bootstrap heading</h1>
        <h2>h2. Bootstrap heading</h2>
        <h3>h3. Bootstrap heading</h3>
        <h4>h4. Bootstrap heading</h4>
        <h5>h5. Bootstrap heading</h5>
        <h6>h6. Bootstrap heading</h6>

        <h2>Display Headings</h2>
        <h1 className="display-1">Display 1</h1>
        <h1 className="display-2">Display 2</h1>
        <h1 className="display-3">Display 3</h1>
        <h1 className="display-4">Display 4</h1>

        <h2>Lead</h2>
        <p className="lead">
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
          Duis mollis, est non commodo luctus.
        </p>

        <h2>Inline Text elements</h2>
        <p>
          You can use the mark tag to <mark>highlight</mark> text.
        </p>
        <p>
          <del>This line of text is meant to be treated as deleted text.</del>
        </p>
        <p>
          <s>This line of text is meant to be treated as no longer accurate.</s>
        </p>
        <p>
          <ins>
            This line of text is meant to be treated as an addition to the
            document.
          </ins>
        </p>
        <p>
          <u>This line of text will render as underlined</u>
        </p>
        <p>
          <small>This line of text is meant to be treated as fine print.</small>
        </p>
        <p>
          <strong>This line rendered as bold text.</strong>
        </p>
        <p>
          <em>This line rendered as italicized text.</em>
        </p>
      </Container>
      <Container className="py-4 mt-2">
        <h1>Colors</h1>
        <Row>
          <Col md={3}>
            <div className="card mb-3">
              <div className="card-img-top bg-primary p-5"> </div>
              <div className="card-body">
                <h5 className="card-title">Primary</h5>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card mb-3">
              <div className="card-img-top bg-secondary p-5"> </div>
              <div className="card-body">
                <h5 className="card-title">Secondary</h5>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card mb-3">
              <div className="card-img-top bg-tertiary p-5"> </div>
              <div className="card-body">
                <h5 className="card-title">Tertiary</h5>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card mb-3">
              <div className="card-img-top bg-success p-5"> </div>
              <div className="card-body">
                <h5 className="card-title">Success</h5>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card mb-3">
              <div className="card-img-top bg-danger p-5"> </div>
              <div className="card-body">
                <h5 className="card-title">Danger</h5>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card mb-3">
              <div className="card-img-top bg-warning p-5"> </div>
              <div className="card-body">
                <h5 className="card-title">Warning</h5>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card mb-3">
              <div className="card-img-top bg-info p-5"> </div>
              <div className="card-body">
                <h5 className="card-title">Info</h5>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card mb-3">
              <div className="card-img-top bg-light p-5"> </div>
              <div className="card-body">
                <h5 className="card-title">Light</h5>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card mb-3">
              <div className="card-img-top bg-dark p-5"> </div>
              <div className="card-body">
                <h5 className="card-title">Dark</h5>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card mb-3">
              <div className="card-img-top bg-white p-5"> </div>
              <div className="card-body">
                <h5 className="card-title">White</h5>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="py-4 mt-2">
        <Row>
          <Col>
            <h2>Tabs</h2>
            <ul id="tabsJustified" className="nav nav-tabs">
              <li className="nav-item">
                <a
                  href="/"
                  data-target="#home1"
                  data-toggle="tab"
                  className="nav-link small text-uppercase"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/"
                  data-target="#profile1"
                  data-toggle="tab"
                  className="nav-link small text-uppercase active"
                >
                  Profile
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/"
                  data-target="#messages1"
                  data-toggle="tab"
                  className="nav-link small text-uppercase"
                >
                  Messages
                </a>
              </li>
            </ul>
            <br />
            <div id="tabsJustifiedContent" className="tab-content">
              <div id="home1" className="tab-pane fade">
                <div className="list-group">
                  <a href="/" className="list-group-item d-inline-block">
                    <span className="float-right badge rounded-pill bg-dark">
                      51
                    </span>{" "}
                    Home Link
                  </a>{" "}
                  <a href="/" className="list-group-item d-inline-block">
                    <span className="float-right badge rounded-pill bg-dark">
                      8
                    </span>
                    Link 2
                  </a>{" "}
                  <a href="/" className="list-group-item d-inline-block">
                    <span className="float-right badge rounded-pill bg-dark">
                      23
                    </span>{" "}
                    Link 3
                  </a>{" "}
                  <a
                    href="/"
                    className="list-group-item d-inline-block text-muted"
                  >
                    Link n..
                  </a>
                </div>
              </div>
              <div id="profile1" className="tab-pane fade active show">
                <Row className="pb-2">
                  <div className="col-md-7">
                    <p>
                      Tabs can be used to contain a variety of content &amp;
                      elements. They are a good way to group{" "}
                      <a href="/" className="link">
                        relevant content
                      </a>
                      . Display initial content in context to the user. Enable
                      the user to flow through
                      <a href="/" className="link">
                        more
                      </a>{" "}
                      information as needed.
                    </p>
                  </div>
                  <div className="col-md-5">
                    <img
                      src="//dummyimage.com/1005x559.png/5fa2dd/ffffff"
                      className="float-right img-fluid img-rounded"
                      alt="dummy"
                    />
                  </div>
                </Row>
              </div>
              <div id="messages1" className="tab-pane fade">
                <div className="list-group">
                  <a href="/" className="list-group-item d-inline-block">
                    <span className="float-right badge rounded-pill bg-dark">
                      44
                    </span>{" "}
                    Message 1
                  </a>{" "}
                  <a href="/" className="list-group-item d-inline-block">
                    <span className="float-right badge rounded-pill bg-dark">
                      8
                    </span>
                    Message 2
                  </a>{" "}
                  <a href="/" className="list-group-item d-inline-block">
                    <span className="float-right badge rounded-pill bg-dark">
                      23
                    </span>{" "}
                    Message 3
                  </a>{" "}
                  <a
                    href="/"
                    className="list-group-item d-inline-block text-muted"
                  >
                    Message n..
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="py-4 mt-2">
        <Row>
          <Col>
            <h2>Progress Bars</h2>
            <div id="card_stats">
              <div className="card-body p-0">
                <Row>
                  <div className="col-lg-6 my-2">
                    <div className="card card-body p-3">
                      <span className="text-primary">
                        <span className="badge bg-primary float-right">
                          + 13%
                        </span>{" "}
                        Sign-ups{" "}
                      </span>
                      <div className="progress mt-4">
                        <div className="progress-bar w-25 bg-primary wow slideInLeft"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 my-2">
                    <div className="card card-body p-3">
                      <span className="text-primary">
                        <span className="badge bg-primary float-right">
                          80%
                        </span>{" "}
                        Usage{" "}
                      </span>
                      <div className="progress mt-4">
                        <div className="progress-bar w-75 wow slideInLeft"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 my-2">
                    <div className="card card-body p-3">
                      <span className="text-primary">
                        <span className="badge bg-primary float-right">78</span>{" "}
                        Bursts{" "}
                      </span>
                      <div className="progress mt-4">
                        <div className="progress-bar bg-primary w-75 wow slideInLeft"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 my-2">
                    <div className="card card-body p-3">
                      <span className="text-success">
                        <span className="badge bg-success float-right">
                          + 26%
                        </span>{" "}
                        Returning{" "}
                      </span>
                      <div className="progress mt-4">
                        <div className="progress-bar bg-success w-25 wow slideInLeft"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 my-2">
                    <div className="card card-body p-3">
                      <span className="text-primary">
                        <span className="badge bg-primary float-right">
                          201
                        </span>{" "}
                        Sales{" "}
                      </span>
                      <div className="progress mt-4">
                        <div className="progress-bar w-50 bg-primary wow slideInLeft"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 my-2">
                    <div className="card card-body p-3">
                      <span className="text-success">
                        <span className="badge bg-success float-right">
                          + 74%
                        </span>{" "}
                        Pageviews{" "}
                      </span>
                      <div className="progress mt-4">
                        <div className="progress-bar bg-success w-75 wow slideInLeft"></div>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="py-4 mt-2">
        <Row>
          <Col>
            <h2>Lists</h2>
            <div id="card_contacts" className="card border-0">
              <div
                id="contacts"
                aria-expanded="true"
                role="tabpanel"
                className="panel-collapse collapse show"
              >
                <ul id="contact-list" className="list-group">
                  <li className="list-group-item d-block">
                    <Row>
                      <div className="col-12 col-sm-6 col-md-2">
                        <img
                          src="//placehold.it/80"
                          alt="Mike Anamendolla"
                          className="img-fluid rounded-circle mx-auto d-block"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-md-10 text-center text-sm-left">
                        <label className="name">Mike Anamenda</label>
                        <br />{" "}
                        <span className="text-muted">5842 Hillcrest Rd</span>
                        <br />{" "}
                        <span className="text-muted small">(870) 288-4149</span>
                        <br />{" "}
                        <a href="/" className="link small text-truncate">
                          mike.ana@example.com
                        </a>
                      </div>
                    </Row>
                  </li>
                  <li className="list-group-item d-block">
                    <Row>
                      <div className="col-12 col-sm-6 col-md-2">
                        <img
                          src="//placehold.it/80"
                          alt="Seth Frazier"
                          className="img-fluid rounded-circle mx-auto d-block"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-md-10 text-center text-sm-left">
                        <label className="name">Seth Frazier</label>
                        <br />{" "}
                        <span className="text-muted">7396 E North St</span>
                        <br />{" "}
                        <span className="text-muted small">(560) 180-4143</span>
                        <br />{" "}
                        <a href="/" className="link small text-truncate">
                          seth.frazier@example.com
                        </a>
                      </div>
                    </Row>
                  </li>
                  <li className="list-group-item d-block">
                    <Row>
                      <div className="col-12 col-sm-6 col-md-2">
                        <img
                          src="//placehold.it/80"
                          alt="Rosemary Porter"
                          className="img-fluid rounded-circle mx-auto d-block"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-md-10 text-center text-sm-left">
                        <span
                          title="left you a message"
                          className="fa fa-envelope fa-lg text-danger float-right"
                        ></span>
                        <label className="name">Rosemary Porter</label>
                        <br />{" "}
                        <span className="text-muted">5267 Cackson St</span>
                        <br />{" "}
                        <span className="text-muted small">(497) 160-9776</span>
                        <br />{" "}
                        <a href="/" className="link small text-truncate">
                          rosemary.porter@example.com
                        </a>
                      </div>
                    </Row>
                  </li>
                  <li className="list-group-item d-block">
                    <Row>
                      <div className="col-12 col-sm-6 col-md-2">
                        <img
                          src="//placehold.it/80"
                          alt="Debbie Schmidt"
                          className="img-fluid rounded-circle mx-auto d-block"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-md-10 text-center text-sm-left">
                        <label className="name">Debbie Schmidt</label>
                        <br />{" "}
                        <span className="text-muted">3903 W Alexander Rd</span>
                        <br />{" "}
                        <span className="text-muted small">(867) 322-1852</span>
                        <br />{" "}
                        <a href="/" className="link small text-truncate">
                          debbie.schmidt@example.com
                        </a>
                      </div>
                    </Row>
                  </li>
                </ul>
              </div>
            </div>
            <Row className="my-3">
              <div className="col-sm-6">
                <div id="card_list" className="card border-0">
                  <div className="list-group">
                    <a href="/" className="list-group-item">
                      <p className="list-group-item-text m-0">Grapes</p>
                    </a>
                    <a
                      href="/"
                      className="list-group-item justify-content-between"
                    >
                      <p className="list-group-item-text m-0">Milk</p>{" "}
                      <span>
                        <i className="ion-ios-star-outline"></i>
                      </span>
                    </a>
                    <a href="/" className="list-group-item">
                      <p className="list-group-item-text m-0">Apple Chips</p>
                    </a>
                    <a href="/" className="list-group-item">
                      <p className="list-group-item-text m-0">Fried Dough</p>
                    </a>
                    <a href="/" className="list-group-item">
                      <p className="list-group-item-text m-0">Berries</p>
                    </a>
                    <a href="/" className="list-group-item">
                      <p className="list-group-item-text m-0">Salad</p>
                    </a>
                    <a href="/" className="list-group-item">
                      <p className="list-group-item-text m-0">
                        Cookies &amp; Crackers
                      </p>
                    </a>
                    <a href="/" className="list-group-item">
                      <p className="list-group-item-text m-0 font-weight-bold">
                        See More...
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div id="accordion" className="accordion">
                  <div className="card card-default panel">
                    <a
                      href="/"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      data-target="#collapseOne"
                      className="card-header text-white bg-dark"
                    >
                      <span className="card-title">Accordion Item 1</span>
                    </a>
                    <div id="collapseOne" className="card-body collapse show">
                      <p>
                        Food truck quinoa nesciunt laborum for labo lucn. Nihil
                        anim keffiyeh helvetica, craft beer labore wes anderson
                        cred nesciunt sapiente ea proident. Leggings occaecat
                        craft beer farm-to-table, raw denim aesthetic synth
                        nesciunt you probably haven&apos;t heard of them
                        accusamus labore sustainable VHS.
                      </p>
                    </div>{" "}
                    <a
                      href="/"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      data-target="#collapseTwo"
                      className="card-header text-white bg-dark collapsed"
                    >
                      <span className="card-title">Accordion Item 2</span>
                    </a>
                    <div id="collapseTwo" className="card-body collapse">
                      <p>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. Officia aute,
                        non cupidatat skateboard dolor brunch. Food truck quinoa
                        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt cred nesciunt sapiente ea proident. Ad vegan
                        excepteur butcher vice lomo. Leggings occaecat craft
                        beer farm-to-table, raw denim aesthetic synth nesciunt
                        you probably haven&apos;t heard of them accusamus labore
                        sustainable VHS.
                      </p>
                    </div>{" "}
                    <a
                      href="/"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      data-target="#collapseThree"
                      className="card-header text-white bg-dark collapsed"
                    >
                      <span className="card-title">Accordion Item 3</span>
                    </a>
                    <div id="collapseThree" className="card-body collapse">
                      <p>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        samus labore sustainable VHS.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>{" "}
      <span id="jumbotron" className="anchor"></span>
      <Container className="py-4 mt-2 d-flex align-items-center justify-content-center vh-100">
        <Row className="w-100">
          <div className="col mb-4">
            <div
              id="header"
              className="jumbotron mb-0 d-flex align-items-center flex-column justify-content-center p-5"
            >
              <h1 className="display-4">Jumbotron</h1>
              <p>Some perfectly centered content goes here</p>
              <p className="lead">
                <a href="/" role="button" className="btn btn-secondary btn-lg">
                  Learn more
                </a>
              </p>
            </div>
          </div>
        </Row>
      </Container>
      <Container className="py-4 mt-2">
        <Row>
          <div className="col-md-12">
            <h2>Cards</h2>
            <div id="card_counts" className="p-0">
              <Row>
                <div className="col-sm-4">
                  <div className="card text-white bg-dark text-center pt-2">
                    <div className="card-body card-title">
                      <h6 className="text-light">56 Likes</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-white bg-primary text-center pt-2">
                    <div className="card-body card-title">
                      <h6 className="text-light">209 Followers</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-white bg-success text-center pt-2">
                    <div className="card-body card-title">
                      <h6 className="text-light">20 Snaps</h6>
                    </div>
                  </div>
                </div>
                <div className="w-100 py-3"></div>
                <div className="col-sm-4">
                  <div className="card text-white bg-danger text-center pt-2">
                    <div className="card-body card-title">
                      <h6 className="text-light">1,110 Views</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-white bg-info text-center pt-2">
                    <div className="card-body card-title">
                      <h6 className="text-light">90 Views</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-white bg-warning text-center pt-2">
                    <div className="card-body card-title">
                      <h6 className="text-light">44 Apps</h6>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
            <Row className="py-3">
              <div className="col-lg-4 col-sm-6 py-3">
                <div className="card card-default h-100">
                  <div className="card-img-top">
                    <img
                      src="//placehold.it/600x300"
                      alt="card 1"
                      className="grayscale img-fluid mx-auto d-block"
                    />
                  </div>
                  <div className="card-body d-flex flex-column">
                    <p className="text-justify">
                      Ovi lipsim diro? Wi, wi, garius azdipiscing elit. Duis pha
                      codeply.
                    </p>{" "}
                    <a
                      href="/"
                      data-target="#profileModal"
                      data-toggle="modal"
                      data-caption="Tammy"
                      data-image="//placehold.it/600x300"
                      className="btn btn-secondary btn-lg btn-block text-truncate mt-auto"
                    >
                      View Profile
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 py-3">
                <div className="card card-default h-100">
                  <div className="card-img-top">
                    <img
                      src="//placehold.it/600x300"
                      alt="card 2"
                      className="grayscale img-fluid mx-auto d-block"
                    />
                  </div>
                  <div className="card-body d-flex flex-column">
                    <p className="text-justify">Shiny, shiny, shiny.</p>{" "}
                    <a
                      href="/"
                      data-target="#profileModal"
                      data-toggle="modal"
                      data-caption="Marcus"
                      data-image="//placehold.it/600x300"
                      className="btn btn-secondary btn-lg btn-block text-truncate mt-auto"
                    >
                      View Profile
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mx-auto py-3">
                <div className="card card-default h-100">
                  <div className="card-img-top">
                    <img
                      src="//placehold.it/600x300"
                      alt="card 3"
                      className="grayscale img-fluid mx-auto d-block"
                    />
                  </div>
                  <div className="card-body d-flex flex-column">
                    <p className="text-justify">
                      Varius azdipiscing elit. Duis pharetra, ovi lipsim diro?
                    </p>{" "}
                    <a
                      href="/"
                      data-target="#profileModal"
                      data-toggle="modal"
                      data-caption="Carry"
                      data-image="//placehold.it/600x300"
                      className="btn btn-secondary btn-lg btn-block text-truncate mt-auto"
                    >
                      View Profile
                    </a>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Row>
      </Container>{" "}
      <span id="comments" className="anchor"></span>
      <Container className="py-4 mt-2">
        <Row id="comments" className="comments">
          <div className="col-12 mb-4">
            <h3 className="mb-4">Comments</h3>
            <Row className="comment mb-3 ">
              <div className="comment-avatar col-lg-1 col-md-2 col-4 text-center">
                <a href="/">
                  <img
                    src="//placehold.it/80"
                    alt="avatar"
                    className="mx-auto rounded-circle img-fluid"
                  />
                </a>
              </div>
              <div className="comment-content col-lg-11 col-md-10 col-12">
                <h6 className="small comment-meta">
                  <a href="/">admin</a> | Today, 2:38
                </h6>
                <div className="comment-body">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod <a href="/">link to a page</a>
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo.
                    <br />{" "}
                    <a href="/" className="text-right small">
                      <i className="ion-reply"></i> Reply
                    </a>
                  </p>
                </div>
              </div>
              <div className="comment-reply col-lg-11 col-md-10 col-12">
                <Row>
                  <div className="comment-avatar col-lg-1 col-md-2 text-center">
                    <a href="/">
                      <img
                        src="//placehold.it/80"
                        alt="avatar"
                        className="mx-auto rounded-circle img-fluid"
                      />
                    </a>
                  </div>
                  <div className="comment-content col-lg-11 col-md-10 col-12">
                    <h6 className="small comment-meta">
                      <a href="/">phildownney</a> | Today, 12:31
                    </h6>
                    <div className="comment-body">
                      <p>
                        Really?? Consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud exercitat.
                        <br />{" "}
                        <a href="/" className="text-right small">
                          <i className="ion-reply"></i> Reply
                        </a>
                      </p>
                    </div>
                  </div>
                </Row>
              </div>
            </Row>
            <Row className="comment mb-3">
              <div className="comment-avatar col-lg-1 col-md-2 col-4 text-center">
                <a href="/">
                  <img
                    src="//placehold.it/80"
                    alt="avatar"
                    className="mx-auto rounded-circle img-fluid"
                  />
                </a>
              </div>
              <div className="comment-content col-lg-11 col-md-10 col-12">
                <h6 className="small comment-meta">
                  <a href="/">maslarino</a> | Yesterday, 5:03 PM
                </h6>
                <div className="comment-body">
                  <p>
                    Sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo.
                    <br />{" "}
                    <a href="/" className="text-right small">
                      <i className="ion-reply"></i> Reply
                    </a>
                  </p>
                </div>
              </div>
            </Row>
            <Row className="pt-2">
              <div className="col-12">
                <a href="/" className="btn btn-sm btn-primary">
                  Comment
                </a>
              </div>
            </Row>
          </div>
        </Row>
      </Container>{" "}
      <span id="alerts" className="anchor"></span>
      <Container className="py-4 mt-2">
        <Row>
          <div className="col-md-12">
            <h2>Alerts &amp; Notifications</h2>
            <Row>
              <div className="col-md-12">
                <div role="alert" className="alert alert-success">
                  <h4 className="alert-heading">Yes!</h4> Aww yeah, you
                  successfully read this important alert message. This example
                  text is going to run a bit longer so that you can see how
                  spacing within an alert works with this kind of content.
                  Imagine the content here.
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-sm-6">
                <div
                  role="alert"
                  className="mt-1 alert alert-info alert-dismissible fade show"
                >
                  <button
                    type="button"
                    data-dismiss="alert"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>{" "}
                  <span className="font-weight-bold">Heads up!</span> Vestibulum
                  tincidunt ullamcorper eros eget luctus. Nulla{" "}
                  <a href="/" className="alert-link">
                    info
                  </a>{" "}
                  porttitor libero.
                </div>
                <div
                  role="alert"
                  className="mt-1 alert alert-danger alert-dismissible fade show"
                >
                  <button
                    type="button"
                    data-dismiss="alert"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>{" "}
                  <span className="font-weight-bold">Yo there!</span> You should
                  check
                  <a href="/" className="alert-link">
                    danger
                  </a>{" "}
                  in on some of those fields below.
                </div>
                <div
                  role="alert"
                  className="mt-1 alert alert-warning alert-dismissible fade show"
                >
                  <button
                    type="button"
                    data-dismiss="alert"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  Food truck fixie locavore Exercitation, blog{" "}
                  <a href="/" className="alert-link">
                    warning
                  </a>{" "}
                  sartorial PBR leggings.
                </div>
              </div>
              <div className="col-sm-6">
                <div
                  role="alert"
                  className="mt-1 alert alert-primary bg-primary text-white alert-dismissible fade show"
                >
                  <button
                    type="button"
                    data-dismiss="alert"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>{" "}
                  <span className="font-weight-bold">Womp!</span> Vestibulum
                  tincidunt ullamcorper eros eget luctus. Nulla porttitor
                  libero.
                </div>
                <div
                  role="alert"
                  className="mt-1 alert alert-danger bg-danger text-white alert-dismissible fade show"
                >
                  <button
                    type="button"
                    data-dismiss="alert"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>{" "}
                  <span className="font-weight-bold">Danger!</span> Vestibulum
                  tincidunt ullamcorper eros eget luctus. Nulla porttitor
                  libero.
                </div>
                <div
                  role="alert"
                  className="mt-1 alert alert-success bg-success alert-dismissible fade show"
                >
                  <button
                    type="button"
                    data-dismiss="alert"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>{" "}
                  <span className="font-weight-bold">Success!</span> Vestibulum
                  tincidunt ullamcorper eros eget luctus. Nulla porttitor
                  libero.
                </div>
              </div>
            </Row>
          </div>
        </Row>
      </Container>{" "}
      <span id="modals" className="anchor"></span>
      <Container className="py-4 mt-2">
        <Row>
          <div className="col-md-12">
            <h2>Modals</h2>
            <h6 className="text-center text-wide mt-3">Examples</h6>
            <ul className="list-inline text-center">
              <li className="list-inline-item py-1">
                <a
                  href="/"
                  data-target="#myModal"
                  data-toggle="modal"
                  className="btn btn-outline-secondary btn-sm"
                >
                  Standard
                </a>
              </li>
              <li className="list-inline-item py-1">
                <a
                  href="/"
                  data-target="#smallModal"
                  data-toggle="modal"
                  className="btn btn-outline-secondary btn-sm"
                >
                  Small
                </a>
              </li>
              <li className="list-inline-item py-1">
                <a
                  href="/"
                  data-target="#largeModal"
                  data-toggle="modal"
                  className="btn btn-outline-secondary btn-sm"
                >
                  Large
                </a>
              </li>
            </ul>
            <div
              id="myModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              aria-hidden="true"
              className="modal fade mt-5"
            >
              <div role="document" className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 id="myModalLabel" className="modal-title">
                      Modal title
                    </h4>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>
                      Praesent commodo cursus magna, vel scelerisque nisl
                      consectetur et. Vivamus sagittis lacus vel augue laoreet
                      rutrum faucibus dolor auctor. Aenean lacinia bibendum
                      nulla sed consectetur. Praesent commodo cursus magna, vel
                      scelerisque nisl consectetur et. Donec sed odio dui. Donec
                      ullamcorper nulla non metus auctor fringilla. Cras mattis
                      consectetur purus sit amet fermentum. Cras justo odio,
                      dapibus ac facilisis in, egestas eget quam. Morbi leo
                      risus, porta ac consectetur ac, vestibulum at eros.
                      Praesent commodo cursus magna, vel scelerisque nisl
                      consectetur et. Vivamus sagittis lacus vel augue laoreet
                      rutrum faucibus dolor auctor.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-secondary"
                    >
                      Close
                    </button>
                    <button type="button" className="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
              id="smallModal"
              className="modal mt-5"
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content text-center">
                  <div className="modal-body text-center">
                    <div
                      role="alert"
                      className="alert text-white bg-primary fade show"
                    >
                      <span className="font-weight-bold">Womp!</span> There it
                      is.
                    </div>
                    <button data-dismiss="modal">
                      <span aria-hidden="true" className="display-2">
                        ×
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
              id="largeModal"
              className="modal fade mt-5"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content text-center p-3">
                  <div className="modal-body text-center">
                    <h1>Large Modal</h1>
                    <p className="lead">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid. Officia aute, non
                      cupidatat skateboard dolor brunch. Food truck quinoa
                      nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                      aliqua put a bird on it squid single-origin coffee nulla
                      assumenda shoreditch et. Ad vegan excepteur butcher vice
                      lomo. Leggings occaecat craft beer farm-to-table, raw
                      denim aesthetic synth nesciunt you probably haven&apos;t
                      heard of them accusamus labore sustainable VHS.
                    </p>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-lg btn-primary mt-2 text-uppercase"
                    >
                      <span aria-hidden="true">Close</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>{" "}
      <span id="tables" className="anchor"></span>
      <Container className="py-4 mt-2">
        <Row>
          <div className="col-md-12">
            <h2>Tables</h2>
            <table className="table table-hover table-sm">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Points</th>
                  <th>Region</th>
                  <th className="text-right">Mean</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Newport, RI, USA</td>
                  <td>3</td>
                  <td>New England</td>
                  <td className="text-right">45001</td>
                </tr>
                <tr>
                  <td>Chicago, IL, USA</td>
                  <td>5</td>
                  <td>Mid West</td>
                  <td className="text-right">106455</td>
                </tr>
                <tr>
                  <td>New York, NY, USA</td>
                  <td>10</td>
                  <td>Mid Atlantic</td>
                  <td className="text-right">39097</td>
                </tr>
              </tbody>
            </table>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered">
                <thead>
                  <tr>
                    <th className="id">
                      Id <i className="fa fa-sort"></i>
                    </th>
                    <th className="name">
                      Name <i className="fa fa-sort"></i>
                    </th>
                    <th className="description">Description</th>
                    <th className="field3">
                      Link <i className="fa fa-sort"></i>
                    </th>
                    <th className="field4">
                      Reason <i className="fa fa-sort"></i>
                    </th>
                    <th className="field5">
                      Area <i className="fa fa-sort"></i>
                    </th>
                    <th className="text-center">
                      <i className="ion-ios-trash-outline"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>alpha1</td>
                    <td>name 1</td>
                    <td>Description of item #1</td>
                    <td>
                      <a href="/" className="link">
                        alpha
                      </a>
                    </td>
                    <td>Some stuff about rec: 1</td>
                    <td>23</td>
                    <td className="text-center">
                      <a href="/">x</a>
                    </td>
                  </tr>
                  <tr>
                    <td>bob10</td>
                    <td>name 10</td>
                    <td>Description of item #10</td>
                    <td>
                      <a href="/" className="link">
                        bob
                      </a>
                    </td>
                    <td>Some stuff about rec: 10</td>
                    <td>22</td>
                    <td className="text-center">
                      <a href="/">x</a>
                    </td>
                  </tr>
                  <tr>
                    <td>daniel11</td>
                    <td>name 11</td>
                    <td>Description of item #11</td>
                    <td>
                      <a href="/" className="link">
                        daniel
                      </a>
                    </td>
                    <td>Some stuff about rec: 11</td>
                    <td>44</td>
                    <td className="text-center">
                      <a href="/">x</a>
                    </td>
                  </tr>
                  <tr>
                    <td>grace12</td>
                    <td>name 12</td>
                    <td>Description of item #12</td>
                    <td>
                      <a href="/" className="link">
                        grace
                      </a>
                    </td>
                    <td>Some stuff about rec: 12</td>
                    <td>19</td>
                    <td className="text-center">
                      <a href="/">x</a>
                    </td>
                  </tr>
                  <tr>
                    <td>alpha13</td>
                    <td>name 13</td>
                    <td>Description of item #13</td>
                    <td>
                      <a href="/" className="link">
                        alpha
                      </a>
                    </td>
                    <td>Some stuff about rec: 13</td>
                    <td>13</td>
                    <td className="text-center">
                      <a href="/">x</a>
                    </td>
                  </tr>
                  <tr>
                    <td>alpha14</td>
                    <td>name 14</td>
                    <td>Description of item #14</td>
                    <td>
                      <a href="/" className="link">
                        alpha
                      </a>
                    </td>
                    <td>Some stuff about rec: 14</td>
                    <td>14</td>
                    <td className="text-center">
                      <a href="/">x</a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="7">
                      <div className="text-center">
                        <nav aria-label="Page navigation">
                          <ul className="pagination justify-content-center">
                            <li className="page-item">
                              <a
                                href="/"
                                aria-label="Previous"
                                className="page-link"
                              >
                                <i className="fa fa-chevron-left ion-ios-arrow-left"></i>{" "}
                                <span className="sr-only">Previous</span>
                              </a>
                            </li>
                            <li className="page-item">
                              <a href="/" className="page-link">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a href="/" className="page-link">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <a href="/" className="page-link">
                                3
                              </a>
                            </li>
                            <li className="page-item">
                              <a href="/" className="page-link">
                                4
                              </a>
                            </li>
                            <li className="page-item">
                              <a href="/" className="page-link">
                                5
                              </a>
                            </li>
                            <li className="page-item">
                              <a
                                href="/"
                                aria-label="Next"
                                className="page-link"
                              >
                                <i className="fa fa-chevron-right ion-ios-arrow-right"></i>{" "}
                                <span className="sr-only">Next</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <Row>
              <div className="col-sm-6">
                <table className="table table-bordered table-hover">
                  <thead className="thead-inverse">
                    <tr>
                      <th>Location</th>
                      <th>Points</th>
                      <th className="text-right">Mean</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Long Island, NY, USA</td>
                      <td>7</td>
                      <td className="text-right">45,001</td>
                    </tr>
                    <tr>
                      <td>Chicago, Illinois, USA</td>
                      <td>8</td>
                      <td className="text-right">106,455</td>
                    </tr>
                    <tr>
                      <td>New York, New York, USA</td>
                      <td>4</td>
                      <td className="text-right">39,097</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-sm-6">
                <table className="table table-bordered table-hover">
                  <thead className="thead-inverse">
                    <tr>
                      <th>Location</th>
                      <th>Points</th>
                      <th className="text-right">Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Long Island, NY, USA</td>
                      <td>1</td>
                      <td className="text-right">5,001</td>
                    </tr>
                    <tr>
                      <td>Chicago, Illinois, USA</td>
                      <td>2</td>
                      <td className="text-right">6,455</td>
                    </tr>
                    <tr>
                      <td>New York, New York, USA</td>
                      <td>3</td>
                      <td className="text-right">9,097</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Row>
          </div>
        </Row>
      </Container>{" "}
      <span id="accordions" className="anchor"></span>
      <Container className="py-4 mt-2">
        <Row>
          <div className="col-md-12">
            <h2>Accordions &amp; Collapsible</h2>
            <Row className="mt-2">
              <div className="col-lg-6">
                <div className="card card-default">
                  <h4 className="card-header">
                    <a
                      href="/"
                      data-target="#collapseMe"
                      data-toggle="collapse"
                      className="float-right"
                    >
                      <i className="align-middle md_18 material-icons">
                        more_vert
                      </i>
                    </a>
                    Heading
                  </h4>
                  <div id="collapseMe" className="collapse show card-body">
                    <p>
                      Shiny, shiny. Varius azdipiscing elit. Duis pharetra
                      codeply varius quam sit amet vulputate. Ovi lipsim diro?
                      Then puska craft beer labore wes anderson cred nesciunt
                      sapiente ea proident!
                    </p>
                    <button className="btn btn-secondary">Take Action!</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div id="accordion2" className="accordion">
                  <div className="card">
                    <a
                      data-toggle="collapse"
                      href="/"
                      data-parent="#accordion2"
                      data-target="#collapseOne2"
                      className="card-header"
                    >
                      <span className="card-title">Panel 1</span>
                    </a>
                    <div id="collapseOne2" className="card-body collapse show">
                      <p>
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident. Ad vegan
                        excepteur butcher vice lomo. Leggings occaecat craft
                        beer farm-to-table, raw denim aesthetic synth nesciunt
                        you probably haven&apos;t heard of them accusamus labore
                        sustainable VHS.
                      </p>
                    </div>{" "}
                    <a
                      data-toggle="collapse"
                      href="/"
                      data-parent="#accordion2"
                      data-target="#collapseTwo2"
                      className="card-header collapsed"
                    >
                      <span className="card-title">Panel 2</span>
                    </a>
                    <div id="collapseTwo2" className="card-body collapse">
                      <p>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. Officia aute,
                        non cupidatat skateboard dolor brunch. Food truck quinoa
                        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee
                        nulla assumenda shoreditch et. Nihil anim keffiyeh
                        helvetica, craft beer labore wes anderson cred nesciunt
                        sapiente ea proident. Ad vegan excepteur butcher vice
                        lomo. Leggings occaecat craft beer farm-to-table, raw
                        denim aesthetic synth nesciunt you probably haven&apos;t
                        heard of them accusamus labore sustainable VHS.
                      </p>
                    </div>{" "}
                    <a
                      data-toggle="collapse"
                      href="/"
                      data-parent="#accordion2"
                      data-target="#collapseThree2"
                      className="card-header collapsed"
                    >
                      <span className="card-title">Panel 3</span>
                    </a>
                    <div id="collapseThree2" className="card-body collapse">
                      <p>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        samus labore sustainable VHS.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Row>
      </Container>{" "}
      <span id="buttons" className="anchor"></span>
      <Container className="py-4 mt-2 vh-100">
        <Row className="h-100 align-items-center">
          <div className="col-12">
            <h2>Buttons</h2>
            <Row className="mt-2">
              <div className="col-lg col-4">
                <button className="btn btn-outline-primary mr-1">
                  Primary
                </button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-outline-secondary mr-1">
                  Secondary
                </button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-outline-info mr-1">Info</button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-outline-danger mr-1">Danger</button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-outline-success mr-1">
                  Success
                </button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-outline-warning mr-1">
                  Warning
                </button>
              </div>
              <div className="w-100 my-3"></div>
              <div className="col-lg col-4">
                <button className="btn btn-primary mr-1">Primary</button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-secondary mr-1">Secondary</button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-info mr-1">Info</button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-danger mr-1">
                  Danger{" "}
                  <span className="badge badge-light rounded-pill">!</span>
                </button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-success mr-1">Success</button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-warning mr-1">Warning</button>
              </div>
              <div className="w-100 my-3"></div>
              <div className="col-xl col-4">
                <button className="btn btn-lg btn-primary mr-1">Primary</button>
              </div>
              <div className="col-xl col-4">
                <button className="btn btn-lg btn-secondary mr-1">
                  Secondary
                </button>
              </div>
              <div className="col-xl col-4">
                <button className="btn btn-lg btn-info mr-1">Info</button>
              </div>
              <div className="col-xl col-4">
                <button className="btn btn-lg btn-danger mr-1">Danger</button>
              </div>
              <div className="col-xl col-4">
                <button className="btn btn-lg btn-success mr-1">Success</button>
              </div>
              <div className="col-xl col-4">
                <button className="btn btn-lg btn-warning mr-1">Warning</button>
              </div>
              <div className="w-100 my-3"></div>
              <div className="col-lg col-4">
                <button className="btn btn-sm btn-primary mr-1">Primary</button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-sm btn-secondary mr-1">
                  Secondary
                </button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-sm btn-info mr-1">Info</button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-sm btn-danger mr-1">Danger</button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-sm btn-success mr-1">Success</button>
              </div>
              <div className="col-lg col-4">
                <button className="btn btn-sm btn-warning mr-1">Warning</button>
              </div>
              <div className="w-100 my-3"></div>
              <div className="col-lg col-3">
                <div className="btn-group">
                  <button type="button" className="btn btn-secondary">
                    Left
                  </button>
                  <button type="button" className="btn btn-secondary">
                    Middle
                  </button>
                  <button type="button" className="btn btn-secondary">
                    Right
                  </button>
                </div>
              </div>
              <div className="col-lg col-3">
                <div className="dropdown">
                  <button
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="btn btn-secondary dropdown-toggle"
                  >
                    Dropdown
                  </button>
                  <div
                    aria-labelledby="dropdownMenuButton"
                    className="dropdown-menu"
                  >
                    <a href="/" className="dropdown-item">
                      Action
                    </a>{" "}
                    <a href="/" className="dropdown-item">
                      Another action
                    </a>{" "}
                    <a href="/" className="dropdown-item">
                      Something else here
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg col-3">
                <div className="btn-group">
                  <button type="button" className="btn btn-secondary">
                    Split
                  </button>
                  <button
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                  >
                    <span className="sr-only">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu">
                    <a href="/" className="dropdown-item">
                      Action
                    </a>{" "}
                    <a href="/" className="dropdown-item">
                      Another action
                    </a>{" "}
                    <a href="/" className="dropdown-item">
                      Something else here
                    </a>
                    <div className="dropdown-divider"></div>{" "}
                    <a href="/" className="dropdown-item">
                      Separated link
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg col-3">
                <button type="button" className="btn btn-secondary btn-block">
                  Block
                </button>
              </div>
            </Row>
          </div>
        </Row>
      </Container>{" "}
      <span id="badges" className="anchor"></span>
      <Container className="py-4 mt-2">
        <Row>
          <div className="col-md-12">
            <h2>Badges</h2>
            <Row className="mt-2">
              <div className="col-lg-3 pb-2">
                <h6>Normal</h6>{" "}
                <a href="/">
                  <span className="badge bg-dark">badge</span>
                </a>{" "}
                <a href="/">
                  <span className="badge bg-success">success</span>
                </a>{" "}
                <a href="/">
                  <span className="badge bg-danger">danger</span>
                </a>
                <div className="w-100 my-3"></div>{" "}
                <a href="/">
                  <span className="badge bg-warning">warning</span>
                </a>{" "}
                <a href="/">
                  <span className="badge bg-info">info</span>
                </a>{" "}
                <a href="/">
                  <span className="badge bg-primary">primary</span>
                </a>
                <div className="w-100 my-3"></div>{" "}
                <a href="/">
                  <span className="badge rounded-pill bg-dark">pill</span>
                </a>{" "}
                <a href="/">
                  <span className="badge rounded-pill bg-success">100k</span>
                </a>{" "}
                <a href="/">
                  <span className="badge rounded-pill bg-info">2</span>
                </a>
                <a href="/">
                  <span className="badge rounded-pill bg-danger">378</span>
                </a>{" "}
                <a href="/">
                  <span className="badge rounded-pill bg-warning">tag</span>
                </a>{" "}
                <a href="/">
                  <span className="badge rounded-pill bg-primary">1123</span>
                </a>
              </div>
              <div className="col-lg-4 pb-2">
                <h5>Heading 5</h5>
                <h5 className="mt-2">
                  <a href="/">
                    <span className="badge bg-dark">badge</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge bg-success">success</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge bg-danger">danger</span>
                  </a>
                  <div className="w-100 my-3"></div>{" "}
                  <a href="/">
                    <span className="badge bg-warning">warning</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge bg-info">info</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge bg-primary">primary</span>
                  </a>
                  <div className="w-100 my-3"></div>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-dark">pill</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-success">100k</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-info">2</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-danger">378</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-warning">tag</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-primary">1123</span>
                  </a>
                </h5>
              </div>
              <div className="col-lg-5 pb-2">
                <h3>Heading 3</h3>
                <h3>
                  <a href="/">
                    <span className="badge bg-dark">badge</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge bg-success">success</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge bg-danger">danger</span>
                  </a>
                  <div className="w-100 my-3"></div>{" "}
                  <a href="/">
                    <span className="badge bg-warning">warning</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge bg-info">info</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge bg-primary">primary</span>
                  </a>
                  <div className="w-100 my-3"></div>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-dark">pill</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-success">100k</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-info">2</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-danger">378</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-warning">tag</span>
                  </a>{" "}
                  <a href="/">
                    <span className="badge rounded-pill bg-primary">1123</span>
                  </a>
                </h3>
              </div>
            </Row>
          </div>
        </Row>
      </Container>
    </Page>
  );
};

Styleguide.contextTypes = {
  t: PropTypes.func,
};

export default Styleguide;
