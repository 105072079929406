import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { getArtistAttributes } from "../../../redux/artists";
import TalenzzLogo from "../../icons/TalenzzLogo";

const ArtistPreview = ({ artistId, toEditRoute }, context) => {
  const artistData = useSelector((state) =>
    getArtistAttributes(state, artistId)
  );

  const currentLang = useSelector((state) => state.i18nState.lang);

  return (
    <Card className="h-100 rounded-5 overflow-hidden shadow">
      <div className="ratio ratio-1x1 overflow-hidden card-img-top">
        {artistData?.logo_original?.url ? (
          <img
            src={artistData?.logo_original?.url}
            className="img-fluid card-img-top h-100 object-fit-cover"
            alt={artistData?.name}
          />
        ) : (
          <div className="img-fluid card-img-top h-100 d-flex align-items-center justify-content-center ">
            <TalenzzLogo width={"50%"} />
          </div>
        )}
      </div>
      <CardBody className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <p className="h5">{artistData?.name}</p>
        </div>
        <p className="text-muted">{artistData?.genre}</p>
        <div className="d-flex mt-auto">
          <Link
            to={`/${currentLang}/artists/${artistId}${
              toEditRoute ? "/edit" : ""
            }`}
            className="btn btn-link ms-auto p-0 stretched-link"
          >
            {toEditRoute ? context.t("Edit") : context.t("View Details")}
          </Link>
        </div>
      </CardBody>
    </Card>
  );
};

ArtistPreview.contextTypes = {
  t: PropTypes.func,
};

ArtistPreview.propTypes = {
  artistId: PropTypes.string.isRequired,
  toEditRoute: PropTypes.bool,
};

ArtistPreview.defaultProps = {
  toEditRoute: false,
};

export default ArtistPreview;
