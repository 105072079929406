/*
Replace
investment with your resource Name :) Take care about different writings!
/!\ Investment investment Investments investments /!\
Keep an eye on pluralized namings in here :)

*/

import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  defaultAddToDictionary,
  defaultCreate,
  defaultCreateFail,
  defaultCreateSuccess,
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
} from "../defaultReducers";

import { defaultInitialState } from "../defaultInitialState";

export const investmentsSlice = createSlice({
  name: "investments",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: (state, action) => {
      defaultCreateSuccess(state, action);
      state.showId = action.payload.id;
    },
    createFail: defaultCreateFail,
    addToDictionary: defaultAddToDictionary,
    listErrorReset: (state) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
  },
});

export const {
  show: showInvestment,
  list: listInvestments,
  create: createInvestment,
} = investmentsSlice.actions;
export const actions = investmentsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const investments = (state) =>
  state.investments.idsList.map((id) => state.investments.dictionary[id]);
export const investment = (state) =>
  state.investments.showId && state.investments.dictionary[state.investments.showId];
export const investmentAttributes = (state) => investment(state)?.attributes;
export const investmentRelationships = (state) => investment(state)?.relationships;
export const investmentId = (state) => state.investments.showId;

export const getInvestment = createSelector(
  (state) => state.investments.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getInvestmentAttributes = createSelector(
  (state) => state.investments.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getInvestmentRelationships = createSelector(
  (state) => state.investments.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getInvestments = createSelector(
  (state) => state.investments.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default investmentsSlice.reducer;
