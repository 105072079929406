import PropTypes from "prop-types";
import React from "react";
import {
  FaDiscord,
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaXTwitter,
} from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import TalenzzLogo from "../icons/TalenzzLogo";

const Footer = (props, context) => {
  const currentLang = useSelector((state) => state.i18nState.lang);
  return (
    <>
      <Container
        style={{ marginBottom: "-46px", zIndex: 99, position: "relative" }}
      >
        <div className="bg-secondary py-3 py-md-0 rounded-5 shadow">
          <Row className="justify-content-around">
            <Col
              xs="12"
              lg="2"
              className="py-2 d-flex flex-column justify-content-center"
            >
              <div className="text-center fw-bold fs-5 text-uppercase">
                {context.t("Our Partners")}
              </div>
            </Col>
            <Col
              xs="6"
              lg="2"
              className="py-3 px-4 d-flex flex-column justify-content-center"
            >
              <a href="https://www.bmm-ag.de/" target="_blank" rel="noreferrer">
                <img
                  src="/assets/icons/brainstorm_logo.svg"
                  alt="Brainstorm Logo"
                  className="img-fluid w-100"
                />
              </a>
            </Col>
            <Col
              xs="6"
              lg="2"
              className="py-3 px-4 d-flex flex-column justify-content-center"
            >
              <a
                href="https://emergenza.live/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/icons/emergenza_logo.svg"
                  alt="Emergenza Logo"
                  className="img-fluid w-100"
                />
              </a>
            </Col>
            <Col
              xs="6"
              lg="2"
              className="py-3 px-4 d-flex flex-column justify-content-center"
            >
              <a href="https://inncubator.at/" target="_blank" rel="noreferrer">
                <img
                  src="/assets/icons/inncubator_logo.svg"
                  alt="INNCUBATOR Logo"
                  className="img-fluid w-100"
                />
              </a>
            </Col>
            <Col
              xs="6"
              lg="2"
              className="py-3 px-4 d-flex flex-column justify-content-center"
            >
              <a
                href="https://www.microsoft.com/en-us/startups"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/MS_Startups_Celebration.png"
                  alt="Microsoft Partner Logo"
                  className="img-fluid w-100"
                  width="786"
                  height="336"
                />
              </a>
            </Col>
            <Col
              xs="12"
              lg="2"
              className="py-2 d-flex flex-column justify-content-center"
            >
              <div className="text-center">
                <a
                  href="mailto:partner@talenzz.io"
                  className="btn btn-light fw-bold px-4"
                >
                  {context.t("Become a partner")}
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <footer className="bg-dark text-white p-4">
        <Container style={{ marginTop: "46px" }}>
          <div className="d-flex flex-column flex-md-row gap-5 justify-content-between my-5">
            <div>
              <TalenzzLogo height="56px" />
            </div>
            <div className="d-flex align-items-center gap-1 justify-content-center">
              <div>
                <a
                  href="https://www.instagram.com/talenzzmusic/"
                  target="_blank"
                  rel="noreferrer"
                  className="p-2 bg-white text-dark rounded-circle"
                >
                  <FaInstagram />
                </a>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/talenzzmusic"
                  target="_blank"
                  rel="noreferrer"
                  className="p-2 bg-white text-dark rounded-circle"
                >
                  <FaFacebookF />
                </a>
              </div>
              <div>
                <a
                  href="https://twitter.com/talenzzmusic"
                  target="_blank"
                  rel="noreferrer"
                  className="p-2 bg-white text-dark rounded-circle"
                >
                  <FaXTwitter />
                </a>
              </div>
              <div>
                <a className="p-2 bg-white text-dark rounded-circle">
                  <FaDiscord />
                </a>
              </div>
              <div>
                <a
                  href="https://tiktok.com/@talenzzmusic"
                  target="_blank"
                  rel="noreferrer"
                  className="p-2 bg-white text-dark rounded-circle"
                >
                  <FaTiktok />
                </a>
              </div>
            </div>
          </div>
          <Row className="fs-5">
            <Col md="3" className="my-3">
              <div className="mb-2 fw-bold">Talenzz GmbH</div>
              <div>{context.t("The first Community Music Label")}</div>
            </Col>
            <Col md="3" className="my-3">
              <div className="mb-2">Weidachstraße 13</div>
              <div>87541 Bad Hindelang</div>
            </Col>
            <Col md="3" className="my-3">
              <div className="mb-2">{context.t("Contact")}:</div>
              <div>
                <a
                  href="mailto:info@talenzz.io"
                  target="_blank"
                  rel="noreferrer"
                  className="text-reset text-decoration-none"
                >
                  info@talenzz.io
                </a>
              </div>
            </Col>
            <Col md="3" className="my-3">
              <div className="mb-2">
                <Link
                  to={`/${currentLang}/p/${context.t("slug.imprint")}`}
                  className="text-reset text-decoration-none"
                >
                  {context.t("Imprint")}
                </Link>
              </div>
              <div className="mb-2">
                <Link
                  to={`/${currentLang}/p/${context.t("slug.tos")}`}
                  className="text-reset text-decoration-none"
                >
                  {context.t("Terms of Service")}
                </Link>
              </div>
              <div>
                <Link
                  to={`/${currentLang}/p/${context.t("slug.dataprotection")}`}
                  className="text-reset text-decoration-none"
                >
                  {context.t("Dataprotection")}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

Footer.contextTypes = {
  t: PropTypes.func,
};

export default Footer;
