import PropTypes from "prop-types";
import React, { useState } from "react";
import { MdLanguage } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const LanguageSwitch = (_props, context) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const currentLang = useSelector((state) => state.i18nState.lang);

  const availableLanguages = [
    {
      label: context.t("English"),
      value: "en",
    },
    {
      label: context.t("German"),
      value: "de",
    },
  ];

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const handleLanguageClick = (newLang) => {
    const splitLocation = location.pathname.split("/");
    splitLocation[1] = newLang;
    navigate(splitLocation.join("/"), { replace: true });
    setDropdownOpen(false);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret color="transparent">
        <MdLanguage className="me-2" />
        {currentLang}
      </DropdownToggle>
      <DropdownMenu>
        {availableLanguages?.map((lang) => (
          <DropdownItem
            disabled={lang.value === currentLang}
            key={lang.value}
            onClick={() => {
              handleLanguageClick(lang.value);
            }}
          >
            {lang.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

/**  define proptype for the 'translation' function  */
LanguageSwitch.contextTypes = {
  t: PropTypes.func,
};

LanguageSwitch.propTypes = {};

export default LanguageSwitch;
