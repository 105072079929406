import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from "reactstrap";
import { CampaignPreview } from "../../Campaign";
import { listCampaigns } from "../../../redux/campaigns";
import { Link } from "react-router-dom";

const DashboardCampaigns = (props, context) => {
  const page = useSelector((state) => state.campaigns.listPage);
  const campaignIds = useSelector((state) => state.campaigns.idsList);
  const campaignTotalPages = useSelector((state) => state.campaigns.totalPages);
  const hasMore = page < campaignTotalPages;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      listCampaigns({
        endlessScroll: true,
        investedCampaigns: true,
      })
    );
  }, []);

  const handleClick = () => {
    dispatch(
      listCampaigns({
        endlessScroll: true,
        investedCampaigns: true,
        page: page + 1,
      })
    );
  };

  const currentLang = useSelector((state) => state.i18nState.lang);

  return (
    <Container className="mt-3">
      <h2>{context.t("Your Supported Campaigns")}</h2>

      {campaignIds.length === 0 && (
        <div className="text-center my-5">
          <p className="display-6">{context.t("No campaigns found")}</p>
          <Link
            to={`/${currentLang}/campaigns`}
            className="btn btn-primary mt-3"
          >
            {context.t("Browse Campaigns")}
          </Link>
        </div>
      )}

      {campaignIds.map((campaignId) => (
        <div key={campaignId}>
          <CampaignPreview campaignId={campaignId} />
        </div>
      ))}

      {hasMore && (
        <div className="d-flex justify-content-center">
          <Button
            hidden={page >= campaignTotalPages}
            type="button"
            onClick={handleClick}
            color="tertiary"
            className="btn-lg"
          >
            {context.t("Load More")}
          </Button>
        </div>
      )}
    </Container>
  );
};

/**  define proptype for the 'translation' function  */
DashboardCampaigns.contextTypes = {
  t: PropTypes.func,
};

DashboardCampaigns.propTypes = {};

export default DashboardCampaigns;
