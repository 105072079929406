/*
Replace
staticPage with your resource Name :) Take care about different writings!
/!\ StaticPage staticPage StaticPages staticPages /!\
Keep an eye on pluralized namings in here :)

*/

import { createSelector, createSlice } from "@reduxjs/toolkit";
import { defaultShowFail } from "../defaultReducers";

import { defaultInitialState } from "../defaultInitialState";

export const staticPagesSlice = createSlice({
  name: "staticPages",
  initialState: { ...defaultInitialState },
  reducers: {
    show: (state, action) => {
      state.loadingShow = true;
      state.loadedShow = false;
      state.showError = null;
      state.showSlug = action.payload.slug || null;
    },
    showSuccess: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = true;
      state.showSlug = action.payload.attributes?.slug;
      state.dictionary[action.payload.attributes?.slug] = action.payload;
    },
    showFail: defaultShowFail,
    addToDictionary: (state, action) => {
      state.dictionary[action.payload.attributes?.slug] = {
        ...state.dictionary[action.payload.attributes?.slug],
        ...action.payload,
      };
    },
    showErrorReset: (state) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
  },
});

export const { show: showStaticPage } = staticPagesSlice.actions;
export const actions = staticPagesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const staticPages = (state) =>
  state.staticPages.idsList.map((slug) => state.staticPages.dictionary[slug]);
export const staticPage = (state) =>
  state.staticPages.showSlug &&
  state.staticPages.dictionary[state.staticPages.showSlug];
export const staticPageAttributes = (state) => staticPage(state)?.attributes;
export const staticPageRelationships = (state) =>
  staticPage(state)?.relationships;
export const staticPageSlug = (state) => state.staticPages.showSlug;

export const getStaticPage = createSelector(
  (state) => state.staticPages.dictionary,
  (_, slug) => slug,
  (dictionary, slug) => {
    return slug && dictionary && dictionary[slug];
  }
);

export const getStaticPageAttributes = createSelector(
  (state) => state.staticPages.dictionary,
  (_, slug) => slug,
  (dictionary, slug) => {
    return slug && dictionary && dictionary[slug]?.attributes;
  }
);

export const getStaticPageRelationships = createSelector(
  (state) => state.staticPages.dictionary,
  (_, slug) => slug,
  (dictionary, slug) => {
    return slug && dictionary && dictionary[slug]?.relationships;
  }
);

export const getStaticPages = createSelector(
  (state) => state.staticPages.dictionary,
  (_, slugs) => slugs,
  (dictionary, slugs) => {
    return slugs && dictionary && slugs.map((slug) => dictionary[slug]);
  }
);

export default staticPagesSlice.reducer;
