import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarToggler,
  UncontrolledDropdown,
} from "reactstrap";
import useAccount from "../../hooks/useAccount";
import { logoutAccount, validateToken } from "../../redux/accounts";
import { getTokenCookies } from "../../utils/tokenCookies";
import { LanguageSwitch } from "../LanguageSwitch";
import TalenzzLogo from "../icons/TalenzzLogo";

const Navigation = (_props, context) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser, needsValidate } = useAccount();
  const cookies = getTokenCookies();
  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    if (needsValidate || (!currentUser && cookies["accessToken"])) {
      registerEffect(dispatch, validateToken());
    }
  }, []);

  const handleLogout = () => {
    dispatch(logoutAccount());
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const currentLang = useSelector((state) => state.i18nState.lang);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Navbar expand="md" fixed="top" color="white" className="shadow-sm">
      <NavbarToggler onClick={toggle} />
      <NavLink
        to={`/${currentLang}`}
        title={context.t("Home")}
        className="navbar-brand me-auto ms-3 ms-md-0"
      >
        <TalenzzLogo height="40px" width="auto" />
      </NavLink>
      <div
        className={`navbar-collapse offcanvas-md offcanvas-start ${
          isOpen ? "show" : ""
        }`}
      >
        <div className="offcanvas-header w-100">
          <TalenzzLogo height="29px" />
          <button type="button" className="btn-close" onClick={toggle} />
        </div>
        <div className="offcanvas-body w-100">
          <Nav navbar className="w-100 justify-content-end">
            <NavItem>
              <NavLink to={`/${currentLang}/campaigns`} className="nav-link">
                {context.t("Campaigns")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={`/${currentLang}/artists`} className="nav-link">
                {context.t("Artists")}
              </NavLink>
            </NavItem>
            {currentUser && (
              <NavItem>
                <NavLink to={`/${currentLang}/dashboard`} className="nav-link">
                  {context.t("Dashboard")}
                </NavLink>
              </NavItem>
            )}

            <NavItem>
              <LanguageSwitch />
            </NavItem>
          </Nav>
        </div>
      </div>
      {isOpen && (
        <div className="offcanvas-backdrop fade show" onClick={toggle}></div>
      )}
      <Nav className="position-relative" navbar>
        {currentUser ? (
          <UncontrolledDropdown nav>
            <DropdownToggle nav caret className="text-end">
              {currentUser.attributes.name}
            </DropdownToggle>
            <DropdownMenu style={{ left: "unset", right: "0" }}>
              <DropdownItem>
                <NavLink
                  to={`/${currentLang}/dashboard/profile`}
                  className="nav-link px-0"
                >
                  {context.t("Profile")}
                </NavLink>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}>
                <div className="nav-link px-0">{context.t("Logout")}</div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <NavLink
            to={`/${currentLang}/login`}
            className="btn btn-primary px-md-5 ms-4"
          >
            {context.t("Get Started")}
          </NavLink>
        )}
      </Nav>
    </Navbar>
  );
};

Navigation.contextTypes = {
  t: PropTypes.func,
};

export default Navigation;
