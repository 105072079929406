import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { ArtistForm } from "../components/Artist";
import Page from "../components/layout/Page";
import useAccount from "../hooks/useAccount";
import { showArtist, updateArtist } from "../redux/artists";

const ArtistUpdate = (_props, context) => {
  const artistId = useSelector((state) => state.artists.showId);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentUser } = useAccount();
  const artistUpdated = useSelector((state) => state.artists.updated);

  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    registerEffect(dispatch, showArtist({ id }));
  }, []);

  const onSubmit = (data) => {
    dispatch(updateArtist({ id: artistId, status: "draft", ...data }));
  };

  const currentLang = useSelector((state) => state.i18nState.lang);

  return (
    <Page title={context.t("Update Artist")}>
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <h1>{context.t("Update Artist")}</h1>
            {!currentUser ? (
              <p>{context.t("You need to sign in first")}</p>
            ) : artistUpdated ? (
              <>
                <p className="text-success small mt-1">
                  {context.t(
                    "The artist was updated successfully. The Talenzz Team will review it."
                  )}
                </p>
                <Link to={`/${currentLang}/dashboard/profile`}>
                  {context.t("Go to Profile")}
                </Link>
              </>
            ) : (
              <ArtistForm artistId={artistId} onSubmit={onSubmit} isEditRoute />
            )}
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

ArtistUpdate.contextTypes = {
  t: PropTypes.func,
};

export default ArtistUpdate;
