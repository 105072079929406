/*
Replace
event with your resource Name :) Take care about different writings!
/!\ Event event Events events /!\
Keep an eye on pluralized namings in here :)

*/

import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  defaultAddToDictionary,
  defaultCreate,
  defaultCreateFail,
  defaultCreateSuccess,
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateFail,
  defaultUpdateSuccess,
} from "../defaultReducers";

import { defaultInitialState } from "../defaultInitialState";

export const eventsSlice = createSlice({
  name: "events",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
  },
});

export const {
  show: showEvent,
  update: updateEvent,
  list: listEvents,
  create: createEvent,
} = eventsSlice.actions;
export const actions = eventsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const events = (state) =>
  state.events.idsList.map((id) => state.events.dictionary[id]);
export const event = (state) =>
  state.events.showId && state.events.dictionary[state.events.showId];
export const eventAttributes = (state) => event(state)?.attributes;
export const eventRelationships = (state) => event(state)?.relationships;
export const eventId = (state) => state.events.showId;

export const getEvent = createSelector(
  (state) => state.events.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getEventAttributes = createSelector(
  (state) => state.events.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getEventRelationships = createSelector(
  (state) => state.events.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getEvents = createSelector(
  (state) => state.events.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default eventsSlice.reducer;
