import { useSelector } from "react-redux";

const useAccount = () => {
  const currentUser = useSelector((state) => state.accounts.currentUser);
  const registered = useSelector((state) => state.accounts.registered);
  const isPasswordReset = useSelector((state) => state.accounts.resetPassword);
  const loginErrors = useSelector((state) => state.accounts.loginErrors);
  const registerErrors = useSelector((state) => state.accounts.registerErrors);
  const resetPasswordErrors = useSelector(
    (state) => state.accounts.resetPasswordErrors
  );
  const updatePasswordErrors = useSelector(
    (state) => state.accounts.updatePasswordErrors
  );
  const tokens = useSelector((state) => state.tokens);

  return {
    currentUser,
    loginErrors,
    registered,
    isPasswordReset,
    registerErrors,
    resetPasswordErrors,
    updatePasswordErrors,
    needsValidate:
      !currentUser && tokens["access-token"] && tokens.client && tokens.uid,
  };
};

export default useAccount;
