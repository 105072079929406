import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { listInvestments } from "../../../redux/investments";
import { InvestmentListItem } from "../../Investment";

const DashboardInvestments = (props, context) => {
  const page = useSelector((state) => state.investments.listPage);
  const investmentIds = useSelector((state) => state.investments.idsList);
  const investmentTotalPages = useSelector(
    (state) => state.investments.totalPages
  );
  const hasMore = page < investmentTotalPages;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      listInvestments({
        endlessScroll: true,
      })
    );
  }, []);

  const handleClick = () => {
    dispatch(
      listInvestments({
        endlessScroll: true,
        page: page + 1,
      })
    );
  };

  const currentLang = useSelector((state) => state.i18nState.lang);

  return (
    <Container className="mt-3">
      <h2>{context.t("Your Supports")}</h2>

      {investmentIds.length === 0 && (
        <div className="text-center my-5">
          <p className="display-6">{context.t("No supports found")}</p>
          <Link
            to={`/${currentLang}/campaigns`}
            className="btn btn-primary mt-3"
          >
            {context.t("Browse Campaigns")}
          </Link>
        </div>
      )}

      {investmentIds.map && (
        <>
          {investmentIds.map((investmentId) => (
            <InvestmentListItem
              investmentId={investmentId}
              key={investmentId}
            />
          ))}
        </>
      )}

      {hasMore && (
        <div className="d-flex justify-content-center">
          <Button
            type="button"
            onClick={handleClick}
            color="tertiary"
            className="btn-lg"
          >
            {context.t("Load More")}
          </Button>
        </div>
      )}
    </Container>
  );
};

/**  define proptype for the 'translation' function  */
DashboardInvestments.contextTypes = {
  t: PropTypes.func,
};

DashboardInvestments.propTypes = {};

export default DashboardInvestments;
