import { call, getContext, put, takeEvery } from "redux-saga/effects";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";

/*
Replace
content with your resource Name :) Take care about different writings!
/!\ Content content Contents contents /!\
Keep an eye on pluralized namings in here :)

*/

/** List Saga
 *  @description: connects to the getContent operation
 */
export function* list(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));
  const { page, endlessScroll } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Contents.listContents,
      { page },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(
      actions.listSuccess({ ...payload, endlessScroll: !!endlessScroll })
    );
  } catch (e) {
    yield put(actions.listFail({ message: e.message, ...e }));
  }
}

/** Show Saga
 *  @description: connects to the showContent operation
 *  @param {number} action.payload the Content id
 */
export function* show(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));
  const { id } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Contents.showContent,
      {
        id,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.showSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.showFail({ message: e.message, ...e }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* contentsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
}
export default contentsSaga;
