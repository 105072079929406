import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Nav, NavItem, Row } from "reactstrap";
import { isServer } from "../../../../redux/store";

const ArtistShowNavigation = (props, context) => {
  const { hasCampaigns, hasDiscography, hasEvents } = props;

  const handleClickScroll = (id) => {
    if (!isServer) {
      const element = document.getElementById(id);
      if (element && window) {
        window.scrollTo({
          top: element.offsetTop - 150,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div className="artistshow__navigation py-3 shadow-sm">
      <Container>
        <Row>
          <Col xl={{ size: 8, order: 1 }}>
            <Nav className="nav-underline">
              <NavItem>
                <div
                  className="nav-link px-1 px-lg-5 fs-sm"
                  role="button"
                  onClick={() => handleClickScroll("artist-about")}
                >
                  {context.t("About")}
                </div>
              </NavItem>
              {hasCampaigns && (
                <NavItem>
                  <div
                    className="nav-link px-1 px-lg-5"
                    role="button"
                    onClick={() => handleClickScroll("artist-campaigns")}
                  >
                    {context.t("Campaigns")}
                  </div>
                </NavItem>
              )}
              {hasDiscography && (
                <NavItem>
                  <div
                    className="nav-link px-1 px-lg-5"
                    role="button"
                    onClick={() => handleClickScroll("artist-discography")}
                  >
                    {context.t("Discography")}
                  </div>
                </NavItem>
              )}
              {hasEvents && (
                <NavItem>
                  <div
                    className="nav-link px-1 px-lg-5"
                    role="button"
                    onClick={() => handleClickScroll("artist-events")}
                  >
                    {context.t("On Tour")}
                  </div>
                </NavItem>
              )}
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ArtistShowNavigation.propTypes = {
  hasCampaigns: PropTypes.bool,
  hasDiscography: PropTypes.bool,
  hasEvents: PropTypes.bool,
};

ArtistShowNavigation.defaultProps = {
  hasCampaigns: false,
  hasDiscography: false,
  hasEvents: false,
};

ArtistShowNavigation.contextTypes = {
  t: PropTypes.func,
};

export default ArtistShowNavigation;
