import PropTypes from "prop-types";
import React from "react";
import { Container } from "reactstrap";
import useAccount from "../../../hooks/useAccount";

const DashboardHeader = (props, context) => {
  const { currentUser } = useAccount();

  const userData = currentUser?.attributes;
  return (
    <Container className="py-5">
      <p className="display-4 mb-4">
        {context.t("Hello")}&nbsp;{userData?.name}
      </p>
    </Container>
  );
};

/**  define proptype for the 'translation' function  */
DashboardHeader.contextTypes = {
  t: PropTypes.func,
};

DashboardHeader.propTypes = {};

export default DashboardHeader;
