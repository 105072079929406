/*
Replace
faq with your resource Name :) Take care about different writings!
/!\ Faq faq Faqs faqs /!\
Keep an eye on pluralized namings in here :)

*/

import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  defaultAddToDictionary,
  defaultList,
  defaultListFail,
  defaultListSuccess,
} from "../defaultReducers";

import { defaultInitialState } from "../defaultInitialState";

export const faqsSlice = createSlice({
  name: "faqs",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    addToDictionary: defaultAddToDictionary,
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
  },
});

export const {
  list: listFaqs,
} = faqsSlice.actions;
export const actions = faqsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const faqs = (state) =>
  state.faqs.idsList.map((id) => state.faqs.dictionary[id]);
export const faq = (state) =>
  state.faqs.showId && state.faqs.dictionary[state.faqs.showId];
export const faqAttributes = (state) => faq(state)?.attributes;
export const faqRelationships = (state) => faq(state)?.relationships;
export const faqId = (state) => state.faqs.showId;

export const getFaq = createSelector(
  (state) => state.faqs.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getFaqAttributes = createSelector(
  (state) => state.faqs.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getFaqRelationships = createSelector(
  (state) => state.faqs.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getFaqs = createSelector(
  (state) => state.faqs.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default faqsSlice.reducer;
