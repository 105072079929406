import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, UncontrolledAccordion } from "reactstrap";
import { listFaqs } from "../../../redux/faqs";
import FaqEntry from "./utils/FaqEntry";

const FaqList = (props, context) => {
  const dispatch = useDispatch();
  const registerEffect = useRegisterEffect();

  useSsrEffect(() => {
    registerEffect(dispatch, listFaqs());
  }, []);

  const faqIds = useSelector((state) => state.faqs.idsList);

  return (
    <>
      {!!faqIds?.length && (
        <Container className="py-5" id="faq-section">
          <h2 className="text-center mb-4">{context.t("FAQs")}</h2>
          <UncontrolledAccordion defaultOpen={[]} className="shadow-sm rounded">
            {faqIds.map((faqId) => (
              <FaqEntry faqId={faqId} key={faqId} />
            ))}
          </UncontrolledAccordion>
        </Container>
      )}
    </>
  );
};

FaqList.contextTypes = {
  t: PropTypes.func,
};

export default FaqList;
