import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const NewsletterSignup = (props, context) => {
  const [showNewsletterModal, setShowNewsletterModal] = useState(false);
  const toggleNewsletterModal = () =>
    setShowNewsletterModal(!showNewsletterModal);

  return (
    <>
      <div className="bg-white py-5">
        <Container>
          <h2 className="text-center mb-4">{context.t("Newsletter")}</h2>
          <div className="bg-tertiary rounded rounded-5 p-4 p-md-5 shadow">
            <Row>
              <Col md={8}>
                <p className="mb-0 fw-bold fs-3">
                  {context.t(
                    "Signup for the Talenzz newsletter and help us shape the future of the music industry"
                  )}
                </p>
              </Col>
              <Col
                md={4}
                className="d-flex flex-column justify-content-center align-items-center mt-4 mt-md-0"
              >
                <Button color="dark" size="lg" onClick={toggleNewsletterModal}>
                  {context.t("Sign up to our Newsletter")}
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Modal
        toggle={toggleNewsletterModal}
        isOpen={showNewsletterModal}
        centered
        scrollable
        fullscreen="sm"
      >
        <ModalHeader toggle={toggleNewsletterModal}>
          {context.t("Newsletter Signup")}
        </ModalHeader>
        <ModalBody>
          <iframe
            width="100%"
            height="720px"
            src="https://a36683bd.sibforms.com/serve/MUIFABmZMcJm23HFv3JITydIyu9eI_SCeuR9o7t7Zai4VbS583OzKW0jmqnWovh_cRPqwSzcPoX4OkDA_UttV7DiSRnL9-GWBwToBJ6c9L_SF0B-Xe5-28JOQG1rSTIKoZg7xYbTwFI-3REt8GyesMfHdfKElH_LAfGfw4RFsKICM1M-DVYigZrpJSj-d42xWhyT4a1hNl13GMFE"
          />
        </ModalBody>
      </Modal>
    </>
  );
};

NewsletterSignup.contextTypes = {
  t: PropTypes.func,
};

export default NewsletterSignup;
