import { call, getContext, put, takeEvery } from "redux-saga/effects";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";

/*
Replace
investment with your resource Name :) Take care about different writings!
/!\ Investment investment Investments investments /!\
Keep an eye on pluralized namings in here :)

*/

/** List Saga
 *  @description: connects to the getInvestment operation
 */
export function* list() {
  const headers = yield buildHeaders(yield getContext("tokens"));
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Investments.listInvestments,
      {},
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.listSuccess(payload));
  } catch (e) {
    yield put(actions.listFail({ message: e.message, ...e }));
  }
}

export function* create(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));

  let { successCallbackFnc, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Investments.createInvestment,
      {},
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.createSuccess(payload.obj.data));

    if (successCallbackFnc) successCallbackFnc(payload.obj.data);
  } catch (e) {
    yield put(actions.createFail({ message: e.message, ...e }));
  }
}
/** Show Saga
 *  @description: connects to the showInvestment operation
 *  @param {number} action.payload the Investment id
 */
export function* show(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));

  const { id } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Investments.showInvestment,
      {
        id,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.showSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.showFail({ message: e.message, ...e }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* investmentsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
}
export default investmentsSaga;
