import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { ArtistForm } from "../components/Artist";
import Page from "../components/layout/Page";
import useAccount from "../hooks/useAccount";
import { createArtist } from "../redux/artists";

const ArtistCreate = (_props, context) => {
  const dispatch = useDispatch();
  const { currentUser } = useAccount();
  const artistCreated = useSelector((state) => state.artists.created);

  const onSubmit = (data) => {
    dispatch(createArtist(data));
  };

  const currentLang = useSelector((state) => state.i18nState.lang);

  return (
    <Page title={context.t("Create Artist")}>
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <h1>{context.t("Create Artist")}</h1>
            {!currentUser ? (
              <p>{context.t("You need to sign in first")}</p>
            ) : artistCreated ? (
              <>
                <p className="text-success small mt-1">
                  {context.t(
                    "The artist was created successfully. The Talenzz Team will review it."
                  )}
                </p>
                <Link to={`/${currentLang}/dashboard/profile`}>
                  {context.t("Go to Profile")}
                </Link>
              </>
            ) : (
              <ArtistForm onSubmit={onSubmit} />
            )}
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

ArtistCreate.contextTypes = {
  t: PropTypes.func,
};

export default ArtistCreate;
