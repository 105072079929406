export const translations = {
  'de': {
    'Name': 'Dein Name',
    'Email': 'E-Mail',
    'Submit': 'Senden',
    'On Tour': 'Auf Tour',
    'Load More': 'Mehr anzeigen',
    'is required': 'ist erforderlich',
    'Description': 'Beschreibung',
    'Genre': 'Genre',
    'Location': 'Standort',
    'Founding Year': 'Grundungsjahr',
    'Amount of Members': 'Anzahl der Mitglieder',
    'Website URL': 'Website-URL',
    'Spotify URL': 'Spotify-URL',
    'Facebook URL': 'Facebook-URL',
    'Instagram URL': 'Instagram-URL',
    'TikTok URL': 'TikTok-URL',
    'YouTube URL': 'YouTube-URL',
    'Bandcamp URL': 'Bandcamp-URL',
    'Application Message': 'Anmeldungstext',
    'Edit': 'Bearbeiten',
    'View Details': 'Details anzeigen',
    'About': 'Über',
    'Founded': 'Gegründet',
    'Members': 'Mitglieder',
    'Campaigns': 'Kampagnen',
    'Discography': 'Diskographie',
    'Support Policy & Documents': 'Unterstützungsrichtlinien & Dokumente',
    'Download': 'Download',
    'by': 'von',
    'Price per Share': 'Preis pro Anteil',
    'Share size': 'Anteilsgröße',
    'Shares owned': 'Deine Anteile',
    'Shares Available': 'Verfügbare Anteile',
    'Campaign goal successfully achieved!': 'Kampagnen Ziel erreicht!',
    'Support': 'Unterstützung',
    'Login/Register to Support': 'Zum Unterstützen Einloggen/Registrieren',
    'How many shares would you like to buy?': 'Wie viele Anteile möchtest Du kaufen?',
    'I have read and accepted the {link}.': 'Ich habe die {link} gelesen und akzeptiert.',
    'slug.tos': 'agbs',
    'Terms of Service': 'AGBs',
    'slug.dataprotection': 'datenschutzerklaerung',
    'Data Privacy Policy': 'Datenschutzbestimmungen',
    'slug.disclaimer': 'haftungsausschluss',
    'Disclaimer': 'Haftungsausschluss',
    'Continue to payment provider': 'Weiter zum Zahlungsanbieter',
    'Incentives': 'Incentives',
    'Tokens sold': 'Verkaufte Anteile',
    'Price': 'Preis',
    'Share': 'Anteil',
    'Campaign Type': 'Art der Kampagne',
    'View Campaign': 'Kampagne anzeigen',
    'Campaign Goal': 'Kampagnen Ziel',
    'Share this campaign': 'Teile diese Kampagne',
    'Support Period': 'Unterstützungs-Zeitraum',
    'Release Date': 'Geplante Veröffentlichung',
    'Website & Social Media': 'Website & Social Media',
    'Visit': 'Aufrufen',
    'Show more': 'Mehr anzeigen',
    'Your Supported Campaigns': 'Deine unterstützten Kampagnen',
    'No campaigns found': 'Keine Kampagnen gefunden',
    'Browse Campaigns': 'Kampagnen durchsuchen',
    'Hello': 'Hallo',
    'Your Supports': 'Deine Unterstützungen',
    'No supports found': 'Keine Unterstützungen gefunden',
    'Your Claimed Incentives': 'Deine freigeschalteten Incentives',
    'No incentives found': 'Keine Incentives gefunden',
    'Edit Profile': 'Profil bearbeiten',
    'Your Profile': 'Dein Profil',
    'Cancel': 'Abbrechen',
    'Are you an Artist? Click here to apply': 'Bist Du ein/-e KünstlerIn? Klicke hier um Dich zu bewerben',
    'Artist Profiles': 'KünstlerInnenprofile',
    'Apply as Talenzz Artist': 'Talenzz Artist anlegen',
    'Do you want to create a Campaign?': 'Möchtest Du eine Kampagne erstellen?',
    'application.form.url': 'https://docs.google.com/forms/d/e/1FAIpQLSeH3CirjnhISd9lv7FyKl3r3gtBfNijyGPyI1_DH36tzc4AJw/viewform',
    'Click here to apply': 'Hier klicken zum anlegen',
    'Payout': 'Auszahlung',
    'talenzz pays out the streaming revenues every six months, once in June and once in December.': 'talenzz schüttet die Streaming Einnahmen halbjährlich, einmal im Juni und einmal im Dezember, aus.',
    'If you would like a payout before then, please contact': 'Wenn Du vorher eine Auszahlung wünschst, kontaktiere bitte',
    'Buy Tickets': 'Tickets kaufen',
    'FAQs': 'FAQs',
    'The future of music financing:': 'Die Zukunft der Musikfinanzierung:',
    'Crowdfunding with certain advantages.': 'Crowdfunding mit gewissen Vorzügen.',
    'talenzz: the first Community Music Label': 'talenzz ist das erste Community Musiklabel',
    'Musicians and fans have always had everything they need - the only thing missing was the right platform. Until now!': 'Musikschaffende und Fans hatten schon immer alles was nötig ist - gefehlt hat nur die richtige Plattform. Bis jetzt!',
    'Stay up to date!': 'Bleibt auf dem Laufenden!',
    'Subscribe to our newsletter and don\'t miss any news, campaigns & exciting new features of our platform!': 'Meldet Euch zu unserem Newsletter an und verpasst keine News, Kampagnen & spannende Neurungen unserer Plattform!',
    'How it works?': 'Wie funktioniert’s?',
    'For Fans': 'Für Fans',
    'For Artists': 'Für Artists',
    'Signup/Invest in Campaign.': 'Registrieren & Musikprojekte unterstützen.',
    'Get royalties of the song you supported.': 'Passiv an Streamingeinnahmen der Musik mitverdienen.',
    'Get special rewards, connect with your artist.': 'Aktiv an Musik teilhaben & spannende Rewards erhalten.',
    'Financing music projects through your fans.': 'Finanzierung von Musikprojekten durch Deine Fans.',
    'Production in the studio, release via Talenzz & involving fans.': 'Produktion im Studio, Release über Talenzz & Fans beteiligen.',
    'Easily build a lasting relationship with fans.': 'Unkompliziert eine nachhaltige Beziehung zu Fans aufbauen.',
    'Learn more': 'Mehr erfahren',
    'Create Campaign': 'Kampagne erstellen',
    'We are happy to create a campaign together. Get in touch with us now.': 'Gerne erstellen wir zusammen eine Kampagne. Nimm jetzt Kontakt mit uns auf.',
    'Write an Email': 'Schreibe eine Email',
    'Book a Videocall': 'Buche einen Videocall',
    'Close': 'Schließen',
    'pending': 'Ausstehend',
    'accepted': 'Akzeptiert',
    'rejected': 'Abgelehnt',
    'refunded': 'Zurückerstattet',
    'English': 'English',
    'German': 'German',
    'Our Partners': 'Unsere Partner',
    'Become a partner': 'Werde ein Partner',
    'The first Community Music Label': 'Das erste Community Musik Label',
    'Contact': 'Kontakt',
    'slug.imprint': 'impressum',
    'Imprint': 'Impressum',
    'Dataprotection': 'Datenschutzerklärung',
    'Forbidden': 'Verboten',
    'Sorry, but you do not have permission to access this page': 'Sorry, aber Du hast keinen Zugang zu dieser Seite',
    'Click here': 'Hier klicken',
    'to navigate back to home page': 'um zur Hauptseite zurück zu gelangen',
    'Home': 'Home',
    'Artists': 'KünstlerInnen',
    'Dashboard': 'Dashboard',
    'Profile': 'Profil',
    'Logout': 'Logout',
    'Get Started': 'Los geht\'s',
    'Newsletter': 'Newsletter',
    'Signup for the Talenzz newsletter and help us shape the future of the music industry': 'Holt euch den Talenzz Newsletter und helft uns die Zukunft der Musikindustrie mitzugestalten',
    'Sign up to our Newsletter': 'Abonniere unseren Newsletter',
    'Newsletter Signup': 'Newsletter Anmeldung',
    'Claimable by first {n} shares': 'Beanspruchbar von den ersten {n} Anteilen',
    'Claimable by first {n} investors': 'Beanspruchbar von den ersten {n} Investoren',
    'Claimable by random {n} shares': 'Beanspruchbar von {n} zufälligen Anteilen',
    'Claimable by random {n} investors': 'Beanspruchbar von {n} zufälligen Investoren',
    'Claimable by all shares': 'Beanspruchbar von allen Anteilen',
    'Claimable by all investors': 'Beanspruchbar von allen Investoren',
    'Limited': 'Limitiert',
    'after the campaign ended': 'nachdem die Kampagne beendet ist',
    'total claimed': 'Insgesamt beansprucht',
    'Shares required': 'Erforderliche Anteile',
    'claimed by you': 'Von Dir beansprucht',
    'claimable by you': 'Von Dir beanspruchbar',
    'You have successfully unlocked this perk. Your Unique Voucher Codes are': 'Du hast dieses Goodie erfolgreich freigeschaltet. Deine einmaliger Voucher Code ist',
    'Unlock': 'Freischalten',
    'Copied to clipboard': 'Zum Clipboard kopiert',
    'Share now': 'Jetzt teilen',
    'Login with': 'Anmelden mit',
    'talenzz Facts': 'talenzz Fakten',
    'Chance of being signed by a conventional label': 'Chance von einem herkömmlichen Label gesigned zu werden',
    'Per person and 1000 true fans and an album is financed': 'Pro Person und 1000 wahre Fans und ein Album ist finanziert',
    'Creative Freedom': 'Künstlerische Freiheit',
    'Account Forgot Password': 'Konto Passwort vergessen',
    'Forgot Password': 'Passwort vergessen',
    'Already logged in': 'Bereits eingeloggt',
    'An email has been sent to your email address with a link to reset your password': 'Eine E-Mail mit einem Link zum Zurücksetzen Deines Passworts wurde an Deine E-Mail-Adresse gesendet',
    'Back to': 'Zurück zu',
    'Login': 'Anmeldung',
    'Account Login': 'Konto-Anmeldung',
    'Validating your account': 'Bestätigung Deines Kontos',
    'Password': 'Passwort',
    'is too short': 'ist zu kurz',
    'Don\'t have an account?': 'Du hast noch kein Konto?',
    'Register': 'Registrieren',
    'Forgot your password?': 'Passwort vergessen?',
    'Reset Password': 'Passwort zurücksetzen',
    'Google': 'Google',
    'Facebook': 'Facebook',
    'Account Register': 'Kontoregistrierung',
    'Confirmation': 'Bestätigung',
    'Passwords': 'Passwort',
    'do not match': 'passen nicht zusammen',
    'Data Privacy': 'Datenschutz',
    'An email has been sent to you with a link to activate your account': 'Du hast eine E-Mail mit einem Link zur Aktivierung Deines Kontos erhalten',
    'Already have an account?': 'Du hast bereits ein Konto?',
    'Account New Password': 'Konto neues Passwort',
    'Set a New Password': 'Ein neues Passwort festlegen',
    'Password Confirmation': 'Passwort Bestätigung',
    'Artist Details': 'KünstlerIn Details',
    'Create Artist': 'KünstlerIn erstellen',
    'You need to sign in first': 'Du musst Dich zuerst anmelden',
    'The artist was created successfully. The Talenzz Team will review it.': 'Ein(e) KünstlerIn wurde erfolgreich erstellt. Das Talenzz-Team wird DeineDaten überprüfen.',
    'Go to Profile': 'Zum Profil gehen',
    'Meet our Talenzz': 'Trefft unsere Talenzz',
    'Update Artist': 'Update KünstlerIn',
    'The artist was updated successfully. The Talenzz Team will review it.': 'Ein(e) KünstlerIn wurde erfolgreich aktualisiert. Das Talenzz-Team wird DeineDaten überprüfen.',
    'Campaign': 'Kampagne',
    'See our Campaigns': 'Unsere Kampagnen',
    'Supports': 'Unterstützt',
    'News & Updates': 'Neuigkeiten & Updates',
    'Payment': 'Zahlung',
    'Approved': 'Genehmigt',
    'Failed': 'Gescheitert',
    'Congratulations! If you would like to return to the campaign': 'Herzlichen Glückwunsch! Wenn Du zur Kampagne zurückkehren möchtest',
    'click here': 'Hier klicken',
    'Something unexpected happened.': 'Es geschah etwas Unerwartetes.',
    'Go to Dashboard': 'Zum Dashboard gehen',
    'Go back to Campaign': 'Zurück zur Kampagne',
    'Hello World': 'Hallo Welt',
    'YYYY-MM-DD': 'DD.MM.YYYY',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'Name': 'Name',
    'Email': 'Email',
    'Submit': 'Submit',
    'On Tour': 'On Tour',
    'Load More': 'Load More',
    'is required': 'is required',
    'Description': 'Description',
    'Genre': 'Genre',
    'Location': 'Location',
    'Founding Year': 'Founding Year',
    'Amount of Members': 'Amount of Members',
    'Website URL': 'Website URL',
    'Spotify URL': 'Spotify URL',
    'Facebook URL': 'Facebook URL',
    'Instagram URL': 'Instagram URL',
    'TikTok URL': 'TikTok URL',
    'YouTube URL': 'YouTube URL',
    'Bandcamp URL': 'Bandcamp URL',
    'Application Message': 'Application Message',
    'Edit': 'Edit',
    'View Details': 'View Details',
    'About': 'About',
    'Founded': 'Founded',
    'Members': 'Members',
    'Campaigns': 'Campaigns',
    'Discography': 'Discography',
    'Support Policy & Documents': 'Support Policy & Documents',
    'Download': 'Download',
    'by': 'by',
    'Price per Share': 'Price per Share',
    'Share size': 'Share size',
    'Shares owned': 'Shares owned',
    'Shares Available': 'Shares Available',
    'Support': 'Support',
    'Login/Register to Support': 'Login/Register to Support',
    'How many shares would you like to buy?': 'How many shares would you like to buy?',
    'I have read and accepted the {link}.': 'I have read and accepted the {link}.',
    'slug.tos': 'tos',
    'Terms of Service': 'Terms of Service',
    'slug.dataprotection': 'dataprotection',
    'Data Privacy Policy': 'Data Privacy Policy',
    'slug.disclaimer': 'disclaimer',
    'Disclaimer': 'Disclaimer',
    'Continue to payment provider': 'Continue to payment provider',
    'Incentives': 'Incentives',
    'Tokens sold': 'Tokens sold',
    'Price': 'Price',
    'Share': 'Share',
    'Campaign Type': 'Campaign Type',
    'View Campaign': 'View Campaign',
    'Campaign Goal': 'Campaign Goal',
    'Share this campaign': 'Share this campaign',
    'Support Period': 'Support Period',
    'Release Date': 'Release Date',
    'Website & Social Media': 'Website & Social Media',
    'Visit': 'Visit',
    'Show more': 'Show more',
    'Your Supported Campaigns': 'Your Supported Campaigns',
    'No campaigns found': 'No campaigns found',
    'Browse Campaigns': 'Browse Campaigns',
    'Hello': 'Hello',
    'Your Supports': 'Your Supports',
    'No supports found': 'No supports found',
    'Your Claimed Incentives': 'Your Claimed Incentives',
    'No incentives found': 'No incentives found',
    'Edit Profile': 'Edit Profile',
    'Your Profile': 'Your Profile',
    'Cancel': 'Cancel',
    'Are you an Artist? Click here to apply': 'Are you an Artist? Click here to apply',
    'Artist Profiles': 'Artist Profiles',
    'Apply as Talenzz Artist': 'Apply as Talenzz Artist',
    'Do you want to create a Campaign?': 'Do you want to create a Campaign?',
    'application.form.url': 'https://docs.google.com/forms/d/e/1FAIpQLSfk7a3Fz4z7Dt_0PyujosGhLRRyEezgHCHbWGcdZpetFd3KhA/viewform',
    'Click here to apply': 'Click here to apply',
    'Payout': 'Payout',
    'talenzz pays out the streaming revenues every six months, once in June and once in December.': 'talenzz pays out the streaming revenues every six months, once in June and once in December.',
    'If you would like a payout before then, please contact': 'If you would like a payout before then, please contact',
    'Buy Tickets': 'Buy Tickets',
    'FAQs': 'FAQs',
    'talenzz: the first Community Music Label': 'talenzz: the first Community Music Label',
    'Musicians and fans have always had everything they need - the only thing missing was the right platform. Until now!': 'Musicians and fans have always had everything they need - the only thing missing was the right platform. Until now!',
    'Stay up to date!': 'Stay up to date!',
    'Subscribe to our newsletter and don\'t miss any news, campaigns & exciting new features of our platform!': 'Subscribe to our newsletter and don\'t miss any news, campaigns & exciting new features of our platform!',
    'We celebrate the launch of our platform!': 'We celebrate the launch of our platform!',
    'To celebrate our partnerships & the launch of talenzz, we are reducing transaction fees from 15% to 10%!': 'To celebrate our partnerships & the launch of talenzz, we are reducing transaction fees from 15% to 10%!',
    'How it works?': 'How it works?',
    'For Fans': 'For Fans',
    'For Artists': 'For Artists',
    'Signup/Invest in Campaign.': 'Signup/Invest in Campaign.',
    'Get royalties of the song you supported.': 'Get royalties of the song you supported.',
    'Get special rewards, connect with your artist.': 'Get special rewards, connect with your artist.',
    'Financing music projects through your fans.': 'Financing music projects through your fans.',
    'Production in the studio, release via Talenzz & involving fans.': 'Production in the studio, release via Talenzz & involving fans.',
    'Easily build a lasting relationship with fans.': 'Easily build a lasting relationship with fans.',
    'Learn more': 'Learn more',
    'Create Campaign': 'Create Campaign',
    'We are happy to create a campaign together. Get in touch with us now.': 'We are happy to create a campaign together. Get in touch with us now.',
    'Write an Email': 'Write an Email',
    'Book a Videocall': 'Book a Videocall',
    'Close': 'Close',
    'pending': 'pending',
    'accepted': 'accepted',
    'rejected': 'rejected',
    'refunded': 'refunded',
    'English': 'English',
    'German': 'German',
    'Our Partners': 'Our Partners',
    'Become a partner': 'Become a partner',
    'The first Community Music Label': 'The first Community Music Label',
    'Contact': 'Contact',
    'slug.imprint': 'imprint',
    'Imprint': 'Imprint',
    'Dataprotection': 'Dataprotection',
    'Forbidden': 'Forbidden',
    'Sorry, but you do not have permission to access this page': 'Sorry, but you do not have permission to access this page',
    'Click here': 'Click here',
    'to navigate back to home page': 'to navigate back to home page',
    'Home': 'Home',
    'Artists': 'Artists',
    'Dashboard': 'Dashboard',
    'Profile': 'Profile',
    'Logout': 'Logout',
    'Get Started': 'Get Started',
    'Newsletter': 'Newsletter',
    'Signup for the Talenzz newsletter and help us shape the future of the music industry': 'Signup for the Talenzz newsletter and help us shape the future of the music industry',
    'Sign up to our Newsletter': 'Sign up to our Newsletter',
    'Newsletter Signup': 'Newsletter Signup',
    'Claimable by first {n} shares': 'Claimable by first {n} shares',
    'Claimable by first {n} investors': 'Claimable by first {n} investors',
    'Claimable by random {n} shares': 'Claimable by random {n} shares',
    'Claimable by random {n} investors': 'Claimable by random {n} investors',
    'Claimable by all shares': 'Claimable by all shares',
    'Claimable by all investors': 'Claimable by all investors',
    'Limited': 'Limited',
    'after the campaign ended': 'after the campaign ended',
    'total claimed': 'total claimed',
    'Shares required': 'Shares required',
    'claimed by you': 'claimed by you',
    'claimable by you': 'claimable by you',
    'You have successfully unlocked this perk. Your Unique Voucher Codes are': 'You have successfully unlocked this perk. Your Unique Voucher Codes are',
    'Unlock': 'Unlock',
    'Copied to clipboard': 'Copied to clipboard',
    'Share now': 'Share now',
    'Login with': 'Login with',
    'talenzz Facts': 'talenzz Facts',
    'Chance of being signed by a conventional label': 'Chance of being signed by a conventional label',
    'Per person and 1000 true fans and an album is financed': 'Per person and 1000 true fans and an album is financed',
    'Creative Freedom': 'Creative Freedom',
    'Account Forgot Password': 'Account Forgot Password',
    'Forgot Password': 'Forgot Password',
    'Already logged in': 'Already logged in',
    'An email has been sent to your email address with a link to reset your password': 'An email has been sent to your email address with a link to reset your password',
    'Back to': 'Back to',
    'Login': 'Login',
    'Account Login': 'Account Login',
    'Validating your account': 'Validating your account',
    'Password': 'Password',
    'is too short': 'is too short',
    'Don\'t have an account?': 'Don\'t have an account?',
    'Register': 'Register',
    'Forgot your password?': 'Forgot your password?',
    'Reset Password': 'Reset Password',
    'Google': 'Google',
    'Facebook': 'Facebook',
    'Account Register': 'Account Register',
    'Confirmation': 'Confirmation',
    'Passwords': 'Passwords',
    'do not match': 'do not match',
    'Data Privacy': 'Data Privacy',
    'An email has been sent to you with a link to activate your account': 'An email has been sent to you with a link to activate your account',
    'Already have an account?': 'Already have an account?',
    'Account New Password': 'Account New Password',
    'Set a New Password': 'Set a New Password',
    'Password Confirmation': 'Password Confirmation',
    'Artist Details': 'Artist Details',
    'Create Artist': 'Create Artist',
    'You need to sign in first': 'You need to sign in first',
    'The artist was created successfully. The Talenzz Team will review it.': 'The artist was created successfully. The Talenzz Team will review it.',
    'Go to Profile': 'Go to Profile',
    'Meet our Talenzz': 'Meet our Talenzz',
    'Update Artist': 'Update Artist',
    'The artist was updated successfully. The Talenzz Team will review it.': 'The artist was updated successfully. The Talenzz Team will review it.',
    'Campaign': 'Campaign',
    'See our Campaigns': 'See our Campaigns',
    'Supports': 'Supports',
    'News & Updates': 'News & Updates',
    'Payment': 'Payment',
    'Approved': 'Approved',
    'Failed': 'Failed',
    'Congratulations! If you would like to return to the campaign': 'Congratulations! If you would like to return to the campaign',
    'click here': 'click here',
    'Something unexpected happened.': 'Something unexpected happened.',
    'Go to Dashboard': 'Go to Dashboard',
    'Go back to Campaign': 'Go back to Campaign',
    'Hello World': 'Hello World',
    'YYYY-MM-DD': 'YYYY-MM-DD',
  },
}
