import { createSlice } from "@reduxjs/toolkit";
import { defaultInitialState } from "../defaultInitialState";
import { defaultAddToDictionary } from "../defaultReducers";

export const paymentsSlice = createSlice({
  name: "payments",
  initialState: {
    ...defaultInitialState,
  },
  reducers: {
    addToDictionary: defaultAddToDictionary,
  },
});

export const actions = paymentsSlice.actions;

export default paymentsSlice.reducer;
