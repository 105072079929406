import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Page from "../components/layout/Page";
import useAccount from "../hooks/useAccount";
import { registerAccount } from "../redux/accounts";
import { getEnv } from "../utils/env";

const AccountRegister = (_props, context) => {
  const REACT_APP_WEB_SERVER = getEnv("REACT_APP_WEB_SERVER");
  const dispatch = useDispatch();
  const { currentUser, registered, registerErrors } = useAccount();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(
      registerAccount({
        ...data,
        confirm_success_url: REACT_APP_WEB_SERVER + "/login",
        successCallbackFnc: () => {
          reset();
        },
      })
    );
  };

  const currentLang = useSelector((state) => state.i18nState.lang);

  return (
    <Page title={context.t("Account Register")} className="pt-4">
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <h1>{context.t("Register")}</h1>
            {currentUser ? (
              <p>{context.t("Already logged in")}</p>
            ) : (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Label for="name">{context.t("Name")}</Label>
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(v) => {
                          field.onChange(v);
                        }}
                        id="name"
                        invalid={errors?.name || registerErrors?.name}
                      />
                    )}
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                  />

                  {errors.name?.type === "required" && (
                    <p className="text-danger small mt-1">
                      {context.t("Name")} {context.t("is required")}
                    </p>
                  )}

                  {registerErrors?.name?.map((error) => (
                    <p className="text-danger small mt-1" key={error}>
                      {error}
                    </p>
                  ))}
                </FormGroup>

                <FormGroup>
                  <Label for="email">{context.t("Email")}</Label>
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(v) => {
                          field.onChange(v);
                        }}
                        type="email"
                        id="email"
                        invalid={errors?.email || registerErrors?.email}
                        autoComplete="email"
                      />
                    )}
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                  />

                  {errors.email?.type === "required" && (
                    <p className="text-danger small mt-1">
                      {context.t("Email")} {context.t("is required")}
                    </p>
                  )}

                  {registerErrors?.email?.map((error) => (
                    <p className="text-danger small mt-1" key={error}>
                      {error}
                    </p>
                  ))}
                </FormGroup>

                <FormGroup>
                  <Label for="password">{context.t("Password")}</Label>
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(v) => {
                          field.onChange(v);
                        }}
                        type="password"
                        id="password"
                        invalid={errors?.password || registerErrors?.password}
                        autoComplete="new-password"
                      />
                    )}
                    name="password"
                    control={control}
                    rules={{ required: true, minLength: 6 }}
                    defaultValue=""
                  />

                  {errors.password?.type === "required" && (
                    <p className="text-danger small mt-1">
                      {context.t("Password")} {context.t("is required")}
                    </p>
                  )}
                  {errors.password?.type === "minLength" && (
                    <p className="text-danger small mt-1">
                      {context.t("Password")} {context.t("is too short")}
                    </p>
                  )}

                  {registerErrors?.password?.map((error) => (
                    <p className="text-danger small mt-1" key={error}>
                      {error}
                    </p>
                  ))}
                </FormGroup>

                <FormGroup>
                  <Label for="password_confirmation">
                    {context.t("Password")} {context.t("Confirmation")}
                  </Label>
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(v) => {
                          field.onChange(v);
                        }}
                        type="password"
                        id="password_confirmation"
                        invalid={
                          errors?.password_confirmation ||
                          registerErrors?.password_confirmation
                        }
                        autoComplete="new-password"
                      />
                    )}
                    name="password_confirmation"
                    control={control}
                    rules={{
                      required: true,
                      minLength: 6,
                      validate: {
                        match: (v) => v === getValues("password"),
                      },
                    }}
                    defaultValue=""
                  />

                  {errors.password_confirmation?.type === "required" && (
                    <p className="text-danger small mt-1">
                      {context.t("Password")} {context.t("Confirmation")}{" "}
                      {context.t("is required")}
                    </p>
                  )}
                  {errors.password_confirmation?.type === "minLength" && (
                    <p className="text-danger small mt-1">
                      {context.t("Password")} {context.t("Confirmation")}{" "}
                      {context.t("is too short")}
                    </p>
                  )}
                  {errors.password_confirmation?.type === "match" && (
                    <p className="text-danger small mt-1">
                      {context.t("Passwords")} {context.t("do not match")}
                    </p>
                  )}

                  {registerErrors?.password_confirmation?.map((error) => (
                    <p className="text-danger small mt-1" key={error}>
                      {error}
                    </p>
                  ))}
                </FormGroup>

                <FormGroup check>
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(v) => {
                          field.onChange(v);
                        }}
                        type="checkbox"
                        id="tos"
                      />
                    )}
                    name="tos"
                    control={control}
                    rules={{ required: true }}
                  />

                  <Label
                    for="tos"
                    dangerouslySetInnerHTML={{
                      __html: context.t(
                        "I have read and accepted the {link}.",
                        {
                          link: `<a href="/${currentLang}/p/${context.t(
                            "slug.tos"
                          )}" target="_blank" rel="noreferrer">${context.t(
                            "Terms of Service"
                          )}</a>`,
                        }
                      ),
                    }}
                  />

                  {errors.tos?.type === "required" && (
                    <p className="text-danger small mt-1">
                      {context.t("Terms of Service")} {context.t("is required")}
                    </p>
                  )}
                </FormGroup>

                <FormGroup check>
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(v) => {
                          field.onChange(v);
                        }}
                        type="checkbox"
                        id="data_privacy"
                      />
                    )}
                    name="data_privacy"
                    control={control}
                    rules={{ required: true }}
                  />
                  <Label
                    for="data_privacy"
                    dangerouslySetInnerHTML={{
                      __html: context.t(
                        "I have read and accepted the {link}.",
                        {
                          link: `<a href="/${currentLang}/p/${context.t(
                            "slug.dataprotection"
                          )}" target="_blank" rel="noreferrer">${context.t(
                            "Data Privacy Policy"
                          )}</a>`,
                        }
                      ),
                    }}
                  />

                  {errors.data_privacy?.type === "required" && (
                    <p className="text-danger small mt-1">
                      {context.t("Data Privacy")} {context.t("is required")}
                    </p>
                  )}
                </FormGroup>

                {registered && (
                  <p className="text-success small mt-1">
                    {context.t(
                      "An email has been sent to you with a link to activate your account"
                    )}
                    .
                  </p>
                )}

                <Button
                  disabled={!watch("tos") || !watch("data_privacy")}
                  type="submit"
                  color="primary"
                  className="w-100 mb-3"
                >
                  {context.t("Register")}
                </Button>

                <div className="mb-3">
                  <small>
                    {context.t("Already have an account?")}{" "}
                    <Link to={`/${currentLang}/login`}>
                      {context.t("Login")}
                    </Link>
                  </small>
                </div>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

AccountRegister.contextTypes = {
  t: PropTypes.func,
};

export default AccountRegister;
