import PropTypes from "prop-types";
import * as React from "react";

const TalenzzLogo = ({ width, height, className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 473.2 171.68"
      alt="talenzz"
    >
      <path
        fill="currentColor"
        stroke="none"
        d="m137.65,101.98c-.43-1.56-.69-3.15-.78-4.76-.11-1.74-.17-3.55-.17-5.41v-31.83c.2-5.42-1.58-10.72-5.02-14.91-3.55-4.07-8.13-7.12-13.25-8.82-5.77-1.99-11.83-2.98-17.93-2.92-6.35-.1-12.66.97-18.62,3.15-5.12,1.82-9.64,5.02-13.06,9.24-3.28,4.28-4.97,9.56-4.8,14.95h21.15c-.22-3.21,1.25-6.31,3.89-8.16,3.43-1.98,7.36-2.9,11.31-2.65,3.79-.2,7.56.61,10.93,2.36,2.42,1.24,3.93,3.73,3.92,6.44v.12c0,1.59-1.54,2.71-4.62,3.38-3.08.66-8.66,1.39-16.73,2.17-5.25.46-10.43,1.6-15.38,3.39-4.37,1.51-8.29,4.1-11.39,7.53-2.96,3.55-4.49,8.07-4.3,12.69-.12,4.21,1.12,8.34,3.54,11.79,2.5,3.35,5.89,5.92,9.79,7.43,4.56,1.79,9.43,2.66,14.33,2.57,4.29.03,8.57-.56,12.69-1.76,3.69-1.06,7.16-2.78,10.24-5.07,1.01-.74,1.94-1.58,2.79-2.5.19.77.41,1.54.67,2.29.42,1.22.99,2.4,1.69,3.49.44.72.94,1.39,1.49,2.02h21.11c-.65-.68-1.22-1.42-1.7-2.22-.77-1.24-1.36-2.58-1.76-3.98Zm-24.91-15.97c-1.78,2.57-4.27,4.58-7.16,5.76-2.98,1.23-6.18,1.85-9.41,1.83h0c-3.08.2-6.15-.46-8.89-1.9-1.97-1.21-3.11-3.41-2.99-5.72-.12-2.44,1.27-4.7,3.51-5.68,3.36-1.39,6.93-2.21,10.55-2.4,3.97-.41,7.95-.97,11.96-1.68,1.75-.29,3.48-.66,5.19-1.1v.58c.17,3.64-.8,7.24-2.77,10.3Z"
      />
      <rect
        fill="currentColor"
        stroke="none"
        x="147.92"
        width="21.14"
        height="108.17"
      />
      <path
        fill="currentColor"
        stroke="none"
        d="m38.52,10.48h-21.51v9.26c.04,2.8-.09,5.59-.37,8.37-.09,1.6-.63,3.14-1.56,4.44-.95,1.02-2.25,1.65-3.65,1.77-2.2.27-4.42.38-6.64.35H0v16.61h17v37.23c-.17,3.86.64,7.7,2.35,11.16,1.42,2.66,3.56,4.87,6.17,6.37,2.58,1.45,5.39,2.43,8.3,2.9,2.87.48,5.78.73,8.7.74,2.06.02,4.11-.15,6.14-.53,1.39-.25,2.75-.62,4.08-1.11v-16.18c-1,.38-2.03.66-3.07.86-1.43.29-2.89.44-4.35.43-3.02,0-4.91-1-5.67-2.99-.84-2.49-1.23-5.12-1.15-7.75v-31.14h21.36v-16.61h-21.34V10.48Z"
      />
      <path
        fill="currentColor"
        stroke="none"
        d="m238.75,38.18c-6.54-3.38-13.83-5.06-21.2-4.87-7.3-.15-14.53,1.5-21.05,4.8-5.91,3.04-10.83,7.69-14.21,13.41-3.49,6.1-5.25,13.04-5.09,20.07-.14,6.95,1.59,13.81,5,19.87,3.31,5.74,8.19,10.42,14.06,13.5,6.57,3.37,13.88,5.04,21.26,4.87,5.4.08,10.78-.62,15.97-2.08,4.27-1.19,8.31-3.1,11.94-5.65,3.06-2.14,5.68-4.86,7.7-8.01,1.77-2.74,2.87-5.87,3.2-9.12h-21.23c-.17,1.07-.58,2.09-1.21,2.98-.8,1.11-1.82,2.06-2.99,2.77-1.58.96-3.3,1.65-5.1,2.05-2.44.56-4.94.82-7.45.78-3.87.13-7.7-.79-11.07-2.68-2.98-1.8-5.32-4.49-6.69-7.69-.71-1.72-1.25-3.51-1.63-5.32h58.11c.12-.78.19-1.58.21-2.37.05-1.06.07-2.15.07-3.28.16-7.11-1.51-14.14-4.84-20.42-3.18-5.78-7.96-10.5-13.78-13.61Zm-31.36,13.74c3.19-1.79,6.8-2.67,10.45-2.57,3.49-.14,6.96.66,10.03,2.31,2.46,1.39,4.47,3.45,5.79,5.94.99,1.89,1.6,3.95,1.8,6.07h-36.25c.38-1.47.87-2.9,1.49-4.29,1.42-3.11,3.75-5.71,6.69-7.46Z"
      />
      <path
        fill="currentColor"
        stroke="none"
        d="m326.75,36.92c-4.54-2.56-9.71-3.81-14.92-3.61-5.51-.07-10.95,1.33-15.74,4.06-3.73,2.13-6.94,5.05-9.44,8.51v-11.22h-21.45v73.48h21.45s0-39.74,0-39.74c.11-2.81.77-5.58,1.94-8.15,1.21-2.61,3.15-4.82,5.58-6.35,2.59-1.57,5.57-2.36,8.6-2.28,3.09-.2,6.16.6,8.77,2.27,2.03,1.51,3.51,3.64,4.22,6.06.81,2.72,1.2,5.54,1.17,8.37v39.82h21.44s0-46.01,0-46.01c.11-5.19-.87-10.34-2.87-15.13-1.8-4.19-4.85-7.72-8.74-10.1Z"
      />
      <polygon
        fill="currentColor"
        stroke="none"
        points="434.46 91.34 472.4 50.59 472.4 34.67 411.77 34.67 411.77 51.43 448.56 51.43 410.36 92.25 410.36 108.17 473.2 108.17 473.2 91.34 434.46 91.34"
      />
      <polygon
        fill="currentColor"
        stroke="none"
        points="407.52 91.34 368.77 91.34 406.71 50.59 406.71 34.67 346.08 34.67 346.08 51.43 382.88 51.43 344.68 92.25 344.68 108.17 407.52 108.17 407.52 91.34"
      />
      <path
        fill="currentColor"
        stroke="none"
        d="m463.64,129.32c-4.54-1.5-9.43.96-10.93,5.49-.07.21-7.59,21.26-42.13,19.45-34.64-1.81-43.38-19.19-43.84-20.17-1.9-4.38-6.99-6.4-11.38-4.5-4.38,1.9-6.4,6.99-4.5,11.38.49,1.15,12.46,28.16,58.82,30.58,1.76.09,3.46.14,5.12.14,43.2,0,53.85-30,54.29-31.33.01-.03.02-.06.03-.09,1.5-4.54-.96-9.43-5.49-10.93Z"
      />
    </svg>
  );
};

TalenzzLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

TalenzzLogo.defaultProps = {
  width: "100%",
  height: "100%",
};

export default TalenzzLogo;
