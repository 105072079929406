import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Container,
} from "reactstrap";

const HomeHero = (props, context) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const items = [
    {
      src: "/assets/images/Slide1.jpg",
      textClass: "text-light",
      mobileBackground: "#121212",
      title: context.t("The future of music financing:"),
      subtitle: context.t("Crowdfunding with certain advantages."),
      key: 1,
    },
    {
      src: "/assets/images/Slide2.jpg",
      title: context.t("talenzz: the first Community Music Label"),
      subtitle: context.t(
        "Musicians and fans have always had everything they need - the only thing missing was the right platform. Until now!"
      ),

      textClass: "text-dark",
      mobileBackground: "#f2f2f2",
      key: 2,
    },
    {
      src: "/assets/images/Slide3.jpg",
      title: context.t("Stay up to date!"),
      subtitle: context.t(
        "Subscribe to our newsletter and don't miss any news, campaigns & exciting new features of our platform!"
      ),
      textClass: "text-light",
      mobileBackground: "#282421",
      key: 3,
    },
  ];

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
        className="homehero__item position-relative"
      >
        <div style={{ backgroundColor: item.mobileBackground }}>
          <div
            className="ratio ratio-21x9"
            style={{
              backgroundImage: `url(${item.src})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="homehero__item__text">
            <div className="h-100">
              <Container className="d-flex flex-column h-100 py-5">
                <div
                  className={`homehero__item__text__wrapper ${item.textClass}`}
                >
                  <p className="fw-bold homehero__item__text__headline">
                    {item.title}
                  </p>
                  <p className="mb-4 homehero__item__text__subtitle">
                    {item.subtitle}
                  </p>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={10000}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

/**  define proptype for the 'translation' function  */
HomeHero.contextTypes = {
  t: PropTypes.func,
};

HomeHero.propTypes = {};

export default HomeHero;
