import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import Page from "../components/layout/Page";
import { getStaticPageAttributes, showStaticPage } from "../redux/staticPages";

function StaticPage() {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    registerEffect(dispatch, showStaticPage({ slug }));
  }, []);

  useEffect(() => {
    dispatch(showStaticPage({ slug }));
  }, [dispatch, slug]);

  const staticPage = useSelector((state) =>
    getStaticPageAttributes(state, slug)
  );

  const content = { __html: staticPage?.content };

  return (
    <Page title={staticPage?.title} className="pt-4">
      <Container>
        <div dangerouslySetInnerHTML={content} />
      </Container>
    </Page>
  );
}

StaticPage.contextTypes = {
  t: PropTypes.func,
};

export default StaticPage;
