import { call, getContext, put, takeEvery } from "redux-saga/effects";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";

/*
Replace
faq with your resource Name :) Take care about different writings!
/!\ Faq faq Faqs faqs /!\
Keep an eye on pluralized namings in here :)

*/

/** List Saga
 *  @description: connects to the getFaq operation
 */
export function* list() {
  const headers = yield buildHeaders(yield getContext("tokens"));
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Faqs.listFaqs,
      {},
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.listSuccess(payload));
  } catch (e) {
    yield put(actions.listFail({ message: e.message, ...e }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* faqsSaga() {
  yield takeEvery(actions.list, list);
}
export default faqsSaga;
