import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { getArtistAttributes } from "../../../redux/artists";
import { useSelector } from "react-redux";

const ArtistForm = ({ artistId, onSubmit, isEditRoute }, context) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const artistData = useSelector((state) =>
    getArtistAttributes(state, artistId)
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!isEditRoute && (
        <FormGroup>
          <Label for="name">{context.t("Name")}</Label>
          <Controller
            render={({ field }) => (
              <Input
                {...field}
                onChange={(v) => {
                  field.onChange(v);
                }}
                type="text"
                placeholder="Artist Name..."
                id="name"
                invalid={errors?.name}
              />
            )}
            name="name"
            control={control}
            rules={{ required: true }}
            defaultValue=""
          />

          {errors.name?.type === "required" && (
            <p className="text-danger small mt-1">
              {context.t("Name")} {context.t("is required")}
            </p>
          )}
        </FormGroup>
      )}

      <FormGroup>
        <Label for="description">{context.t("Description")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="textarea"
              placeholder="Briefly describe the artist here..."
              id="description"
              invalid={errors?.description}
            />
          )}
          name="description"
          control={control}
          rules={{ required: !isEditRoute }}
          defaultValue={artistData?.description || ""}
        />

        {errors.description?.type === "required" && (
          <p className="text-danger small mt-1">
            {context.t("Description")} {context.t("is required")}
          </p>
        )}
      </FormGroup>

      {!isEditRoute && (
        <FormGroup>
          <Label for="genre">{context.t("Genre")}</Label>
          <Controller
            render={({ field }) => (
              <Input
                {...field}
                onChange={(v) => {
                  field.onChange(v);
                }}
                type="text"
                placeholder="Enter Genre (e.g., Rock, Pop, Jazz...)"
                id="genre"
                invalid={errors?.genre}
              />
            )}
            name="genre"
            control={control}
            rules={{ required: true }}
            defaultValue=""
          />

          {errors.genre?.type === "required" && (
            <p className="text-danger small mt-1">
              {context.t("Genre")} {context.t("is required")}
            </p>
          )}
        </FormGroup>
      )}

      {!isEditRoute && (
        <FormGroup>
          <Label for="location">{context.t("Location")}</Label>
          <Controller
            render={({ field }) => (
              <Input
                {...field}
                onChange={(v) => {
                  field.onChange(v);
                }}
                type="text"
                placeholder="City, Country..."
                id="location"
                invalid={errors?.location}
              />
            )}
            name="location"
            control={control}
            rules={{ required: true }}
            defaultValue=""
          />

          {errors.location?.type === "required" && (
            <p className="text-danger small mt-1">
              {context.t("Location")} {context.t("is required")}
            </p>
          )}
        </FormGroup>
      )}

      {!isEditRoute && (
        <FormGroup>
          <Label for="founded_at">{context.t("Founding Year")}</Label>
          <Controller
            render={({ field }) => (
              <Input
                {...field}
                onChange={(v) => {
                  field.onChange(v);
                }}
                type="number"
                placeholder="YYYY"
                id="founded_at"
                invalid={errors?.founded_at}
              />
            )}
            name="founded_at"
            control={control}
            defaultValue=""
          />
        </FormGroup>
      )}

      <FormGroup>
        <Label for="members_count">{context.t("Amount of Members")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="number"
              id="members_count"
              invalid={errors?.members_count}
            />
          )}
          name="members_count"
          control={control}
          defaultValue={artistData?.members_count || ""}
        />
      </FormGroup>

      <FormGroup>
        <Label for="website_url">{context.t("Website URL")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="url"
              id="website_url"
              invalid={errors?.website_url}
            />
          )}
          name="website_url"
          control={control}
          defaultValue={artistData?.website_url || ""}
        />
      </FormGroup>

      <FormGroup>
        <Label for="spotify_url">{context.t("Spotify URL")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="url"
              placeholder="https://open.spotify.com/artist/id"
              id="spotify_url"
              invalid={errors?.spotify_url}
            />
          )}
          name="spotify_url"
          control={control}
          defaultValue={artistData?.spotify_url || ""}
        />
      </FormGroup>

      <FormGroup>
        <Label for="facebook_url">{context.t("Facebook URL")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="url"
              placeholder="https://www.facebook.com/profile.php?id=facebook_id"
              id="facebook_url"
              invalid={errors?.facebook_url}
            />
          )}
          name="facebook_url"
          control={control}
          defaultValue={artistData?.facebook_url || ""}
        />
      </FormGroup>

      <FormGroup>
        <Label for="instagram_url">{context.t("Instagram URL")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="url"
              placeholder="https://www.instagram.com/username"
              id="instagram_url"
              invalid={errors?.instagram_url}
            />
          )}
          name="instagram_url"
          control={control}
          defaultValue={artistData?.instagram_url || ""}
        />
      </FormGroup>

      <FormGroup>
        <Label for="tiktok_url">{context.t("TikTok URL")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="url"
              placeholder="https://www.tiktok.com/@username"
              id="tiktok_url"
              invalid={errors?.tiktok_url}
            />
          )}
          name="tiktok_url"
          control={control}
          defaultValue={artistData?.tiktok_url || ""}
        />
      </FormGroup>

      <FormGroup>
        <Label for="youtube_url">{context.t("YouTube URL")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="url"
              placeholder="https://www.youtube.com/@username"
              id="youtube_url"
              invalid={errors?.youtube_url}
            />
          )}
          name="youtube_url"
          control={control}
          defaultValue={artistData?.youtube_url || ""}
        />
      </FormGroup>

      <FormGroup>
        <Label for="bandcamp_url">{context.t("Bandcamp URL")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="url"
              placeholder="https://username.bandcamp.com"
              id="bandcamp_url"
              invalid={errors?.bandcamp_url}
            />
          )}
          name="bandcamp_url"
          control={control}
          defaultValue={artistData?.bandcamp_url || ""}
        />
      </FormGroup>

      {!isEditRoute && (
        <FormGroup>
          <Label for="application_message">
            {context.t("Application Message")}
          </Label>
          <Controller
            render={({ field }) => (
              <Input
                {...field}
                onChange={(v) => {
                  field.onChange(v);
                }}
                type="textarea"
                placeholder="Why do you want/What do you want to do..."
                id="application_message"
                invalid={errors?.application_message}
              />
            )}
            name="application_message"
            control={control}
            rules={{ required: true }}
            defaultValue=""
          />

          {errors.application_message?.type === "required" && (
            <p className="text-danger small mt-1">
              {context.t("Application Message")} {context.t("is required")}
            </p>
          )}
        </FormGroup>
      )}

      <Button type="submit" color="primary" className="w-100 mb-3">
        {context.t("Submit")}
      </Button>
    </Form>
  );
};

ArtistForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isEditRoute: PropTypes.bool,
  artistId: PropTypes.string,
};

ArtistForm.defaultProps = {
  isEditRoute: false,
};

ArtistForm.contextTypes = {
  t: PropTypes.func,
};

export default ArtistForm;
