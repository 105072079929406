import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Loadable from "react-loadable";
import I18n from "redux-i18n";
import "./index.sass";
import Root from "./App";
import reportWebVitals from "./reportWebVitals";
import { createSsr } from "@issr/core";
import { translations } from "./locales/translations";
import rootSaga from "./redux/rootSaga";
import configureStore from "./redux/store";
import { getTokenCookies } from "../src/utils/tokenCookies";

const tokenCookies = getTokenCookies();
const tokens = {
  ...tokenCookies,
  "access-token": tokenCookies?.accessToken,
};
/** create a store */
const { store } = configureStore(tokens);

/** run sagas */
store.runSaga(rootSaga);
const { currentUser } = store.getState().accounts;
if (tokens?.accessToken && !currentUser) {
  store.dispatch({ type: "accounts/validateToken" });
}
const SSR = createSsr();

const Application = () => (
  <Provider store={store}>
    <I18n translations={translations} initialLang="de">
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </I18n>
  </Provider>
);

const rootEl = document.getElementById("root");
/**
 * the index main
 */

if (rootEl.hasChildNodes()) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrateRoot(
      rootEl,
      <SSR>
        <Application />
      </SSR>
    );
  });
} else {
  const root = createRoot(rootEl);

  // If we're not running on the server, just render like normal
  root.render(
    <SSR>
      <Application />
    </SSR>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/** build the service worker configuration object */
// var config = configureWorker(store);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// if (!isServer) {
//   // serviceWorkerRegistration.register(config);
//   serviceWorkerRegistration.unregister();
// } else {
//   serviceWorkerRegistration.unregister();
// }
