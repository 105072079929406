// import { apiClient, applyHeaders, buildHeaders } from "@bkry/bowline-utils";
import { call, put, takeEvery, getContext } from "redux-saga/effects";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";

/** List Saga
 *  @description: connects to the getCampaign operation
 */
export function* list(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));
  const {
    page,
    endlessScroll,
    investedCampaigns: invested_campaigns,
  } = action.payload;
  try {
    // TODO: cant the SwaggerClient be imported from the apiSwaggerRequest?
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Campaigns.listCampaigns,
      { page, invested_campaigns },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(
      actions.listSuccess({ ...payload, endlessScroll: !!endlessScroll })
    );
  } catch (e) {
    yield put(actions.listFail({ message: e.message, ...e }));
  }
}

export function* create(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));
  let { ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Campaigns.createCampaign,
      {},
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.createSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.createFail({ message: e.message, ...e }));
  }
}
/** Show Saga
 *  @description: connects to the showCampaign operation
 *  @param {number} action.payload the Campaign id
 */
export function* show(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));
  const { id } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Campaigns.showCampaign,
      {
        id: id,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.showSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.showFail({ message: e.message, ...e }));
  }
}

export function* update(action) {
  const { id, ...requestBody } = action.payload;
  const headers = yield buildHeaders(yield getContext("tokens"));
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Campaigns.updateCampaign,
      { id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.updateSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.updateFail({ message: e.message, ...e }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* campaignsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.create, create);
}
export default campaignsSaga;
