import { createSlice } from "@reduxjs/toolkit";
import {
  defaultAddToDictionary,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateFail,
  defaultUpdateSuccess,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const accountsSlice = createSlice({
  name: "accounts",
  initialState: {
    ...defaultInitialState,
    loggingIn: false,
    loggedIn: false,
    loginErrors: {},
    dictionary: {},
    currentUser: null,
    registering: false,
    registered: false,
    registerErrors: {},
    loggingOut: false,
    loggedOut: false,
    logoutErrors: {},
    resettingPassword: false,
    resetPassword: false,
    resetPasswordErrors: {},
    updatingPassword: false,
    updatedPassword: false,
    updatePasswordErrors: {},
  },
  reducers: {
    login: (state) => {
      state.loggingIn = true;
      state.loggedIn = false;
      state.loginErrors = {};
    },
    loginSuccess: (state, action) => {
      state.loggingIn = false;
      state.loggedIn = true;
      state.dictionary[action.payload.id] = action.payload;
      state.currentUser = action.payload;
      state.loginErrors = {};
    },
    loginFail: (state, action) => {
      state.loggingIn = false;
      state.loggedIn = false;
      state.loginErrors = action.payload?.attributes?.errors;
      state.currentUser = null;
    },
    register: (state) => {
      state.registering = true;
      state.registered = false;
      state.registerErrors = {};
    },
    registerSuccess: (state, action) => {
      state.registering = false;
      state.registered = true;
      state.dictionary[action.payload.id] = action.payload;
      state.registerErrors = {};
    },
    registerFail: (state, action) => {
      state.registering = false;
      state.registered = false;
      state.registerErrors =
        action.payload?.attributes?.errors || action.payload;
    },
    resetRegister: (state) => {
      state.registering = false;
      state.registered = false;
      state.registerErrors = {};
    },
    logout: (state) => {
      state.loggingOut = true;
      state.loggedOut = false;
      state.logoutErrors = {};
    },
    logoutSuccess: (state) => {
      state.loggingOut = false;
      state.loggedOut = true;
      state.currentUser = null;
      state.logoutErrors = {};
    },
    logoutFail: (state, action) => {
      state.loggingOut = false;
      state.loggedOut = false;
      state.logoutErrors = action.payload?.attributes?.errors || action.payload;
    },
    validateToken: (state) => {
      state.loggingIn = true;
      state.loggedIn = false;
      state.loginErrors = {};
    },
    resetPassword: (state) => {
      state.resettingPassword = true;
      state.resetPassword = false;
      state.resetPasswordErrors = {};
    },
    resetPasswordSuccess: (state) => {
      state.resettingPassword = false;
      state.resetPassword = true;
      state.resetPasswordErrors = {};
    },
    resetPasswordFail: (state, action) => {
      state.resettingPassword = false;
      state.resetPassword = false;
      state.resetPasswordErrors =
        action.payload?.attributes?.errors || action.payload;
    },
    updatePassword: (state) => {
      state.updatingPassword = true;
      state.updatedPassword = false;
      state.updatePasswordErrors = {};
    },
    updatePasswordSuccess: (state, action) => {
      state.updatingPassword = false;
      state.updatedPassword = true;
      state.updatePasswordErrors = {};
      state.dictionary[action.payload.id] = action.payload;
      state.currentUser = action.payload;
    },
    updatePasswordFail: (state, action) => {
      state.updatingPassword = false;
      state.updatedPassword = false;
      state.updatePasswordErrors =
        action.payload?.attributes?.errors || action.payload;
    },
    addToDictionary: defaultAddToDictionary,
    show: defaultShow,
    showSuccess: (state, action) => {
      defaultShowSuccess(state, action);
      if (state.currentUser.id === action.payload.id) {
        state.currentUser = action.payload;
      }
    },
    showFail: defaultShowFail,
    update: defaultUpdate,
    updateFail: defaultUpdateFail,
    updateSuccess: (state, action) => {
      defaultUpdateSuccess(state, action);
      state.currentUser = action.payload;
    },
  },
});

export const {
  login: loginAccount,
  register: registerAccount,
  logout: logoutAccount,
  validateToken,
  resetPassword,
  updatePassword,
  show: showUser,
  update: updateAccount,
} = accountsSlice.actions;
export const actions = accountsSlice.actions;

export default accountsSlice.reducer;
