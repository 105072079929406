import { call, getContext, put, select, takeEvery } from "redux-saga/effects";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";

/*
Replace
event with your resource Name :) Take care about different writings!
/!\ Event event Events events /!\
Keep an eye on pluralized namings in here :)

*/

/** List Saga
 *  @description: connects to the getEvent operation
 */
export function* list(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));

  const { artistId: artist_id, page, endlessScroll } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Events.listEvents,
      { artist_id, page },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(
      actions.listSuccess({ ...payload, endlessScroll: !!endlessScroll })
    );
  } catch (e) {
    yield put(actions.listFail({ message: e.message, ...e }));
  }
}

export function* create(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));

  const { artistId: artist_id, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Events.createEvent,
      { artist_id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.createSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.createFail({ message: e.message, ...e }));
  }
}
/** Show Saga
 *  @description: connects to the showEvent operation
 *  @param {number} action.payload the Event id
 */
export function* show(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));

  const id = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Events.showEvent,
      {
        id,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.showSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.showFail({ message: e.message, ...e }));
  }
}

export function* update(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));

  const { id, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Events.updateEvent,
      { id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.updateSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.updateFail({ message: e.message, ...e }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* eventsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.create, create);
}
export default eventsSaga;
