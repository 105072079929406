import PropTypes from "prop-types";
import React from "react";
import { NavLink, Navigate, Route, Routes } from "react-router-dom";
import { Container, Nav, NavItem } from "reactstrap";
import {
  DashboardCampaigns,
  DashboardHeader,
  DashboardInvestments,
  DashboardPerks,
  DashboardProfile,
} from "../components/Dashboard";
import Page from "../components/layout/Page";
import useAccount from "../hooks/useAccount";
import { useSelector } from "react-redux";

function Dashboard(_props, context) {
  const { currentUser } = useAccount();
  const currentLang = useSelector((state) => state.i18nState.lang);

  if (!currentUser) {
    return <Navigate to={`/${currentLang}/404`} />;
  }

  return (
    <Page title={context.t("Dashboard")}>
      <DashboardHeader />
      <Container>
        <Nav tabs>
          <NavItem>
            <NavLink
              to={`/${currentLang}/dashboard/campaigns`}
              className="nav-link"
            >
              {context.t("Campaigns")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to={`/${currentLang}/dashboard/perks`}
              className="nav-link"
            >
              {context.t("Incentives")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to={`/${currentLang}/dashboard/supports`}
              className="nav-link"
            >
              {context.t("Supports")}
            </NavLink>
          </NavItem>
          <NavItem className="ms-md-auto">
            <NavLink
              to={`/${currentLang}/dashboard/profile`}
              className="nav-link"
            >
              {context.t("Profile")}
            </NavLink>
          </NavItem>
        </Nav>
      </Container>
      <Routes>
        <Route
          index
          element={
            <Navigate
              to={`/${currentLang}/dashboard/campaigns`}
              replace={true}
            />
          }
        />
        <Route path="/campaigns" element={<DashboardCampaigns />} />
        <Route path="/perks" element={<DashboardPerks />} />
        <Route path="/supports" element={<DashboardInvestments />} />
        <Route path="/profile" element={<DashboardProfile />} />
      </Routes>
    </Page>
  );
}

Dashboard.contextTypes = {
  t: PropTypes.func,
};

export default Dashboard;
