import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ArtistShow } from "../components/Artist";
import Page from "../components/layout/Page";
import { getArtistAttributes, showArtist } from "../redux/artists";
import { listEvents } from "../redux/events";

const Artist = (_props, context) => {
  const artistId = useSelector((state) => state.artists.showId);
  const dispatch = useDispatch();
  const { id } = useParams();

  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    registerEffect(dispatch, showArtist({ id }));
  }, []);

  useSsrEffect(() => {
    registerEffect(dispatch, listEvents({ artistId: id }));
  }, []);

  const artistData = useSelector((state) =>
    getArtistAttributes(state, artistId)
  );

  const artistTitle = artistData?.name || context.t("Artist Details");

  return (
    <Page title={artistTitle}>
      <ArtistShow artistId={artistId} />
    </Page>
  );
};

Artist.contextTypes = {
  t: PropTypes.func,
};

export default Artist;
