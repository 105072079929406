/*
Replace
content with your resource Name :) Take care about different writings!
/!\ Content content Contents contents /!\
Keep an eye on pluralized namings in here :)

*/

import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  defaultAddToDictionary,
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
} from "../defaultReducers";

import { defaultInitialState } from "../defaultInitialState";

export const contentsSlice = createSlice({
  name: "contents",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    addToDictionary: defaultAddToDictionary,
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
  },
});

export const {
  show: showContent,
  list: listContents,
} = contentsSlice.actions;
export const actions = contentsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const contents = (state) =>
  state.contents.idsList.map((id) => state.contents.dictionary[id]);
export const content = (state) =>
  state.contents.showId && state.contents.dictionary[state.contents.showId];
export const contentAttributes = (state) => content(state)?.attributes;
export const contentRelationships = (state) => content(state)?.relationships;
export const contentId = (state) => state.contents.showId;

export const getContent = createSelector(
  (state) => state.contents.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getContentAttributes = createSelector(
  (state) => state.contents.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getContentRelationships = createSelector(
  (state) => state.contents.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getContents = createSelector(
  (state) => state.contents.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default contentsSlice.reducer;
