import { getTokenCookies } from "./tokenCookies";

function buildHeaders(tokens) {
  const cookies = getTokenCookies();
  if (tokens && (tokens.accessToken || tokens["access-token"])) {
    return {
      "Content-Type": "application/json",
      "access-token": tokens.accessToken || tokens["access-token"],
      "token-type": "Bearer",
      client: tokens.client,
      uid: tokens.uid,
      Authorization: tokens["authorization"],
    };
  }
  if (cookies) {
    return {
      "Content-Type": "application/json",
      "access-token": cookies["accessToken"] || cookies["access-token"],
      "token-type": "Bearer",
      client: cookies["client"],
      uid: cookies["uid"],
      Authorization: cookies["authorization"],
    };
  }
  return {
    "Content-Type": "application/json",
  };
}

export default buildHeaders;
