import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Page from "../components/layout/Page";
import { getContentAttributes, showContent } from "../redux/contents";

function Content() {
  // publishDate state avoids hydration issue with dates and timezones
  const [publishDate, setPublishDate] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();

  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    registerEffect(dispatch, showContent({ id }));
  }, []);

  const contentData = useSelector((state) => getContentAttributes(state, id));

  useEffect(() => {
    setPublishDate(new Date(contentData?.publish_date)?.toDateString());
  }, [contentData]);

  const body = { __html: contentData?.body };

  return (
    <Page title={contentData?.title} className="pt-4">
      <Container>
        <Row className="mb-4">
          <Col md={{ size: 6, order: 2 }}>
            <div className="ratio ratio-16x9 overflow-hidden">
              {contentData?.header_original?.url && (
                <img
                  src={contentData.header_original.url}
                  className="img-fluid h-100 object-fit-cover"
                  alt={contentData?.title}
                />
              )}
            </div>
          </Col>
          <Col>
            <h1 className="mt-3">{contentData?.title}</h1>
            {publishDate && <p>{publishDate}</p>}
          </Col>
        </Row>
        <div dangerouslySetInnerHTML={body} />
      </Container>
    </Page>
  );
}

Content.contextTypes = {
  t: PropTypes.func,
};

export default Content;
