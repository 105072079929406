import PropTypes from "prop-types";
import React from "react";
import { MdOutlineEvent } from "react-icons/md";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { getEventAttributes } from "../../../redux/events";

const EventCard = ({ eventId }, context) => {
  const eventData = useSelector((state) => getEventAttributes(state, eventId));

  return (
    <Card className="h-100 shadow-sm">
      <CardBody className="d-flex">
        <MdOutlineEvent size={128} className="flex-shrink-0 me-1" />
        <div className="d-flex flex-column justify-content-between w-100">
          <div className="d-flex flex-column">
            <span>
              {eventData?.city}, {eventData?.country}
            </span>
            <strong>{eventData?.location}</strong>
            <span className="mt-3">
              {eventData?.date && new Date(eventData.date).toDateString()}
            </span>
          </div>
          <div className="d-flex justify-content-end">
            {eventData?.ticket_link && (
              <a href={eventData.ticket_link} target="_blank" rel="noreferrer">
                {context.t("Buy Tickets")}
              </a>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

/**  define proptype for the 'translation' function  */
EventCard.contextTypes = {
  t: PropTypes.func,
};

EventCard.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default EventCard;
