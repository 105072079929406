import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { updateAccount } from "../../../redux/accounts";
import useAccount from "../../../hooks/useAccount";

const AccountForm = ({ toggleEditMode }, context) => {
  const dispatch = useDispatch();
  const { currentUser } = useAccount();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(
      updateAccount({
        ...data,
        successCallbackFnc: () => {
          toggleEditMode();
        },
      })
    );
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for="name">{context.t("Name")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="name"
              id="name"
              invalid={errors?.name}
              autoComplete="username"
            />
          )}
          name="name"
          control={control}
          defaultValue={currentUser?.attributes?.name}
        />
      </FormGroup>

      <FormGroup>
        <Label for="email">{context.t("Email")}</Label>
        <Controller
          render={({ field }) => (
            <Input
              {...field}
              onChange={(v) => {
                field.onChange(v);
              }}
              type="email"
              id="email"
              invalid={errors?.email}
              autoComplete="username"
            />
          )}
          name="email"
          control={control}
          defaultValue={currentUser?.attributes?.email}
        />
      </FormGroup>
      <Button type="submit" color="primary" className="w-100 mb-3">
        {context.t("Submit")}
      </Button>
    </Form>
  );
};

AccountForm.contextTypes = {
  t: PropTypes.func,
};

AccountForm.propTypes = {
  toggleEditMode: PropTypes.func.isRequired,
};

export default AccountForm;
