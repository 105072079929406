import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  defaultAddToDictionary,
  defaultCreate,
  defaultCreateFail,
  defaultCreateSuccess,
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateFail,
  defaultUpdateSuccess,
} from "../defaultReducers";

import { defaultInitialState } from "../defaultInitialState";

export const perksSlice = createSlice({
  name: "perks",
  initialState: {
    ...defaultInitialState,
    unlocking: false,
    unlocked: false,
    unlockErrors: null,
  },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    unlock: (state) => {
      state.unlocking = true;
      state.unlocked = false;
      state.unlockErrors = {};
    },
    unlockSuccess: (state, action) => {
      state.unlocking = false;
      state.unlocked = true;
      state.dictionary[action.payload.id] = action.payload;
      state.errorsObject = null;
      state.unlockErrors = {};
    },
    unlockFail: (state, action) => {
      state.unlocking = false;
      state.unlocked = false;
      state.unlockErrors[action.payload.id] = action.payload.error?.attributes;
    },
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
    unlockErrorsReset: (state) => {
      state.unlocking = false;
      state.unlocked = false;
      state.unlockErrors = null;
    },
  },
});

export const {
  show: showPerk,
  update: updatePerk,
  list: listPerks,
  create: createPerk,
  unlock: unlockPerk,
} = perksSlice.actions;
export const actions = perksSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const perks = (state) =>
  state.perks.idsList.map((id) => state.perks.dictionary[id]);
export const perk = (state) =>
  state.perks.showId && state.perks.dictionary[state.perks.showId];
export const perkAttributes = (state) => perk(state)?.attributes;
export const perkRelationships = (state) => perk(state)?.relationships;
export const perkId = (state) => state.perks.showId;

export const getPerk = createSelector(
  (state) => state.perks.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getPerkAttributes = createSelector(
  (state) => state.perks.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getPerkRelationships = createSelector(
  (state) => state.perks.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getPerks = createSelector(
  (state) => state.perks.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default perksSlice.reducer;
