import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Page from "../components/layout/Page";
import useAccount from "../hooks/useAccount";
import { resetPassword } from "../redux/accounts";
import { getEnv } from "../utils/env";

const AccountForgetPassword = (_props, context) => {
  const dispatch = useDispatch();
  const REACT_APP_WEB_SERVER = getEnv("REACT_APP_WEB_SERVER");
  const { currentUser, isPasswordReset, resetPasswordErrors } = useAccount();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(
      resetPassword({
        ...data,
        redirect_url: REACT_APP_WEB_SERVER + "/update-password",
        successCallbackFnc: () => {
          reset();
        },
      })
    );
  };

  const currentLang = useSelector((state) => state.i18nState.lang);

  return (
    <Page title={context.t("Account Forgot Password")} className="pt-4">
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <h1>{context.t("Forgot Password")}</h1>
            {currentUser ? (
              <p>{context.t("Already logged in")}</p>
            ) : (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Label for="email">{context.t("Email")}</Label>
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(v) => {
                          field.onChange(v);
                        }}
                        type="email"
                        id="email"
                        invalid={errors?.email || resetPasswordErrors?.email}
                        autoComplete="username"
                      />
                    )}
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                  />

                  {errors.email?.type === "required" && (
                    <p className="text-danger small mt-1">
                      {context.t("Email")} {context.t("is required")}
                    </p>
                  )}

                  {resetPasswordErrors?.email?.map((error) => (
                    <p className="text-danger small mt-1" key={error}>
                      {error}
                    </p>
                  ))}
                </FormGroup>

                {isPasswordReset && (
                  <p className="text-success small mt-1">
                    {context.t(
                      "An email has been sent to your email address with a link to reset your password"
                    )}
                    .
                  </p>
                )}

                <Button type="submit" color="primary" className="w-100 mb-3">
                  {context.t("Submit")}
                </Button>
                <div className="mb-3">
                  <small>
                    {context.t("Back to")}{" "}
                    <Link to={`/${currentLang}/login`}>
                      {context.t("Login")}
                    </Link>
                  </small>
                </div>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

AccountForgetPassword.contextTypes = {
  t: PropTypes.func,
};

export default AccountForgetPassword;
