import PropTypes from "prop-types";
import React from "react";
import { FaArrowRightLong, FaUpRightFromSquare } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { getContentAttributes } from "../../../redux/contents";
import TalenzzLogo from "../../icons/TalenzzLogo";

const ContentPreview = ({ contentId }, context) => {
  const contentData = useSelector((state) =>
    getContentAttributes(state, contentId)
  );

  const currentLang = useSelector((state) => state.i18nState.lang);

  return (
    <Card className="h-100 rounded-5 overflow-hidden shadow">
      <div className="ratio ratio-16x9 overflow-hidden card-img-top">
        {contentData?.header_original?.url ? (
          <img
            src={contentData.header_original.url}
            className="img-fluid card-img-top h-100 object-fit-cover"
            alt={contentData?.title}
          />
        ) : (
          <div className="img-fluid card-img-top h-100 d-flex align-items-center justify-content-center ">
            <TalenzzLogo width={"50%"} />
          </div>
        )}
      </div>
      <CardBody className="d-flex flex-column">
        <p className="h6">{contentData?.title}</p>
        {contentData?.external_link ? (
          <a
            href={contentData?.external_link}
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary btn-sm mt-auto stretched-link"
          >
            {context.t("Visit")}
            <FaUpRightFromSquare className="ms-2" />
          </a>
        ) : (
          <Link
            to={`/${currentLang}/news/${contentId}`}
            className="btn btn-primary btn-sm mt-auto stretched-link "
          >
            {context.t("Show more")}
            <FaArrowRightLong className="ms-2" />
          </Link>
        )}
      </CardBody>
    </Card>
  );
};

/**  define proptype for the 'translation' function  */
ContentPreview.contextTypes = {
  t: PropTypes.func,
};

ContentPreview.propTypes = {
  contentId: PropTypes.string.isRequired,
};

export default ContentPreview;
