import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { ArtistPreview } from "../components/Artist";
import Page from "../components/layout/Page";
import { listArtists } from "../redux/artists";

const Artists = (_props, context) => {
  const artistIds = useSelector((state) => state.artists.idsList);
  const randomArtistIds =
    useSelector((state) =>
      state.artists.listMeta?.random_artists?.data?.map((artist) => artist.id)
    ) || [];
  const dispatch = useDispatch();
  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    registerEffect(dispatch, listArtists());
  }, []);

  return (
    <Page title={context.t("Artists")}>
      <Container className="py-5">
        <h1 className="display-4 mb-4">{context.t("Meet our Talenzz")}</h1>
        <Row>
          {randomArtistIds.map((artistId) => (
            <Col
              key={artistId}
              md={6}
              style={{ marginBottom: "var(--bs-gutter-x)" }}
            >
              <ArtistPreview artistId={artistId} />
            </Col>
          ))}
        </Row>
        <Row>
          {artistIds.map((artistId) => (
            <>
              {randomArtistIds.includes(artistId) ? null : (
                <Col
                  key={artistId}
                  md={4}
                  style={{ marginBottom: "var(--bs-gutter-x)" }}
                >
                  <ArtistPreview artistId={artistId} />
                </Col>
              )}
            </>
          ))}
        </Row>
      </Container>
    </Page>
  );
};

Artists.contextTypes = {
  t: PropTypes.func,
};

export default Artists;
