import React from "react";
import { Route, Routes } from "react-router-dom";
import { LanguageWrapper } from "./components/LanguageWrapper";
import Footer from "./components/layout/Footer";
import Navigation from "./components/layout/Navigation";
import NewsletterSignup from "./components/layout/NewsletterSignup";
import AccountForgetPassword from "./routes/AccountForgetPassword";
import AccountLogin from "./routes/AccountLogin";
import AccountRegister from "./routes/AccountRegister";
import AccountUpdatePassword from "./routes/AccountUpdatePassword";
import Artist from "./routes/Artist";
import ArtistCreate from "./routes/ArtistCreate";
import ArtistUpdate from "./routes/ArtistUpdate";
import Artists from "./routes/Artists";
import Campaign from "./routes/Campaign";
import Campaigns from "./routes/Campaigns";
import Content from "./routes/Content";
import Dashboard from "./routes/Dashboard";
import Home from "./routes/Home";
import Investment from "./routes/Investment";
import StaticPage from "./routes/StaticPage";
import Styleguide from "./routes/Styleguide";

function App() {
  return (
    <>
      <Navigation />
      <div id="appcontent">
        <Routes>
          <Route path="/" element={<LanguageWrapper />}>
            <Route path="/:lang/" element={<Home />} />
            <Route path="/:lang/campaigns" element={<Campaigns />} />
            <Route path="/:lang/campaigns/:id" element={<Campaign />} />
            <Route path="/:lang/dashboard/*" element={<Dashboard />} />
            <Route path="/:lang/artists/:id" element={<Artist />} />
            <Route path="/:lang/artists" element={<Artists />} />
            <Route path="/:lang/artists/new" element={<ArtistCreate />} />
            <Route path="/:lang/artists/:id/edit" element={<ArtistUpdate />} />
            <Route path="/:lang/login" element={<AccountLogin />} />
            <Route
              path="/:lang/reset-password"
              element={<AccountForgetPassword />}
            />
            <Route
              path="/:lang/update-password"
              element={<AccountUpdatePassword />}
            />
            <Route path="/:lang/register" element={<AccountRegister />} />
            <Route
              path="/:lang/investments/:id/:status"
              element={<Investment />}
            />
            <Route path="/:lang/p/:slug" element={<StaticPage />} />
            <Route path="/:lang/news/:id" element={<Content />} />
            {/* TODO REMOVE BEFORE PRODUCTION */}
            <Route path="styleguide" element={<Styleguide />} />
          </Route>
        </Routes>
      </div>
      <NewsletterSignup />
      <Footer />
    </>
  );
}

export default App;
