import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { getCampaignRelationships } from "../../../redux/campaigns";
import { PerkCard } from "../../Perk";
import { Col, Row } from "reactstrap";

const CampaignPerks = ({ campaignId }, context) => {
  const campaignRelationships = useSelector((state) =>
    getCampaignRelationships(state, campaignId)
  );

  return (
    <div className="my-5">
      <h2 className="mb-4">{context.t("Incentives")}</h2>
      <Row>
        {campaignRelationships?.perks?.data?.map((perk) => (
          <Col
            key={perk.id}
            md={6}
            xl={3}
            style={{ marginBottom: "var(--bs-gutter-x)" }}
          >
            <PerkCard perkId={perk.id} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
CampaignPerks.contextTypes = {
  t: PropTypes.func,
};

CampaignPerks.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

export default CampaignPerks;
