import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { listEvents } from "../../../redux/events";
import { EventCard } from "../../Event";
import { useDispatch } from "react-redux";

const ArtistEvents = ({ artistId }, context) => {
  const dispatch = useDispatch();
  const eventPage = useSelector((state) => state.events.listPage);
  const artistEventIds = useSelector((state) => state.events.idsList);
  const eventTotalPages = useSelector((state) => state.events.totalPages);

  const handleClick = () => {
    dispatch(
      listEvents({
        artistId,
        endlessScroll: true,
        page: eventPage + 1,
      })
    );
  };

  return (
    <>
      <h2 className="mb-4">{context.t("On Tour")}</h2>
      <Row>
        {artistEventIds?.map((eventId) => (
          <Col
            key={eventId}
            md={4}
            style={{ marginBottom: "var(--bs-gutter-x)" }}
          >
            <EventCard eventId={eventId} />
          </Col>
        ))}
      </Row>
      <div className="d-flex justify-content-center">
        <Button
          hidden={eventPage >= eventTotalPages}
          type="button"
          onClick={handleClick}
          color="tertiary"
          className="btn-lg"
        >
          {context.t("Load More")}
        </Button>
      </div>
    </>
  );
};

ArtistEvents.contextTypes = {
  t: PropTypes.func,
};

ArtistEvents.propTypes = {
  artistId: PropTypes.string.isRequired,
};

export default ArtistEvents;
