import { createSlice } from "@reduxjs/toolkit";

export const tokensSlice = createSlice({
  name: "tokens",
  initialState: {
    "token-type": null,
    uid: null,
    _session_id: null,
    "access-token": null,
    client: null,
    expiry: null,
    authorization: null,
  },
  reducers: {
    setTokens: (state, action) => {
      state["token-type"] = action.payload["token-type"];
      state.uid = action.payload.uid;
      state._session_id = action.payload._session_id;
      state["access-token"] = action.payload["access-token"];
      state.client = action.payload.client;
      state.expiry = action.payload.expiry;
      state.authorization = action.payload.authorization;
    },
  },
});

export const { setTokens } = tokensSlice.actions;
export const actions = tokensSlice.actions;

export default tokensSlice.reducer;
