/**
 * Local Storage utils
 * - functions to load and save the persisted local storage
 */

/**
 * getter for the store state
 */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null || serializedState === "{}") {
      if (window && window.REDUX_DATA) return window.REDUX_DATA;
      else return {};
    }
    return JSON.parse(serializedState);
    //   if (window && window.REDUX_DATA) {
    //     console.log("preloaded_state", window.REDUX_DATA);
    //     return window.REDUX_DATA;
    //   }
    //   console.log("preloaded_state was empty");
    //   return {};
  } catch (err) {
    return {};
  }
};

/** setter for the store state */
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignore
  }
};
