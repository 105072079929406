export const defaultInitialState = {
  idsList: [],
  listPage: 1,
  listTotalCount: 0,
  totalPages: 0,
  loadingList: false,
  loadedList: false,
  listError: null,
  showId: null,
  loadingShow: false,
  loadedShow: false,
  showError: null,
  creating: false,
  created: false,
  createError: null,
  createSuccess: null,
  errorsObject: null,
  dictionary: {},
};
