import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { getArtistAttributes } from "../../../redux/artists";
import {
  getCampaignAttributes,
  getCampaignRelationships,
} from "../../../redux/campaigns";
import { createInvestment } from "../../../redux/investments";
import { ArtistSocials } from "../../Artist";
import { FaqList } from "../../Faq";
import { ShareButtons } from "../../ShareButtons";
import CampaignDocuments from "../CampaignDocuments/CampaignDocuments";
import CampaignInvest from "../CampaignInvest";
import CampaignPerks from "../CampaignPerks";
import CampaignProgress from "../CampaignProgress";

/**
 * Table container used as app default landing page
 */
const CampaignShow = ({ campaignId }, context) => {
  const dispatch = useDispatch();
  const campaignData = useSelector((state) =>
    getCampaignAttributes(state, campaignId)
  );

  const campaignRelationships = useSelector((state) =>
    getCampaignRelationships(state, campaignId)
  );

  const artist = useSelector((state) =>
    getArtistAttributes(state, campaignRelationships?.artist?.data?.id)
  );

  const handleInvestClick = (amount, tos, dataPrivacy) => {
    dispatch(
      createInvestment({
        campaign_id: campaignId,
        shares_amount: amount,
        tos,
        data_privacy: dataPrivacy,
        successCallbackFnc: (investment) => {
          if (typeof window !== "undefined") {
            window.open(
              investment?.attributes?.payment_links?.general,
              "_self"
            );
          }
        },
      })
    );
  };

  const artistAnySocialLinks =
    artist?.spotify_url ||
    artist?.facebook_url ||
    artist?.instagram_url ||
    artist?.tiktok_url;

  return (
    <div className="mb-3">
      <div className="campaignshow__hero">
        <div className="ratio ratio-21x9 card-img-top overflow-hidden">
          <img
            src={artist?.header_original?.url}
            className="img-fluid w-100 h-100 object-fit-cover"
            alt="Artist"
          />
        </div>
        <div className="campaignshow__campaigninvest">
          <Container>
            <Row>
              <Col xs="12" lg={{ size: 4, offset: 8 }}>
                <CampaignInvest
                  campaignId={campaignId}
                  investAction={handleInvestClick}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container>
        <Row>
          <Col xs="12" lg="7" className="mb-3">
            <Row className="mb-3">
              {campaignData?.price && (
                <Col xs="12" md="6">
                  <div className="d-flex align-items-center flex-wrap">
                    <span>{context.t("Campaign Goal")}:&nbsp;</span>
                    <span className="fw-bold">
                      {campaignData.price * 1000}€
                    </span>
                  </div>
                </Col>
              )}
              {campaignData?.campaign_type && (
                <Col xs="12" md="6">
                  <div className="d-flex align-items-center flex-wrap justify-content-md-end">
                    <span>{context.t("Campaign Type")}:&nbsp;</span>
                    <span className="fw-bold">
                      {campaignData.campaign_type}
                    </span>
                  </div>
                </Col>
              )}
            </Row>
            <div className="me-4 mx-sm-0">
              <CampaignProgress campaignId={campaignId} />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="12" lg="7" className="fw-bold">
            {campaignData?.subtitle}
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg={{ size: 5, order: 2 }}>
            <div className="d-flex flex-column align-items-end">
              <p className="h6">{context.t("Share this campaign")}</p>
              <ShareButtons
                fbPost
                tweet={campaignData?.title}
                outline
                className="mb-3 d-flex flex-lg-column align-items-end "
              />
            </div>
          </Col>
          <Col xs="12" lg="7">
            <div style={{ whiteSpace: "pre-wrap" }}>
              {campaignData?.description}
            </div>
          </Col>
        </Row>

        <Row xs="2" lg="4" className="mt-3">
          <Col>
            <h6 className="fw-bold">{context.t("Support Period")}</h6>
            <p>
              {campaignData?.start_date && (
                <span> {campaignData.start_date} - </span>
              )}{" "}
              {campaignData?.end_date && <span>{campaignData?.end_date}</span>}
            </p>
          </Col>
          <Col>
            <h6 className="fw-bold">{context.t("Release Date")}</h6>
            <p>{campaignData?.publish_date}</p>
          </Col>
          {artistAnySocialLinks && (
            <Col>
              <h6 className="fw-bold">{context.t("Website & Social Media")}</h6>
              <div className="d-flex gap-3">
                <ArtistSocials
                  artistId={campaignRelationships?.artist?.data?.id}
                />
              </div>
            </Col>
          )}
        </Row>

        <Row xs="2" lg="4" className="mt-3">
          <Col>
            <h6 className="fw-bold">{context.t("Location")}</h6>
            <p>{artist?.location}</p>
          </Col>
          <Col>
            <h6 className="fw-bold">{context.t("Genre")}</h6>
            <p>{artist?.genre}</p>
          </Col>
        </Row>

        <CampaignPerks campaignId={campaignId} />

        <CampaignDocuments campaignId={campaignId} />
      </Container>
      <FaqList />
    </div>
  );
};

/**  define proptype for the 'translation' function  */
CampaignShow.contextTypes = {
  t: PropTypes.func,
};

CampaignShow.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

/** export the component as redux connected component */
export default CampaignShow;
