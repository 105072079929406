import PropTypes from "prop-types";
import React from "react";
import { MdBlock } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, CardText, CardTitle, Col } from "reactstrap";

const Forbidden = (_props, context) => {
  const currentLang = useSelector((state) => state.i18nState.lang);
  return (
    <Col xs="12" md="8" lg="6">
      <Card body color="tertiary">
        <CardBody className="d-flex flex-column">
          <CardTitle tag="h1" className="mx-auto mb-3">
            <MdBlock /> {context.t("Forbidden")}
          </CardTitle>
          <CardText>
            <p>
              {context.t(
                "Sorry, but you do not have permission to access this page"
              )}
              .
            </p>
            <p>
              <Link to={`/${currentLang}`} className="link-info">
                {context.t("Click here")}
              </Link>{" "}
              {context.t("to navigate back to home page")}
            </p>
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

Forbidden.contextTypes = {
  t: PropTypes.func,
};

export default Forbidden;
