import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import useAccount from "../../../hooks/useAccount";
import { showUser } from "../../../redux/accounts";
import { AccountForm } from "../../Account";
import { ArtistPreview } from "../../Artist";

const DashboardProfile = (props, context) => {
  const currentUserId = useSelector((state) => state.accounts.currentUser.id);
  const { currentUser } = useAccount();
  const currentUserData = currentUser?.attributes;
  const currentUserRelationships = currentUser?.relationships;
  const dispatch = useDispatch();
  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    if (currentUserId)
      registerEffect(dispatch, showUser({ id: currentUserId }));
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const togglePayoutModal = () => setShowPayoutModal(!showPayoutModal);

  const currentLang = useSelector((state) => state.i18nState.lang);

  return (
    <Container className="mt-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>
          {isEditMode ? context.t("Edit Profile") : context.t("Your Profile")}
        </h2>
        <span>
          <Button color="primary" outline onClick={toggleEditMode}>
            {isEditMode ? context.t("Cancel") : context.t("Edit")}
          </Button>
        </span>
      </div>
      {isEditMode ? (
        <AccountForm toggleEditMode={toggleEditMode} />
      ) : (
        <>
          <p>
            {context.t("Name")}: {currentUserData?.name}
          </p>
          <p>
            {context.t("Email")}: {currentUserData?.email}
          </p>
        </>
      )}
      <section className="my-5">
        <div className="d-flex justify-content-between flex-wrap mb-4">
          <h2>
            {currentUserRelationships?.artists?.data?.length === 0
              ? context.t("Are you an Artist? Click here to apply")
              : context.t("Artist Profiles")}
          </h2>
          <span>
            <Link
              to={`/${currentLang}/artists/new`}
              className="btn btn-outline-primary text-nowrap"
            >
              {context.t("Apply as Talenzz Artist")}
            </Link>
          </span>
        </div>
        {currentUserRelationships?.artists?.data?.length > 0 && (
          <>
            <Row>
              {currentUserRelationships?.artists?.data?.map((artist) => (
                <Col
                  key={artist.id}
                  md={4}
                  style={{ marginBottom: "var(--bs-gutter-x)" }}
                >
                  <ArtistPreview artistId={artist.id} toEditRoute />
                </Col>
              ))}
            </Row>
            <div className="d-flex align-items-center justify-content-center">
              <div className="h5 mb-0">
                {context.t("Do you want to create a Campaign?")}
              </div>
              <a
                href={context.t("application.form.url")}
                target="_blank"
                rel="noreferrer"
                className="ms-4 btn btn-outline-primary text-nowrap"
              >
                {context.t("Click here to apply")}
              </a>
            </div>
          </>
        )}
      </section>
      <section className="my-5">
        <Container className="text-center">
          <Button
            color="primary"
            className="px-5"
            outline
            onClick={togglePayoutModal}
          >
            {context.t("Payout")}
          </Button>
          <Modal toggle={togglePayoutModal} isOpen={showPayoutModal} centered>
            <ModalHeader toggle={togglePayoutModal}>
              {context.t("Payout")}
            </ModalHeader>
            <ModalBody>
              <div className="text-center my-5">
                <p>
                  {context.t(
                    "talenzz pays out the streaming revenues every six months, once in June and once in December."
                  )}
                </p>
                <p>
                  {context.t(
                    "If you would like a payout before then, please contact"
                  )}
                </p>
                <a
                  href="mailto:support@talenzz.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@talenzz.io
                </a>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </section>
    </Container>
  );
};

/**  define proptype for the 'translation' function  */
DashboardProfile.contextTypes = {
  t: PropTypes.func,
};

DashboardProfile.propTypes = {};

export default DashboardProfile;
