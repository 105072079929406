import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getArtistAttributes } from "../../../redux/artists";
import {
  getCampaignAttributes,
  getCampaignRelationships,
} from "../../../redux/campaigns";
import CampaignProgress from "../CampaignProgress";

/**
 * Table container used as app default landing page
 */
const CampaignPreview = ({ campaignId }, context) => {
  const campaignData = useSelector((state) =>
    getCampaignAttributes(state, campaignId)
  );

  const campaignRelationships = useSelector((state) =>
    getCampaignRelationships(state, campaignId)
  );

  const artist = useSelector((state) =>
    getArtistAttributes(state, campaignRelationships?.artist?.data?.id)
  );

  const currentLang = useSelector((state) => state.i18nState.lang);

  if (!campaignData) {
    return null;
  }

  return (
    <Card
      className="rounded-5 overflow-hidden shadow"
      style={{ marginBottom: "var(--bs-gutter-x)" }}
    >
      <Row className="g-0">
        <Col lg="4">
          {campaignData?.header_original?.url && (
            <div className="ratio ratio-1x1 overflow-hidden">
              <img
                src={campaignData.header_original.url}
                style={{ objectFit: "cover" }}
              />
            </div>
          )}
        </Col>
        <Col lg="8">
          <CardBody className="d-flex flex-column h-100">
            <p className="h4">{campaignData?.title}</p>
            {artist && (
              <div className="d-flex align-items-center mb-2">
                {artist.logo_original?.url && (
                  <span
                    className="rounded-circle overflow-hidden d-inline-block me-3"
                    style={{ height: "3rem", width: "3rem" }}
                  >
                    <img
                      src={artist.logo_original.url}
                      style={{ objectFit: "cover" }}
                      className="w-100 h-100"
                    />
                  </span>
                )}
                <span>
                  {context.t("by")}{" "}
                  <Link
                    to={`/${currentLang}/artists/${campaignRelationships?.artist?.data?.id}`}
                    className="text-reset text-decoration-none fw-bold"
                  >
                    {artist.name}
                  </Link>
                </span>
              </div>
            )}
            <div className="d-flex flex-wrap justify-content-between my-auto">
              <div className="me-4 mb-3">
                <div className="small text-muted fw-light">
                  {context.t("Tokens sold")}
                </div>
                <div className="fs-3 fw-bold">
                  {campaignData.shares_count || "0"}/{campaignData.shares_limit}
                </div>
              </div>
              <div className="me-4 mb-3">
                <div className="small text-muted fw-light">
                  {context.t("Price")}
                </div>
                <div className="fs-3 fw-bold">
                  {campaignData.price || "0"} €
                </div>
              </div>
              <div className="me-4 mb-3">
                <div className="small text-muted fw-light">
                  {context.t("Share")}
                </div>
                <div className="fs-3 fw-bold">
                  {campaignData.revenue_share || "0"} %
                </div>
              </div>
              {campaignData.campaign_type && (
                <div className="me-4 mb-3">
                  <div className="small text-muted fw-light">
                    {context.t("Campaign Type")}
                  </div>
                  <div className="fs-3 fw-bold">
                    {campaignData.campaign_type}
                  </div>
                </div>
              )}
            </div>
            <div className="my-auto me-3">
              <CampaignProgress campaignId={campaignId} />
            </div>
            <div className="d-flex">
              {campaignData?.current_user_shares ? (
                <div className="me-4 d-flex align-items-center">
                  <span className="small text-muted fw-light">
                    {context.t("Shares owned")}:&nbsp;
                  </span>
                  <span className="fs-3 fw-bold">
                    {campaignData.current_user_shares}
                  </span>
                </div>
              ) : null}
              <div className=" ms-auto mt-auto">
                <Link
                  to={`/${currentLang}/campaigns/${campaignId}`}
                  className="btn btn-primary btn-lg"
                >
                  {context.t("View Campaign")}
                </Link>
              </div>
            </div>
          </CardBody>
        </Col>
      </Row>
    </Card>
  );
};

/**  define proptype for the 'translation' function  */
CampaignPreview.contextTypes = {
  t: PropTypes.func,
};

CampaignPreview.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

/** export the component as redux connected component */
export default CampaignPreview;
