/** ----- tokenCookies.js -----
 *  File: tokenCookies.js
 *
 *  Purpose: handling cookies regarding user tokens from api
 *
 *-----------------------------*/
import Cookies from "universal-cookie";
import { getEnv } from "./env";

let cookieHandler = null;

export function initCookies(headers = null) {
  cookieHandler = new Cookies(headers, { path: "/" });
}

export function setTokenCookies(headers) {
  if (!cookieHandler) initCookies();
  const cookieOptions = {
    sameSite: true,
    path: "/",
  };
  if (headers["access-token"])
    cookieHandler.set("access-token", headers["access-token"], cookieOptions);
  if (headers["client"])
    cookieHandler.set("client", headers["client"], cookieOptions);
  if (headers["expiry"])
    cookieHandler.set("expiry", headers["expiry"], cookieOptions);
  if (headers["token-type"])
    cookieHandler.set("token-type", headers["token-type"], cookieOptions);
  if (headers["uid"]) cookieHandler.set("uid", headers["uid"], cookieOptions);
  if (headers["authorization"])
    cookieHandler.set("authorization", headers["authorization"], cookieOptions);
}

export function getTokenCookies() {
  if (!cookieHandler) initCookies();
  const accessToken = cookieHandler.get("access-token");
  const refreshToken = cookieHandler.get("refresh-token");
  const authorization = cookieHandler.get("authorization");
  const client = cookieHandler.get("client");
  const uid = cookieHandler.get("uid", { doNotParse: true });
  return {
    accessToken:
      accessToken && accessToken !== "undefined" ? accessToken : undefined,
    refreshToken:
      refreshToken && refreshToken !== "undefined" ? refreshToken : undefined,
    authorization:
      authorization && authorization !== "undefined"
        ? authorization
        : undefined,
    client: client && client !== "undefined" ? client : undefined,
    uid: uid && uid !== "undefined" ? uid : undefined,
  };
}

export function deleteTokenCookies() {
  const REACT_APP_WEB_SERVER = getEnv("REACT_APP_WEB_SERVER");

  if (!cookieHandler) initCookies();
  cookieHandler.remove("access-token", { path: "/" });
  cookieHandler.remove("refresh-token", { path: "/" });
  cookieHandler.remove("client", { path: "/" });
  cookieHandler.remove("expiry", { path: "/" });
  cookieHandler.remove("token-type", { path: "/" });
  cookieHandler.remove("uid", { path: "/" });
  cookieHandler.remove("authorization", { path: "/" });

  // remove legacy cookies
  cookieHandler.remove("access-token", {
    path: "/",
    domain: `.${(REACT_APP_WEB_SERVER || "").replace(/^https?:\/\//, "")}`,
    sameSite: "strict",
  });
}

/** exports all actions as default */
export default {
  initCookies,
  setTokenCookies,
  getTokenCookies,
  deleteTokenCookies,
};
