import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { getCampaignAttributes } from "../../../redux/campaigns";

const CampaignProgress = ({ campaignId, tmpAmount = 0 }) => {
  const campaignData = useSelector((state) =>
    getCampaignAttributes(state, campaignId)
  );

  const progressPercentage = (campaignData?.shares_count + tmpAmount) / 10;

  const tiers = campaignData?.tiers?.map((tier) => ({
    label: tier?.tier_type,
    value: tier?.shares_limit / 10,
  }));

  return (
    <div className="campaign-progress">
      <div className="campaign-progress__container">
        <div className="campaign-progress__chart-container">
          <div className="campaign-progress__line-container">
            <div className="campaign-progress__line"></div>
            <div
              className="campaign-progress__line campaign-progress__line--left"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>

          <div className="campaign-progress__break-container">
            {tiers?.map((tier) => (
              <div
                key={tier?.value}
                className={`campaign-progress__tiers campaign-progress__tier--${tier?.value}`}
              >
                <div
                  className={`campaign-progress__break ${
                    progressPercentage >= tier?.value
                      ? "campaign-progress__break--completed"
                      : `campaign-progress__tier--${tier?.value}__break--colored`
                  } ${tier?.value === 100 ? "d-none" : ""}`}
                ></div>
              </div>
            ))}
          </div>
        </div>

        <div className="campaign-progress__label-container">
          <div className="campaign-progress__tiers campaign-progress__tier--0">
            <div className="campaign-progress__label campaign-progress__label--colored">
              0€
            </div>
          </div>
          {tiers?.map((tier) => (
            <div
              key={tier?.value}
              className={`campaign-progress__tiers campaign-progress__tier--${tier?.value}`}
            >
              <div
                className={`campaign-progress__label ${
                  progressPercentage >= tier?.value
                    ? "campaign-progress__label--colored"
                    : ""
                }`}
              >
                {tier?.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CampaignProgress.contextTypes = {
  t: PropTypes.func,
};

CampaignProgress.propTypes = {
  campaignId: PropTypes.string.isRequired,
  tmpAmount: PropTypes.number,
};

export default CampaignProgress;
