import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from "reactstrap";
import { CampaignPreview } from "../components/Campaign";
import Page from "../components/layout/Page";
import { listCampaigns } from "../redux/campaigns";

function Campaigns(_props, context) {
  const page = useSelector((state) => state.campaigns.listPage);
  const campaignIds = useSelector((state) => state.campaigns.idsList);
  const campaignTotalPages = useSelector((state) => state.campaigns.totalPages);
  const dispatch = useDispatch();
  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    registerEffect(
      dispatch,
      listCampaigns({
        endlessScroll: true,
      })
    );
  }, []);

  const handleClick = () => {
    dispatch(
      listCampaigns({
        endlessScroll: true,
        page: page + 1,
      })
    );
  };

  return (
    <Page title={context.t("Campaigns")}>
      <Container className="py-4">
        <h1 className="display-4 mb-4">{context.t("See our Campaigns")}</h1>
        {campaignIds.map((campaignId) => (
          <div key={campaignId}>
            <CampaignPreview campaignId={campaignId} />
          </div>
        ))}

        <div className="d-flex justify-content-center">
          <Button
            hidden={page >= campaignTotalPages}
            type="button"
            onClick={handleClick}
            color="tertiary"
            className="btn-lg"
          >
            {context.t("Load More")}
          </Button>
        </div>
      </Container>
    </Page>
  );
}

Campaigns.contextTypes = {
  t: PropTypes.func,
};

export default Campaigns;
