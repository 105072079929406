import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { setLanguage } from "redux-i18n";
import Cookies from "universal-cookie";

const LanguageWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const supportedLanguages = ["en", "de"];
  const currentLang = useSelector((state) => state.i18nState.lang);
  const { lang } = useParams();
  const cookieHandler = new Cookies();

  const cookieLang = supportedLanguages.includes(cookieHandler.get("lang"))
    ? cookieHandler.get("lang")
    : null;

  useEffect(() => {
    if (lang === currentLang) {
      if (lang !== cookieLang) {
        cookieHandler.set("lang", lang);
      }
      return;
    }

    if (lang) {
      if (supportedLanguages.includes(lang)) {
        console.log("runs");
        dispatch(setLanguage(lang));
        cookieHandler.set("lang", lang);
      } else {
        const newPathname = location.pathname?.replace(
          `/${lang}`,
          `/${cookieLang || currentLang}`
        );
        navigate(newPathname, { replace: true });
      }
    } else {
      navigate(`/${cookieLang || currentLang}`, { replace: true });
    }
  }, [lang]);

  return <Outlet />;
};

/**  define proptype for the 'translation' function  */
LanguageWrapper.contextTypes = {
  t: PropTypes.func,
};

LanguageWrapper.propTypes = {};

export default LanguageWrapper;
