/**
 * The ScrollToTop component
 */
import { useEffect } from "react";
import { isServer } from "../../../redux/store";

/**
 * React component to scroll to the top when navigating to a new route
 */
export default function ScrollToTopOnMount() {
  useEffect(() => {
    if (!isServer) {
      const scrollOptions = {
        left: 0,
        top: 0,
        behavior: "auto",
      };
      if (window) window.scrollTo(scrollOptions);
    }
  }, []);

  return null;
}
