import PropTypes from "prop-types";
import React, { useState } from "react";
import { BsDashCircle, BsPlusCircle } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import useAccount from "../../../hooks/useAccount";
import { getArtistAttributes } from "../../../redux/artists";
import {
  getCampaignAttributes,
  getCampaignRelationships,
} from "../../../redux/campaigns";
import CampaignProgress from "../CampaignProgress";

const CampaignInvest = ({ campaignId, investAction }, context) => {
  const { currentUser } = useAccount();
  const [modal, setModal] = useState(false);
  const [tokenCounter, setTokenCounter] = useState(1);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [dataPrivacyAccepted, setDataPrivacyAccepted] = useState(false);

  const toggle = () => setModal(!modal);
  const campaignData = useSelector((state) =>
    getCampaignAttributes(state, campaignId)
  );
  const decrement = () => {
    if (tokenCounter > 1) {
      setTokenCounter(tokenCounter - 1);
    }
  };
  const maxAmount = campaignData
    ? campaignData.shares_limit - campaignData?.shares_count
    : 1000;
  const increment = () => {
    if (tokenCounter < maxAmount) {
      setTokenCounter(tokenCounter + 1);
    }
  };
  const campaignRelationships = useSelector((state) =>
    getCampaignRelationships(state, campaignId)
  );

  const artistData = useSelector((state) =>
    getArtistAttributes(state, campaignRelationships?.artist?.data?.id)
  );

  const currentLang = useSelector((state) => state.i18nState.lang);

  return (
    <>
      <Card
        color="light"
        className="shadow border-0 rounded-4 overflow-hidden "
      >
        <div className="ratio ratio-1x1 card-img-top overflow-hidden">
          <img
            src={campaignData?.header_original?.url}
            className="img-fluid w-100 h-100 object-fit-cover"
            alt="Campaign"
          />
        </div>
        <CardBody>
          <CardTitle className="h5 mb-0">{campaignData?.title}</CardTitle>
          <span className="text-muted small">
            {context.t("by")}{" "}
            <Link
              to={`/${currentLang}/artists/${campaignRelationships?.artist?.data?.id}`}
              className="text-reset text-decoration-none fw-bold"
            >
              {artistData?.name}
            </Link>
          </span>
          <CardText>
            <Row>
              <Col xs="6">
                <label className="text-muted" style={{ fontSize: "11px" }}>
                  {context.t("Price per Share")}
                </label>
                <p>{campaignData?.price}€</p>
              </Col>
              <Col xs="6">
                <label className="text-muted" style={{ fontSize: "11px" }}>
                  {context.t("Share size")}
                </label>
                <p>{campaignData?.revenue_share}%</p>
              </Col>
              <Col xs="6">
                <div>
                  <label className="text-muted" style={{ fontSize: "11px" }}>
                    {context.t("Shares owned")}
                  </label>
                  <p>{campaignData?.current_user_shares || "0"}</p>
                </div>
              </Col>
              <Col xs="6">
                <label className="text-muted" style={{ fontSize: "11px" }}>
                  {context.t("Shares Available")}
                </label>
                <p>{campaignData?.shares_limit - campaignData?.shares_count}</p>
              </Col>
            </Row>
            {campaignData?.shares_limit === campaignData?.shares_count ? (
              <p className="small text-success">
                {context.t("Campaign goal successfully achieved!")}
              </p>
            ) : (
              <>
                {currentUser ? (
                  <Button block color="primary" type="button" onClick={toggle}>
                    {context.t("Support")}
                  </Button>
                ) : (
                  <Link
                    to={`/${currentLang}/login`}
                    className="btn btn-primary w-100"
                  >
                    {context.t("Login/Register to Support")}
                  </Link>
                )}
              </>
            )}
          </CardText>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle} fullscreen="sm" centered>
        <ModalHeader toggle={toggle}>{campaignData?.title}</ModalHeader>
        <ModalBody>
          <p className="fw-bold text-center">
            {context.t("How many shares would you like to buy?")}
          </p>
          <div className="px-3 mb-4">
            <CampaignProgress
              campaignId={campaignId}
              tmpAmount={tokenCounter}
            />
          </div>
          <Row>
            <Col
              md={{ size: 8, offset: 2 }}
              className="d-flex align-items-center justify-content-center mb-3"
            >
              <Button
                className="mx-2"
                onClick={decrement}
                outline
                color="primary"
                size="lg"
                disabled={tokenCounter <= 1}
              >
                <BsDashCircle size={20} />
              </Button>
              <Input
                type="number"
                value={tokenCounter}
                onChange={(e) => {
                  if (e.target.value > maxAmount) {
                    setTokenCounter(maxAmount);
                  } else {
                    setTokenCounter(parseInt(e.target.value));
                  }
                }}
                on
                size="lg"
                className="text-center"
                min={0}
                max={maxAmount}
              />
              <Button
                className="mx-2"
                color="primary"
                onClick={increment}
                size="lg"
                outline
                disabled={tokenCounter >= maxAmount}
              >
                <BsPlusCircle size={20} />
              </Button>
            </Col>
          </Row>
          <p className="text-center">
            {(campaignData?.price * tokenCounter).toFixed(2)}€
          </p>

          <div className="d-flex flex-column align-items-center">
            <div>
              <FormGroup check>
                <Input
                  onChange={() => setTosAccepted(!tosAccepted)}
                  checked={tosAccepted}
                  type="checkbox"
                  id="tos"
                />
                <Label
                  className="small"
                  for="tos"
                  dangerouslySetInnerHTML={{
                    __html: context.t("I have read and accepted the {link}.", {
                      link: `<a href="/${currentLang}/p/${context.t(
                        "slug.tos"
                      )}" target="_blank" rel="noreferrer">${context.t(
                        "Terms of Service"
                      )}</a>`,
                    }),
                  }}
                />
              </FormGroup>

              <FormGroup check>
                <Input
                  onChange={() => setDataPrivacyAccepted(!dataPrivacyAccepted)}
                  checked={dataPrivacyAccepted}
                  type="checkbox"
                  id="data_privacy"
                />
                <Label
                  className="small"
                  for="data_privacy"
                  dangerouslySetInnerHTML={{
                    __html: context.t("I have read and accepted the {link}.", {
                      link: `<a href="/${currentLang}/p/${context.t(
                        "slug.dataprotection"
                      )}" target="_blank" rel="noreferrer">${context.t(
                        "Data Privacy Policy"
                      )}</a>`,
                    }),
                  }}
                />
              </FormGroup>
              <div className="text-center">
                <a
                  href={`/${currentLang}/p/${context.t("slug.disclaimer")}`}
                  target="_blank"
                  rel="noreferrer"
                  className="small"
                >
                  {context.t("Disclaimer")}
                </a>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={!tosAccepted || !dataPrivacyAccepted}
            onClick={() =>
              investAction(tokenCounter, tosAccepted, dataPrivacyAccepted)
            }
            className="w-100"
          >
            {context.t("Continue to payment provider")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

/**  define proptype for the 'translation' function  */
CampaignInvest.contextTypes = {
  t: PropTypes.func,
};

CampaignInvest.propTypes = {
  campaignId: PropTypes.string.isRequired,
  investAction: PropTypes.func.isRequired,
};

export default CampaignInvest;
