import PropTypes from "prop-types";
import React from "react";
import { BsFacebook, BsGoogle } from "react-icons/bs";
import { getEnv } from "../../utils/env";
import { useSelector } from "react-redux";

const SocialLoginLink = ({ provider, name }, context) => {
  const REACT_APP_API_SERVER = getEnv("REACT_APP_API_SERVER");
  const REACT_APP_WEB_SERVER = getEnv("REACT_APP_WEB_SERVER");
  const currentLang = useSelector((state) => state.i18nState.lang);

  const renderIcon = () => {
    switch (provider) {
      case "google_oauth2":
        return <BsGoogle alt={name} />;
      case "facebook":
        return <BsFacebook alt={name} />;
      default:
        return null;
    }
  };

  return (
    <a
      href={`${REACT_APP_API_SERVER}/auth/${provider}?auth_origin_url=${REACT_APP_WEB_SERVER}/${
        currentLang || "en"
      }/login`}
      className={"btn btn-outline-dark w-100"}
    >
      {context.t("Login with")}&nbsp;
      {renderIcon()}
    </a>
  );
};

SocialLoginLink.contextTypes = {
  t: PropTypes.func,
};

SocialLoginLink.propTypes = {
  provider: PropTypes.oneOf(["google_oauth2", "facebook"]).isRequired,
  name: PropTypes.string.isRequired,
};

export default SocialLoginLink;
