/**
 * The root saga function. This function is used to register all sagas watching the store
 */
import { all } from "redux-saga/effects";
import dictionarySaga from "./dictionarySagas";
import accountsSaga from "./accounts/sagas";
import artistsSaga from "./artists/sagas";
import campaignsSaga from "./campaigns/sagas";
import contentsSaga from "./contents/sagas";
import eventsSaga from "./events/sagas";
import faqsSaga from "./faqs/sagas";
import investmentsSaga from "./investments/sagas";
import perksSaga from "./perks/sagas";
import releasesSaga from "./releases/sagas";
import staticPagesSaga from "./staticPages/sagas";

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports

/** exports the rootSaga for the store */
export default function* rootSaga() {
  yield all([
    dictionarySaga(),
    accountsSaga(),
    artistsSaga(),
    campaignsSaga(),
    contentsSaga(),
    eventsSaga(),
    faqsSaga(),
    investmentsSaga(),
    perksSaga(),
    releasesSaga(),
    staticPagesSaga(),
  ]);
}
