import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import SocialLoginLink from "../components/SocialLoginLink";
import Page from "../components/layout/Page";
import useAccount from "../hooks/useAccount";
import { loginAccount, validateToken } from "../redux/accounts";

const AccountLogin = (_props, context) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("auth_token");
  const client = searchParams.get("client_id");
  const uid = searchParams.get("uid");
  const { currentUser, loginErrors } = useAccount();

  const redirectOnSuccess = (user) =>
    user?.relationships?.invested_campaigns?.data?.length
      ? navigate("/dashboard")
      : navigate("/");

  const needsValidation = !currentUser && accessToken && client && uid;

  const handleValidateToken = () => {
    dispatch(
      validateToken({
        accessToken,
        client,
        uid,
        successCallbackFnc: redirectOnSuccess,
      })
    );
  };

  useEffect(() => {
    if (needsValidation) handleValidateToken();
  }, [currentUser, accessToken, client, uid]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(
      loginAccount({
        email: data.email,
        password: data.password,
        successCallbackFnc: (user) => {
          reset();
          redirectOnSuccess(user);
        },
      })
    );
  };

  const currentLang = useSelector((state) => state.i18nState.lang);

  if (needsValidation) {
    return (
      <Page title={context.t("Account Login")} className="pt-4">
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <h1>{context.t("Validating your account")}</h1>
              <Button onClick={handleValidateToken}>Validate</Button>
            </Col>
          </Row>
        </Container>
      </Page>
    );
  }

  return (
    <Page title={context.t("Account Login")} className="pt-4">
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <h1>{context.t("Login")}</h1>
            {currentUser ? (
              <p>{context.t("Already logged in")}</p>
            ) : (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Label for="email">{context.t("Email")}</Label>
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(v) => {
                          field.onChange(v);
                        }}
                        type="email"
                        id="email"
                        invalid={errors?.email}
                        autoComplete="username"
                      />
                    )}
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                  />

                  {errors.email?.type === "required" && (
                    <p className="text-danger small mt-1">
                      {context.t("Email")} {context.t("is required")}
                    </p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="password">{context.t("Password")}</Label>
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(v) => {
                          field.onChange(v);
                        }}
                        type="password"
                        id="password"
                        invalid={errors?.password}
                        autoComplete="current-password"
                      />
                    )}
                    name="password"
                    control={control}
                    rules={{ required: true, minLength: 6 }}
                    defaultValue=""
                  />

                  {errors.password?.type === "required" && (
                    <p className="text-danger small mt-1">
                      {context.t("Password")} {context.t("is required")}
                    </p>
                  )}
                  {errors.password?.type === "minLength" && (
                    <p className="text-danger small mt-1">
                      {context.t("Password")} {context.t("is too short")}
                    </p>
                  )}
                </FormGroup>

                {loginErrors?.base?.map((error) => (
                  <p key={error} className="text-danger small mt-1">
                    {error}
                  </p>
                ))}

                <div className="d-flex flex-column">
                  <small>
                    {context.t("Don't have an account?")}{" "}
                    <Link to={`/${currentLang}/register`}>
                      {context.t("Register")}
                    </Link>
                  </small>
                  <small>
                    {context.t("Forgot your password?")}{" "}
                    <Link to={`/${currentLang}/reset-password`}>
                      {context.t("Reset Password")}
                    </Link>
                  </small>
                </div>

                <Button type="submit" color="primary" className="w-100 mt-3">
                  {context.t("Login")}
                </Button>
                <hr />
                <Row className="mb-3">
                  <Col>
                    <SocialLoginLink
                      provider="google_oauth2"
                      name={context.t("Google")}
                    />
                  </Col>

                  <Col>
                    <SocialLoginLink
                      provider="facebook"
                      name={context.t("Facebook")}
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

AccountLogin.contextTypes = {
  t: PropTypes.func,
};

export default AccountLogin;
