/*
Replace
release with your resource Name :) Take care about different writings!
/!\ Release release Releases releases /!\
Keep an eye on pluralized namings in here :)

*/

import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  defaultAddToDictionary,
  defaultCreate,
  defaultCreateFail,
  defaultCreateSuccess,
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateFail,
  defaultUpdateSuccess,
} from "../defaultReducers";

import { defaultInitialState } from "../defaultInitialState";

export const releasesSlice = createSlice({
  name: "releases",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
  },
});

export const {
  show: showRelease,
  update: updateRelease,
  list: listReleases,
  create: createRelease,
} = releasesSlice.actions;
export const actions = releasesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const releases = (state) =>
  state.releases.idsList.map((id) => state.releases.dictionary[id]);
export const release = (state) =>
  state.releases.showId && state.releases.dictionary[state.releases.showId];
export const releaseAttributes = (state) => release(state)?.attributes;
export const releaseRelationships = (state) => release(state)?.relationships;
export const releaseId = (state) => state.releases.showId;

export const getRelease = createSelector(
  (state) => state.releases.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getReleaseAttributes = createSelector(
  (state) => state.releases.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getReleaseRelationships = createSelector(
  (state) => state.releases.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getReleases = createSelector(
  (state) => state.releases.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default releasesSlice.reducer;
