import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge, Card, CardBody, CardText, CardTitle } from "reactstrap";
import { getReleaseAttributes } from "../../../redux/releases";

const ReleaseCard = ({ releaseId }) => {
  // releaseDate state avoids hydration issue with dates and timezones
  const [releaseDate, setReleaseDate] = useState("");
  const releaseData = useSelector((state) =>
    getReleaseAttributes(state, releaseId)
  );

  useEffect(() => {
    setReleaseDate(new Date(releaseData?.date)?.toDateString());
  }, [releaseData]);

  return (
    <Card className="h-100 shadow-sm">
      <div className="position-relative">
        <div className="ratio ratio-1x1 overflow-hidden card-img-top">
          <img
            src={releaseData?.header_original?.url}
            className="img-fluid card-img-top h-100 object-fit-cover"
            alt={releaseData?.title}
          />
        </div>
        <span className="position-absolute top-0 end-0 p-2">
          <Badge color="info">{releaseData?.release_type}</Badge>
        </span>
      </div>
      <CardBody className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle tag="h5">{releaseData?.title}</CardTitle>
        </div>
        <CardText className="mt-auto">Date: {releaseDate}</CardText>
      </CardBody>
    </Card>
  );
};

/**  define proptype for the 'translation' function  */
ReleaseCard.contextTypes = {
  t: PropTypes.func,
};

ReleaseCard.propTypes = {
  releaseId: PropTypes.string.isRequired,
};

export default ReleaseCard;
