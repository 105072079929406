/** ----- apiSwaggerRequest.js -----
 *  File: apiSwaggerRequest.js
 *
 *  Purpose: providing helper functions to perform API requests to ease out
 *  error handling through swagger client
 *----------------------------- */
import Swagger from "swagger-client";
import { getEnv } from "./env";
/**
 * The swagger client. We make this call to load the swagger json
 */
let cachedClient = null;
const swagger = () => {
  const REACT_APP_API_SERVER = getEnv("REACT_APP_API_SERVER");
  if (cachedClient) {
    return cachedClient;
  }
  const url = `${REACT_APP_API_SERVER}/api-docs/v1/swagger.yaml`;
  cachedClient = Swagger({
    url,
  });
  return cachedClient;
};

/**
 * Request interceptor used to add headers to the request
 * @param {Object} headers the headers to apply
 * @returns a request object
 */
export const applyHeaders = (headers) => (req) => {
  if (headers) {
    Object.keys(headers).map(
      (headerKey) => (req.headers[headerKey] = headers[headerKey])
    );
  }
  return req;
};

export default swagger;
