import PropTypes from "prop-types";
import React from "react";
import { FaFile, FaFilePdf } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import { getCampaignAttributes } from "../../../redux/campaigns";

const CampaignDocuments = ({ campaignId }, context) => {
  const campaignData = useSelector((state) =>
    getCampaignAttributes(state, campaignId)
  );

  return (
    <>
      {!!campaignData?.documents?.length && (
        <div className="my-5">
          <h2 className="mb-4">{context.t("Support Policy & Documents")}</h2>
          <div className="d-flex gap-3">
            {campaignData?.documents?.map((document) => (
              <div key={document.url}>
                <Card key={document.url}>
                  {document.content_type.includes("pdf") ? (
                    <FaFilePdf size={128} className="mx-auto my-3" />
                  ) : (
                    <FaFile size={128} className="mx-auto my-3" />
                  )}
                  <CardBody>
                    <CardTitle tag="h5">{document.name}</CardTitle>
                    <CardText className="text-info fst-italic d-flex gap-3">
                      <span>{(document.size / 1000).toFixed(1)} KB</span>
                      <span>{document.content_type}</span>
                    </CardText>
                    <div className="d-flex">
                      <a
                        href={document.url}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-secondary ms-auto"
                      >
                        {context.t("Download")}
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

/**  define proptype for the 'translation' function  */
CampaignDocuments.contextTypes = {
  t: PropTypes.func,
};

CampaignDocuments.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

export default CampaignDocuments;
