/**
 * The HowItWorks component
 */
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { isServer } from "../../redux/store";
import CrowdfundingIcon from "../icons/CrowdfundingIcon";
import EarningsIcon from "../icons/EarningsIcon";
import FansIcon from "../icons/FansIcon";

/**
 * Stateless react component to render the HowItWorks
 * @param {Object} props the props passed to the component.
 */

const HowItWorks = (props, context) => {
  const [targetAudience, setTargetAudience] = useState("1");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClickScroll = () => {
    if (!isServer) {
      const element = document.getElementById("faq-section");
      if (element && window) {
        window.scrollTo({
          top: element.offsetTop - 74,
          behavior: "smooth",
        });
      }
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <Container className="py-5">
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center mb-3">
          <h2 className="mb-lg-0">{context.t("How it works?")}</h2>
          <div>
            <Button
              onClick={() => setTargetAudience("1")}
              color={targetAudience === "1" ? "secondary" : "light"}
              className="ms-3 px-4"
              size="lg"
            >
              {context.t("For Fans")}
            </Button>
            <Button
              onClick={() => setTargetAudience("2")}
              color={targetAudience === "2" ? "quaternary" : "light"}
              className="ms-3 px-4"
              size="lg"
            >
              {context.t("For Artists")}
            </Button>
          </div>
        </div>
        <TabContent activeTab={targetAudience}>
          <TabPane tabId="1">
            <Row>
              <Col lg="4" className="mb-5 mb-lg-0">
                <div className="rounded-5 bg-secondary p-4 h-100 shadow">
                  <div style={{ height: "100px" }}>
                    <CrowdfundingIcon />
                  </div>
                  <p className="fs-5 text-center fw-bold mt-3 mb-0">
                    {context.t("Signup/Invest in Campaign.")}
                  </p>
                </div>
              </Col>
              <Col lg="4" className="mb-5 mb-lg-0">
                <div className="rounded-5 bg-secondary p-4 h-100 shadow">
                  <Row>
                    <div style={{ height: "100px" }}>
                      <EarningsIcon />
                    </div>
                  </Row>
                  <p className="fs-5 text-center fw-bold mt-3 mb-0">
                    {context.t("Get royalties of the song you supported.")}
                  </p>
                </div>
              </Col>
              <Col lg="4" className="mb-0 mb-lg-0">
                <div className="rounded-5 bg-secondary p-4 h-100 shadow">
                  <div style={{ height: "100px" }}>
                    <FansIcon />
                  </div>
                  <p className="fs-5 text-center fw-bold mt-3 mb-0">
                    {context.t(
                      "Get special rewards, connect with your artist."
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col lg="4" className="mb-5 mb-lg-0">
                <div className="rounded-5 bg-quaternary p-4 h-100 shadow">
                  <div style={{ height: "100px" }}>
                    <CrowdfundingIcon />
                  </div>
                  <p className="fs-5 text-center fw-bold mt-3 mb-0">
                    {context.t("Financing music projects through your fans.")}
                  </p>
                </div>
              </Col>
              <Col lg="4" className="mb-5 mb-lg-0">
                <div className="rounded-5 bg-quaternary p-4 h-100 shadow">
                  <Row>
                    <div style={{ height: "100px" }}>
                      <EarningsIcon />
                    </div>
                  </Row>
                  <p className="fs-5 text-center fw-bold mt-3 mb-0">
                    {context.t(
                      "Production in the studio, release via Talenzz & involving fans."
                    )}
                  </p>
                </div>
              </Col>
              <Col lg="4" className="mb-0 mb-lg-0">
                <div className="rounded-5 bg-quaternary p-4 h-100 shadow">
                  <div style={{ height: "100px" }}>
                    <FansIcon />
                  </div>
                  <p className="fs-5 text-center fw-bold mt-3 mb-0">
                    {context.t(
                      "Easily build a lasting relationship with fans."
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
        <div className="mt-5">
          <Row xs="2">
            <Col>
              <div className="d-flex justify-content-end">
                <Button onClick={handleClickScroll} color="primary" outline>
                  {context.t("Learn more")}
                </Button>
              </div>
            </Col>
            <Col>
              <Button
                type="button"
                onClick={toggleModal}
                color="primary"
                outline
              >
                {context.t("Create Campaign")}
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {context.t("Create Campaign")}
        </ModalHeader>
        <ModalBody>
          <p>
            {context.t(
              "We are happy to create a campaign together. Get in touch with us now."
            )}
          </p>
          <Container>
            <Row xs="2">
              <Col className="border-end">
                <div className="d-flex justify-content-center">
                  <a
                    href="mailto:info@talenzz.io"
                    className="btn btn-outline-primary"
                  >
                    {context.t("Write an Email")}
                  </a>
                </div>
              </Col>
              <Col>
                <div className="d-flex justify-content-center">
                  <a
                    href="https://outlook.office365.com/owa/calendar/Wiefunktionierttalenzz@talenzz.io/bookings/"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-outline-primary"
                  >
                    {context.t("Book a Videocall")}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={toggleModal}>
            {context.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

/** define proptypes for the HowItWorks */
HowItWorks.propTypes = {};

HowItWorks.defaultProps = {};

/**  define proptype for the 'translation' function  */
HowItWorks.contextTypes = {
  t: PropTypes.func,
};

export default HowItWorks;
