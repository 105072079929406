import {
  call,
  delay,
  put,
  takeEvery,
  // select,
  getContext,
} from "redux-saga/effects";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";
import { deleteTokenCookies, setTokenCookies } from "../../utils/tokenCookies";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";
import { isServer } from "../store";

export function* login(action) {
  const headers = yield buildHeaders();
  const { successCallbackFnc, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Users.createSession,
      {},
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.loginSuccess(payload.obj.data));

    if (successCallbackFnc) successCallbackFnc(payload.obj?.data);
    if (!isServer) {
      setTokenCookies(payload.headers);
    }
  } catch (e) {
    yield put(actions.loginFail(e.response?.obj?.data || e));
  }
}

export function* register(action) {
  const headers = yield buildHeaders();
  const { successCallbackFnc, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Users.createUser,
      {},
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.registerSuccess(payload.obj.data));

    if (successCallbackFnc) successCallbackFnc(payload.obj?.data);

    yield delay(5000);
    yield put(actions.resetRegister());
  } catch (e) {
    yield put(actions.registerFail(e.response?.obj ? e.response?.obj.data : e));
  }
}

export function* logout() {
  const headers = yield buildHeaders(yield getContext("tokens"));
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Users.destroySession,
      {},
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.logoutSuccess(payload.obj.data));
    deleteTokenCookies();
  } catch (e) {
    yield put(actions.logoutFail(e.response?.obj?.data || e));
  }
}

export function* validateToken(action) {
  let headers = yield buildHeaders(yield getContext("tokens"));
  const successCallbackFnc = action.payload?.successCallbackFnc;
  const token = action.payload?.accessToken || action.payload?.["access-token"];
  if (token) {
    headers = {
      ...headers,
      access_token: token,
      "access-token": token,
      client: action.payload.client,
      uid: action.payload.uid,
    };
  }

  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Users.validateToken,
      {},
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.loginSuccess({ ...payload.obj.data }));

    if (successCallbackFnc) successCallbackFnc(payload.obj.data);
    if (!isServer) {
      setTokenCookies(payload.headers);
    }
  } catch (e) {
    yield put(actions.loginFail(e.response?.obj?.data || e));
  }
}

export function* resetPassword(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));
  const { ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Users.resetPassword,
      {},
      { requestInterceptor: applyHeaders(headers), requestBody }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.resetPasswordSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.resetPasswordFail(e.response?.obj?.data || e));
  }
}

export function* updatePassword(action) {
  let headers = yield buildHeaders(yield getContext("tokens"));
  const { successCallbackFnc, newHeaders, ...requestBody } = action.payload;
  headers = { ...headers, ...newHeaders };
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Users.updatePassword,
      {},
      { requestInterceptor: applyHeaders(headers), requestBody }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.updatePasswordSuccess(payload.obj.data));
    if (!isServer) setTokenCookies(payload.headers);
    if (successCallbackFnc) successCallbackFnc();
  } catch (e) {
    yield put(actions.updatePasswordFail(e.response?.obj?.data || e));
  }
}

export function* show(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));
  const { id } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Users.showUser,
      {
        id: id,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.showSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.showFail({ message: e.message, ...e }));
  }
}

export function* update(action) {
  const { id, successCallbackFnc, ...requestBody } = action.payload;
  const headers = yield buildHeaders(yield getContext("tokens"));
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Users.updateUser,
      { id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.updateSuccess(payload.obj.data));
    if (successCallbackFnc) successCallbackFnc();
  } catch (e) {
    yield put(actions.updateFail({ message: e.message, ...e }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* accountsSaga() {
  yield takeEvery(actions.login, login);
  yield takeEvery(actions.register, register);
  yield takeEvery(actions.logout, logout);
  yield takeEvery(actions.validateToken, validateToken);
  yield takeEvery(actions.resetPassword, resetPassword);
  yield takeEvery(actions.updatePassword, updatePassword);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
}
export default accountsSaga;
