import { call, getContext, put, takeEvery } from "redux-saga/effects";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";

/** List Saga
 *  @description: connects to the getPerk operation
 */
export function* list(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));
  const {
    page,
    unlockedPerks: unlocked_perks,
    unlockablePerks: unlockable_perks,
  } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Perks.listPerks,
      { page, unlocked_perks, unlockable_perks },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.listSuccess(payload));
  } catch (e) {
    yield put(actions.listFail({ message: e.message, ...e }));
  }
}

export function* create(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));
  let { campaignId: campaign_id, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Perks.createPerk,
      { campaign_id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.createSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.createFail({ message: e.message, ...e }));
  }
}
/** Show Saga
 *  @description: connects to the showPerk operation
 *  @param {number} action.payload the Perk id
 */
export function* show(action) {
  const headers = yield buildHeaders(yield getContext("tokens"));
  const { id } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Perks.showPerk,
      {
        id,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.showSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.showFail({ message: e.message, ...e }));
  }
}

export function* update(action) {
  const { id, ...requestBody } = action.payload;
  const headers = yield buildHeaders(yield getContext("tokens"));
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Perks.updatePerk,
      { id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.updateSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.updateFail({ message: e.message, ...e }));
  }
}

export function* unlock(action) {
  const { id, ...requestBody } = action.payload;
  const headers = yield buildHeaders(yield getContext("tokens"));
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Perks.unlockPerk,
      { id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.unlockSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.unlockFail({ id, error: e.response?.obj?.data }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* perksSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.unlock, unlock);
}
export default perksSaga;
