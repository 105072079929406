import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { CampaignPreview } from "../components/Campaign";
import { ContentPreview } from "../components/Content";
import { FaqList } from "../components/Faq";
import { HomeHero } from "../components/Home";
import { HowItWorks } from "../components/HowItWorks";
import { TalenzzKpi } from "../components/TalenzzKpi";
import Page from "../components/layout/Page";
import { listCampaigns } from "../redux/campaigns";
import { listContents } from "../redux/contents";

function Home(props, context) {
  const page = useSelector((state) => state.campaigns.listPage);
  const campaignIds = useSelector((state) => state.campaigns.idsList);
  const campaignTotalPages = useSelector((state) => state.campaigns.totalPages);
  const contentsIds = useSelector((state) => state.contents.idsList);
  const contentsTotalPages = useSelector((state) => state.contents.totalPages);
  const contentsPage = useSelector((state) => state.contents.listPage);
  const dispatch = useDispatch();
  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    registerEffect(
      dispatch,
      listCampaigns({
        endlessScroll: true,
      })
    );
    registerEffect(
      dispatch,
      listContents({
        endlessScroll: true,
      })
    );
  }, []);

  const handleClick = () => {
    dispatch(
      listCampaigns({
        endlessScroll: true,
        page: page + 1,
      })
    );
  };

  const loadMoreContents = () => {
    dispatch(
      listContents({
        endlessScroll: true,
        page: contentsPage + 1,
      })
    );
  };

  return (
    <Page title={context.t("Home")}>
      <HomeHero />
      <HowItWorks />
      <Container className="py-5">
        {campaignIds.map((campaignId) => (
          <div key={campaignId}>
            <CampaignPreview campaignId={campaignId} />
          </div>
        ))}

        <div className="d-flex justify-content-center">
          <Button
            hidden={page >= campaignTotalPages}
            type="button"
            onClick={handleClick}
            color="primary"
          >
            {context.t("Load More")}
          </Button>
        </div>
      </Container>
      <TalenzzKpi />
      {!!contentsIds?.length && (
        <Container className="py-5">
          <h2 className="text-center mb-4">{context.t("News & Updates")}</h2>
          <Row>
            {contentsIds.map((contentId) => (
              <Col
                xs="6"
                md="4"
                lg="3"
                key={contentId}
                style={{ marginBottom: "var(--bs-gutter-x)" }}
              >
                <ContentPreview contentId={contentId} />
              </Col>
            ))}
          </Row>
          <div className="d-flex justify-content-center">
            <Button
              hidden={contentsPage >= contentsTotalPages}
              type="button"
              onClick={loadMoreContents}
              color="primary"
            >
              {context.t("Load More")}
            </Button>
          </div>
        </Container>
      )}
      <FaqList />
    </Page>
  );
}

Home.contextTypes = {
  t: PropTypes.func,
};

export default Home;
