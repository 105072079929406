/*
Replace
artist with your resource Name :) Take care about different writings!
/!\ Artist artist Artists artists /!\
Keep an eye on pluralized namings in here :)

*/

import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  defaultAddToDictionary,
  defaultCreate,
  defaultCreateFail,
  defaultCreateSuccess,
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateFail,
  defaultUpdateSuccess,
} from "../defaultReducers";

import { defaultInitialState } from "../defaultInitialState";

export const artistsSlice = createSlice({
  name: "artists",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
  },
});

export const {
  show: showArtist,
  update: updateArtist,
  list: listArtists,
  create: createArtist,
} = artistsSlice.actions;

export const actions = artistsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const artists = (state) =>
  state.artists.idsList.map((id) => state.artists.dictionary[id]);
export const artist = (state) =>
  state.artists.showId && state.artists.dictionary[state.artists.showId];
export const artistAttributes = (state) => artist(state)?.attributes;
export const artistRelationships = (state) => artist(state)?.relationships;
export const artistId = (state) => state.artists.showId;

export const getArtist = createSelector(
  (state) => state.artists.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getArtistAttributes = createSelector(
  (state) => state.artists.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getArtistRelationships = createSelector(
  (state) => state.artists.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getArtists = createSelector(
  (state) => state.artists.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default artistsSlice.reducer;
