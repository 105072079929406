import { useRegisterEffect, useSsrEffect } from "@issr/core";
import PropTypes from "prop-types";
import React from "react";
import { MdOutlineCheckCircle, MdOutlineError } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, Col, Container } from "reactstrap";
import Forbidden from "../components/layout/Forbidden";
import Page from "../components/layout/Page";
import useAccount from "../hooks/useAccount";
import { validateToken } from "../redux/accounts";
import {
  investmentRelationships as getInvestmentRelationships,
  investmentAttributes,
  showInvestment,
} from "../redux/investments";
import { getTokenCookies } from "../utils/tokenCookies";

function Investment(_props, context) {
  const { currentUser } = useAccount();
  const { status } = useParams();
  const dispatch = useDispatch();
  const { id } = useParams();
  const cookies = getTokenCookies();
  const investmentData = useSelector((state) => investmentAttributes(state));
  const investmentRelationships = useSelector((state) =>
    getInvestmentRelationships(state)
  );

  const registerEffect = useRegisterEffect();
  useSsrEffect(() => {
    if (!currentUser && cookies["accessToken"]) {
      registerEffect(dispatch, validateToken());
    }
    registerEffect(dispatch, showInvestment({ id }));
  }, []);

  const isApproved = investmentData?.payment_status === "accepted";
  const showAlert = status === "success" || status === "fail";

  const currentLang = useSelector((state) => state.i18nState.lang);

  if (!investmentData) return "";
  return (
    <Page title={context.t("Support")}>
      <Container className="my-3 d-flex justify-content-center">
        {currentUser && showAlert && (
          <Col xs="12" md="8" lg="6">
            <div
              className={`border border-3 ${
                isApproved ? "border-success" : "border-danger"
              }`}
            ></div>
            <Card body className="shadow border-0">
              <div className="mb-4 text-center">
                {isApproved ? (
                  <MdOutlineCheckCircle
                    style={{ fontSize: "4.7rem" }}
                    className="text-success"
                  />
                ) : (
                  <MdOutlineError
                    style={{ fontSize: "4.7rem" }}
                    className="text-danger"
                  />
                )}
              </div>
              <div className="text-center">
                <h1>
                  {context.t("Payment")}{" "}
                  {isApproved ? context.t("Approved") : context.t("Failed")}
                </h1>
                <p>
                  {isApproved ? (
                    <>
                      {context.t(
                        "Congratulations! If you would like to return to the campaign"
                      )}{" "}
                      <Link
                        to={`/${currentLang}/campaigns/${investmentRelationships?.campaign?.data?.id}`}
                        className="link-info"
                      >
                        {context.t("click here")}
                      </Link>
                      .
                    </>
                  ) : (
                    context.t("Something unexpected happened.")
                  )}
                </p>
                <div className="d-flex justify-content-center gap-3">
                  {isApproved ? (
                    <Link
                      to={`/${currentLang}/dashboard`}
                      className="btn btn-outline-success"
                    >
                      {context.t("Go to Dashboard")}
                    </Link>
                  ) : (
                    <Link
                      to={`/${currentLang}/campaigns/${investmentRelationships?.campaign?.data?.id}`}
                      className="btn btn-outline-danger"
                    >
                      {context.t("Go back to Campaign")}
                    </Link>
                  )}
                </div>
              </div>
            </Card>
          </Col>
        )}
        {!currentUser && !cookies["accessToken"] && <Forbidden />}
      </Container>
    </Page>
  );
}

Investment.contextTypes = {
  t: PropTypes.func,
};

export default Investment;
