/**
 *  Sagas for the voting resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

// What to do for a new resource?
// 1.) add Import
// import {actions as resourceNameActions} from './resourceName';
// 2.) add store included handlinge
// } else if (entry.type === "resource_name"){
//   return put({type: resourceNameActions.addToDictionary, payload: entry});
// }

import { all, put, takeEvery } from "redux-saga/effects";
import { actions as accountActions } from "./accounts";
import { actions as artistActions } from "./artists";
import { actions as campaignActions } from "./campaigns";
import { actions as contentActions } from "./contents";
import { actions as eventActions } from "./events";
import { actions as faqActions } from "./faqs";
import { actions as investmentActions } from "./investments";
import { actions as paymentActions } from "./payments";
import { actions as perkActions } from "./perks";
import { actions as releaseActions } from "./releases";

/** Worker Sagas */
export function* storeIncluded(action) {
  yield all(
    action?.payload?.map((entry) => {
      if (entry.type === "account") {
        return put(accountActions.addToDictionary(entry));
      } else if (entry.type === "artist") {
        return put(artistActions.addToDictionary(entry));
      } else if (entry.type === "campaign") {
        return put(campaignActions.addToDictionary(entry));
      } else if (entry.type === "content") {
        return put(contentActions.addToDictionary(entry));
      } else if (entry.type === "event") {
        return put(eventActions.addToDictionary(entry));
      } else if (entry.type === "faq") {
        return put(faqActions.addToDictionary(entry));
      } else if (entry.type === "investment") {
        return put(investmentActions.addToDictionary(entry));
      } else if (entry.type === "payment") {
        return put(paymentActions.addToDictionary(entry));
      } else if (entry.type === "perk") {
        return put(perkActions.addToDictionary(entry));
      } else if (entry.type === "release") {
        return put(releaseActions.addToDictionary(entry));
      }
      // if (entry.type === "resource") {
      //   return put(resourceActions.addToDictionary(entry));
      // }
      else return null;
    })
  );
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* dictionarySagas() {
  yield takeEvery("dictionary/storeIncluded", storeIncluded);
  // yield takeEvery('dictionary/updateRelationships', updateRelationships);
}
export default dictionarySagas;
