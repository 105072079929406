export const defaultListSuccess = (state, action) => {
  const responseList = action.payload.obj.data || [];
  const responseIds = action.payload.obj.data.map((a) => a.id);
  const responseHeaders = action.payload.headers;

  if (responseHeaders && responseHeaders["total-count"])
    state.listTotalCount = parseInt(responseHeaders["total-count"], 10);
  if (responseHeaders && responseHeaders["current-page"])
    state.listPage = parseInt(responseHeaders["current-page"], 10);
  if (responseHeaders && responseHeaders["total-pages"])
    state.totalPages = parseInt(responseHeaders["total-pages"], 10);
  
  state.loadingList = false;
  state.loadedList = true;
  state.endlessScroll = action.payload.endlessScroll;


  if (state.endlessScroll && state.listPage > 1) {
    state.idsList = state.idsList.concat(responseIds);
  } else {
    state.idsList = responseIds;
  }

  state.listMeta = action.payload.obj.meta;

  responseList.forEach((entry) => (state.dictionary[entry.id] = entry));
};

export const defaultList = (state, action) => {
  state.loadingList = true;
  state.loadedList = false;
  state.listError = null;
  if (action.payload && action.payload.page) {
    state.listPage = action.payload.page;
  }
};

export const defaultListFail = (state, action) => {
  state.loadingList = false;
  state.loadedList = false;
  state.listError = action.payload.message;
};

export const defaultSetListPage = (state, action) => {
  state.listPage = action.payload;
};

export const defaultShow = (state, action) => {
  state.loadingShow = true;
  state.loadedShow = false;
  state.showError = null;
  state.showId = action.payload.id || null;
};
export const defaultShowSuccess = (state, action) => {
  state.loadingShow = false;
  state.loadedShow = true;
  state.showId = action.payload.id;
  state.dictionary[action.payload.id] = action.payload;
};

export const defaultShowFail = (state, action) => {
  state.loadingShow = false;
  state.loadedShow = false;
  state.showError = action?.payload?.message;
};

export const defaultCreate = (state) => {
  state.creating = true;
  state.created = false;
  state.createError = null;
};
export const defaultCreateSuccess = (state, action) => {
  state.creating = false;
  state.created = true;
  state.dictionary[action.payload.id] = action.payload;
  state.createError = null;
  state.errorsObject = null;
};
export const defaultCreateFail = (state, action) => {
  state.creating = false;
  state.created = false;
  if (action.payload?.response?.body?.data) {
    state.errorsObject = action.payload.response.body.data;
  } else {
    state.createError = action.payload.message;
  }
};

export const defaultUpdate = (state) => {
  state.updating = true;
  state.updated = false;
  state.updateErrorMessage = null;
  state.updateError = false;
};
export const defaultUpdateSuccess = (state, action) => {
  state.updating = false;
  state.updated = true;
  state.dictionary[action.payload.id] = action.payload;
  state.errorsObject = null;
  state.updateError = false;
};
export const defaultUpdateFail = (state, action) => {
  state.updating = false;
  state.updated = false;
  state.updateError = true;
  if (action.payload?.response?.body?.data) {
    state.errorsObject = action.payload.response.body.data;
  } else {
    state.updateErrorMessage = action.payload.message;
  }
};
export const defaultRemove = (state, action) => {
  state.removing = true;
  state.removed = false;
  state.removeError = null;
  if (action.payload.id) {
    state.idsList = state.idsList.filter(
      (entry) => entry !== action.payload.id
    );
    delete state.dictionary[action.payload.id];
  }
};
export const defaultRemoveSuccess = (state, action) => {
  state.removing = false;
  state.removed = true;
};
export const defaultRemoveFail = (state, action) => {
  state.removing = false;
  state.removed = false;
  state.removeError = action.payload.message;
};
export const defaultRemoveReset = (state, action) => {
  state.removing = false;
  state.removed = false;
  state.removeError = null;
};

export const defaultAddToDictionary = (state, action) => {
  state.dictionary[action.payload.id] = {
    ...state.dictionary[action.payload.id],
    ...action.payload,
  };
};
