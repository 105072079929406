/**
 * Normalize ENVs
 */

export const {
  REACT_APP_API_SERVER,
  REACT_APP_CDN_SERVER,
  REACT_APP_WEB_SERVER,
  REACT_APP_CDN_PATH,
} = typeof window !== "undefined" && window.env ? window.env : process.env;

export const getEnv = (envName) => {
  if (!envName) return null;
  const windowEnvs = typeof window !== "undefined" ? window.env : null;
  const processEnvs = process.env;
  if (windowEnvs) {
    return windowEnvs[envName];
  } else if (processEnvs) {
    return processEnvs[envName];
  } else {
    return null;
  }
};
