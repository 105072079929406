import PropTypes from "prop-types";
import React from "react";
import {
  BsFacebook,
  BsInstagram,
  BsSpotify,
  BsTiktok,
  BsYoutube,
} from "react-icons/bs";
import { FaBandcamp, FaHouse } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getArtistAttributes } from "../../../redux/artists";

const ArtistSocials = ({ artistId }) => {
  const artistData = useSelector((state) =>
    getArtistAttributes(state, artistId)
  );

  const iconStyle = {
    fontSize: "2rem",
  };

  return (
    <>
      {artistData?.spotify_url && (
        <Link to={artistData?.spotify_url} className="link-dark">
          <BsSpotify style={iconStyle} />
        </Link>
      )}
      {artistData?.facebook_url && (
        <Link to={artistData?.facebook_url} className="link-dark">
          <BsFacebook style={iconStyle} />
        </Link>
      )}
      {artistData?.instagram_url && (
        <Link to={artistData?.instagram_url} className="link-dark">
          <BsInstagram style={iconStyle} />
        </Link>
      )}
      {artistData?.tiktok_url && (
        <Link to={artistData?.tiktok_url} className="link-dark">
          <BsTiktok style={iconStyle} />
        </Link>
      )}
      {artistData?.youtube_url && (
        <Link to={artistData?.youtube_url} className="link-dark">
          <BsYoutube style={iconStyle} />
        </Link>
      )}
      {artistData?.bandcamp_url && (
        <Link to={artistData?.bandcamp_url} className="link-dark">
          <FaBandcamp style={iconStyle} />
        </Link>
      )}
      {artistData?.website_url && (
        <Link to={artistData?.website_url} className="link-dark">
          <FaHouse style={iconStyle} />
        </Link>
      )}
    </>
  );
};

/**  define proptype for the 'translation' function  */
ArtistSocials.contextTypes = {
  t: PropTypes.func,
};

ArtistSocials.propTypes = {
  artistId: PropTypes.string.isRequired,
};

export default ArtistSocials;
